import { AspectRatio } from "../components/common/DropDowns/StaticDropdown";
import { Status } from "../components/common/DropDowns/useStaticDropdown";
import { IframeGenModels } from "../pages/IframeNew/IframeEditor/Form/Dropdowns/IframeGenModels";
import { PlaygroundConfigs } from "../pages/Playground/playground-store";
import { Organization } from "./organization";

// Enum Definitions
export enum AiEditorTabs {
	Background = "Background",
	Objects = "Objects",
	Presenters = "Presenters",
	Style = "Style",
	Text = "Text",
	Size = "Size",
	Brand = "Brand",
}

export type IframeLanguage = "english" | "japanese";
export type IframePages = PlaygroundConfigs | "aiEditor";
type IframeFeatureType =
	| "expression"
	| "diversity"
	| "appearance"
	| "camera_movement_video"
	| "logo"
	| "fine_tuning"
	| "mood"
	| "remove"
	| "blur"
	| "paint"
	| "replace"
	| "background"
	| "remove_object"
	| "text_editor"
	| "crop"
	| "psd_download"
	| "super_resolution"
	| "expand_background"
	| "image_style"
	| "campaign_create"
	| "auto_obj_identification";

export type IframeFeature = {
	enabled?: boolean;
	price?: number;
};

export type IframeFeatures = { [feature in IframeFeatureType]?: IframeFeature };
export type IframeLanguages = { [language in IframeLanguage]?: boolean };

export type HeaderIframeConfig = {
	white_label?: boolean;
};

export type TgIframeConfig = {
	enabled_models: string[];
};

export type GenIframeConfig = {
	enabled_models?: IframeGenModels;
	default_aspect_ratio?: AspectRatio;
};

export type AiEditorIframeConfig = {
	default_tab?: AiEditorTabs;
	disable_save?: boolean;
	disable_save_psd?: boolean;
	disable_download_image?: boolean;
	disable_download_psd?: boolean;
	disable_upload?: boolean;
	save_label?: string;
};

export type IframeCustomStyle = {
	logo?: string;
	cssVars?: { [varKey: string]: string };
};

export type ControlsIframeConfig = {
	isAdmin?: boolean;
	closeWindowBtn?: boolean;
};

export type IframeConfig = {
	allowed_domains: string[];
	allowed_features: IframeFeatures;
	allowed_languages: IframeLanguages;
	enabled_pages: Partial<{ [page in IframePages]: boolean }>;
	enabled_features?: IframeFeatures;
	enabled_languages?: IframeLanguages;
	landing_page: IframePages;
	tg_config?: TgIframeConfig;
	gen_config?: GenIframeConfig;
	ai_editor_config?: AiEditorIframeConfig;
	custom_style?: IframeCustomStyle;
	header_config?: HeaderIframeConfig;
	controls?: ControlsIframeConfig;
};

export type Iframe = {
	id: string;
	name: string;
	description: string;
	status: Status;
	config: IframeConfig;
	org_id?: string;
	created_at: Date;
	updated_at: Date;
	organization: Organization;
	username: string;
	password: string;
};

export type PaginatedIframes = {
	items: Iframe[];
	total: number;
};

export const getDefaultIframe = (orgId: string) =>
	({
		id: "",
		name: "Untitled - Iframe Name",
		description: "",
		status: "Pending",
		config: {
			allowed_domains: [],
			allowed_features: {},
			allowed_languages: {},
			enabled_pages: {
				textToImage: true,
				textToVector: true,
				imageToImage: true,
				aiEditor: true,
				campaign: true,
			},
			landing_page: "" as IframePages,
			controls: {
				isAdmin: false,
				closeWindowBtn: false,
			},
		},
		created_at: new Date(),
		updated_at: new Date(),
		organization: {
			id: orgId,
			allowed_pages: {
				textToImage: true,
				textToVector: true,
				imageToImage: true,
				aiEditor: true,
				campaign: true,
			},
			is_blocked: false,
		},
		org_id: orgId,
		username: "",
		password: "",
	}) as Iframe;

export enum IframePostMessageTypes {
	InitPreview = "Bria_InitPreview",
}
