import { Typography } from "@mui/material";
import Link from "@mui/material/Link";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import LinkConstants from "../../../constants/LinkConstants.ts";
import RouterConstants from "../../../constants/RouterConstants.ts";
import { ORG_SUBSCRIPTION_PLANS } from "../../../constants/billing.ts";
import useSecureNavigate from "../../../hooks/useSecureNavigate.tsx";
import { useAppStore } from "../../../hooks/useStores.tsx";
import { capitalizeFirstLetter, removeParamFromLocationUrl } from "../../../utils";
import ConfirmationPopup from "../../common/ConfirmationPopup/ConfirmationPopup.tsx";
import SelectOrganizationPopup from "../../common/SelectOrganizationPopup/SelectOrganizationPopup.tsx";
import LoadingPopup from "../LoadingPopup/LoadingPopup.tsx";
import CheckoutPopup from "./CheckoutPopup/CheckoutPopup.tsx";
import styles from "./PricingFlow.module.scss";
import PricingPopup from "./PricingPopup/PricingPopup.tsx";
import SubscriptionCancelledPopup from "./SubscriptionCancelledPopup/SubscriptionCancelledPopup.tsx";
import ThankYouCheckoutPopup from "./ThankYouCheckoutPopup/ThankYouCheckoutPopup.tsx";
import { PRICING_QUERY_PARAMS, PRICING_STEPS } from "./store.tsx";

const PricingFlow = () => {
	const { t } = useTranslation("translation", { keyPrefix: "pricingFlow" });
	const { pricingStore, authStore } = useAppStore();
	const navigate = useSecureNavigate();
	const [isLoading, setIsLoading] = useState<boolean>(false);

	useEffect(() => {
		setIsLoading(
			authStore.isLoadingUserOrganizations ||
				authStore.isLoadingOrgSubscriptions ||
				(!!pricingStore.checkoutSessionId && !pricingStore.checkoutSession),
		);
	}, [
		authStore.isLoadingUserOrganizations,
		authStore.isLoadingOrgSubscriptions,
		pricingStore.checkoutSessionId,
		pricingStore.checkoutSession,
	]);

	useEffect(() => {
		if (pricingStore.isPricingFlowOpen && !isLoading) {
			removeParamFromLocationUrl(PRICING_QUERY_PARAMS, navigate);
			pricingStore._openPricingFlow();
		}
	}, [pricingStore.isPricingFlowOpen, isLoading]);

	return pricingStore.isPricingFlowOpen ? (
		isLoading ? (
			<LoadingPopup open={isLoading} paperClassName={styles.loadingPopup} />
		) : (
			<>
				<CheckoutPopup
					open={
						pricingStore.activeStep === PRICING_STEPS.CHECKOUT_POPUP ||
						pricingStore.activeStep === PRICING_STEPS.CLOSE_CONFIRMATION_POPUP
					}
					onClose={() => pricingStore.setProperty("activeStep", PRICING_STEPS.CLOSE_CONFIRMATION_POPUP)}
				/>
				<ThankYouCheckoutPopup />
				<SelectOrganizationPopup
					open={pricingStore.activeStep === PRICING_STEPS.SELECT_ORG_POPUP}
					onClose={pricingStore.handleCloseSelectOrgPopup}
				/>
				<PricingPopup
					open={pricingStore.activeStep === PRICING_STEPS.PRICING_POPUP}
					onClose={pricingStore.closePricingFlow}
					onPlanClick={pricingStore.handlePlanButtonClick}
				/>
				<SubscriptionCancelledPopup />
				<ConfirmationPopup
					onClose={() => {
						pricingStore.closePricingFlow();
					}}
					paperClassName={clsx(styles.agreeOnTermsPopup, styles.popupPaper)}
					title={t("agreeOnTermsPopup.title")}
					description={
						<Typography className={styles.description}>
							<Trans
								i18nKey="pricingFlow.agreeOnTermsPopup.description"
								components={[
									<Link
										onClick={() => {
											pricingStore.setProperty("isTermsPopupOpen", true);
										}}
										className={styles.link}
									/>,
								]}
							/>
						</Typography>
					}
					confirmButtonText={t("agreeOnTermsPopup.buttonText")}
					open={pricingStore.activeStep === PRICING_STEPS.AGREE_ON_TERMS_POPUP}
					hideCancel={true}
					onClick={pricingStore.openCheckoutFlow}
				/>
				<ConfirmationPopup
					paperClassName={styles.termsPopup}
					contentClassName={styles.termsPopupContent}
					title={""}
					maxWidth={"unset"}
					description={
						<embed
							src={`${pricingStore.selectedPlan?.termsAndConditionsLink}?#scrollbar=0&toolbar=0&navpanes=0&view=FitH`}
							type="application/pdf"
							width="100%"
							height="100%"
						/>
					}
					confirmButtonText={t("termsPopup.close")}
					hideCancel={true}
					onClick={async () => {
						pricingStore.setProperty("isTermsPopupOpen", false);
					}}
					onClose={() => {
						pricingStore.setProperty("isTermsPopupOpen", false);
					}}
					open={pricingStore.isTermsPopupOpen}
				/>
				<ConfirmationPopup
					onClose={() => {
						pricingStore.closePricingFlow();
					}}
					paperClassName={clsx(styles.updateSubscriptionConfirmationPopup, styles.popupPaper)}
					title={t("updateSubscriptionConfirmationPopup.title")}
					description={
						<Typography className={styles.description}>
							{t("updateSubscriptionConfirmationPopup.description", {
								oldPlan: capitalizeFirstLetter(authStore.orgSubscription?.plan_name ?? ""),
								newPlan: pricingStore.selectedPlan?.displayName,
							})}
						</Typography>
					}
					confirmButtonText={t("updateSubscriptionConfirmationPopup.buttonText")}
					open={pricingStore.activeStep === PRICING_STEPS.SUBSCRIPTION_UPDATE_CONFIRMATION_POPUP}
					loading={pricingStore.isLoadingUpdateSubscription}
					onClick={pricingStore.updateSubscription}
				/>
				<ConfirmationPopup
					onClose={() => {
						pricingStore.closePricingFlow();
					}}
					paperClassName={clsx(styles.renewSubscriptionConfirmationPopup, styles.popupPaper)}
					title={t("renewSubscriptionConfirmationPopup.title", {
						plan: authStore.orgSubscription?.plan_name ?? ORG_SUBSCRIPTION_PLANS.free.name,
					})}
					description={
						<Typography className={styles.description}>
							{t("renewSubscriptionConfirmationPopup.description", {
								plan: authStore.orgSubscription?.plan_name ?? ORG_SUBSCRIPTION_PLANS.free.name,
							})}
						</Typography>
					}
					confirmButtonText={t("renewSubscriptionConfirmationPopup.buttonText")}
					open={pricingStore.activeStep === PRICING_STEPS.SUBSCRIPTION_RENEW_CONFIRMATION_POPUP}
					loading={pricingStore.isLoadingUpdateSubscription}
					onClick={pricingStore.updateSubscription}
				/>
				<ConfirmationPopup
					onClose={() => {
						window.location.replace(RouterConstants.SUBSCRIPTION.fullPath);
					}}
					paperClassName={clsx(styles.subscriptionUpdatedDialog, styles.popupPaper)}
					title={t("subscriptionUpdatedDialog.title", { plan: pricingStore.selectedPlan?.displayName })}
					description={
						<Typography className={styles.boldDescription}>
							{t("subscriptionUpdatedDialog.description", {
								plan: pricingStore.selectedPlan?.displayName,
							})}
						</Typography>
					}
					subDescription={
						<Typography className={styles.lightDescription}>
							<Trans i18nKey={"pricingFlow.subscriptionUpdatedDialog.subDescription"} />
						</Typography>
					}
					confirmButtonText={t("subscriptionUpdatedDialog.buttonText")}
					open={pricingStore.activeStep === PRICING_STEPS.SUBSCRIPTION_UPDATED_POPUP}
					hideCancel={true}
					onClick={() => {
						window.location.replace(RouterConstants.SUBSCRIPTION.fullPath);
					}}
				/>
				<ConfirmationPopup
					paperClassName={styles.popupPaper}
					onClose={() => {
						pricingStore.setProperty("activeStep", PRICING_STEPS.CHECKOUT_POPUP);
					}}
					title={t("checkoutConfirmationPopup.title")}
					confirmButtonText={t("checkoutConfirmationPopup.proceed")}
					description={
						<Typography className={styles.description}>
							{t("checkoutConfirmationPopup.description")}
						</Typography>
					}
					open={pricingStore.activeStep === PRICING_STEPS.CLOSE_CONFIRMATION_POPUP}
					onClick={() => {
						pricingStore.closePricingFlow();
					}}
				/>
				<ConfirmationPopup
					onClose={() => {
						pricingStore.closePricingFlow();
					}}
					paperClassName={clsx(styles.wrongOrgErrorPopup, styles.popupPaper)}
					title={t("wrongOrgErrorPopup.title")}
					description={
						<Typography className={styles.description}>{t("wrongOrgErrorPopup.description")}</Typography>
					}
					confirmButtonText={t("wrongOrgErrorPopup.buttonText")}
					open={pricingStore.activeStep === PRICING_STEPS.WRONG_ORG_ERROR_POPUP}
					firstButtonText={t("wrongOrgErrorPopup.firstButtonText")}
					onSecondaryClick={() => {
						authStore.logout().finally(async () => {
							navigate({
								pathname: RouterConstants.LOGIN.path,
								search: await pricingStore.getEncryptedQueryParams(),
							});
						});
					}}
					onClick={() => {
						window.open(LinkConstants.CONTACT_US_FORM_LINK, "_blank");
					}}
				/>
				<ConfirmationPopup
					onClose={() => {
						pricingStore.closePricingFlow();
					}}
					paperClassName={clsx(styles.linkExpiredErrorPopup, styles.popupPaper)}
					title={t("linkExpiredErrorPopup.title")}
					description={
						<Typography className={styles.description}>{t("linkExpiredErrorPopup.description")}</Typography>
					}
					confirmButtonText={t("linkExpiredErrorPopup.buttonText")}
					open={pricingStore.activeStep === PRICING_STEPS.LINK_EXPIRED_ERROR_POPUP}
					onClick={() => {
						window.open(LinkConstants.CONTACT_US_FORM_LINK, "_blank");
					}}
				/>
			</>
		)
	) : (
		<></>
	);
};

export default observer(PricingFlow);
