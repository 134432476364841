import { Box, SelectChangeEvent, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { ChangeEvent, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { BackgroundOptionsEnum, ISmartImageParams, ObjectsEnum, SecondaryTabTypeEnum } from "../..";
import CloudUploadSVG from "../../../../../../../../assets/images/svgs/AIEditor/cloud-upload.svg";
import { AspectRatio } from "../../../../../../../../components/common/DropDowns/StaticDropdown.tsx";
import ImageUploader from "../../../../../../../../components/common/ImageUploader/ImageUploader";
import { useAppStore } from "../../../../../../../../hooks/useStores";
import useImageUtils from "../../../../../../../../utils/useImageUtils.tsx";
import ReplaceImage from "../../../../../../../assets/svgs/ReplaceImage.svg";
import Download from "../../../../../../../assets/svgs/download.svg";
import ImageGrid from "../../../../../../../components/common/ImageGrid/ImageGrid.tsx";
import GenerateImagesSection from "../../../../../../../components/common/ImagesTab/GenerateImagesSection/GenerateImagesSection.tsx";
import { DesignEditorContext } from "../../../../../../../contexts/DesignEditor.tsx";
import { useEditor } from "../../../../../../../hooks/useEditor.tsx";
import { Image } from "../../../../../../../models/image.ts";
import { IScene } from "../../../../../../../types";
import { useSmartImageUtils } from "../../../../../utils/smartImageUtils.ts";
import { IMenuItem } from "../Images.tsx";
import BriaSwitchTab from "./BriaSwitchTab/BriaSwitchTab";
import GenerateBackground from "./GenerateBackground/GenerateBackground.tsx";
import ReplaceBackground from "./ReplaceBackground/ReplaceBackground.tsx";
import styles from "./SmartImage.module.scss";

export type IProps = {
	handleGenAspectRatioChange: (e: SelectChangeEvent<AspectRatio | AspectRatio[]>) => void;
	openText2ImagePopup: () => void;
};

const SmartImage = ({ handleGenAspectRatioChange, openText2ImagePopup }: IProps) => {
	const numberOfColumns = 2;
	const editor = useEditor();
	const { downloadImage } = useImageUtils();
	const { resetRectangleInAGroup, findObjectFromScene } = useSmartImageUtils();
	const { isPopupView, setCurrentScene, setScenes, scenes } = useContext(DesignEditorContext);
	const { uploadAndRegisterBackgroundImages, drawSmartImage } = useSmartImageUtils();
	const [loadingSmartImages, setLoadingSmartImages] = useState<boolean>(false);
	const { imagesStore, playgroundStore } = useAppStore();
	const { t } = useTranslation("translation", { keyPrefix: "editor.images" });
	const resultsContainerRef = useRef<null | HTMLDivElement>(null);
	const menuItems: IMenuItem[] = [
		{
			label: t("download"),
			icon: Download,
			onClick: (image: Image) => {
				{
					downloadImage(image.url, image.visual_hash ?? image.visual_id);
				}
			},
		},
	];

	useEffect(() => {
		if (imagesStore.isGeneratingImages) {
			callScrollIntoView();
		}
	}, [imagesStore.isGeneratingImages]);

	const callScrollIntoView = () => {
		setTimeout(() => {
			if (resultsContainerRef.current) {
				resultsContainerRef?.current.scrollIntoView({ behavior: "smooth", block: "start" });
			}
		}, 200);
	};

	const drawOriginalAndSmartImages = async (image: Image, externalScene?: IScene) => {
		try {
			if (editor) {
				setLoadingSmartImages(true);
				const _currentScene = externalScene ?? editor.scene.exportToJSON();
				const originalImage = await findObjectFromScene(_currentScene, ObjectsEnum.OriginalImage);

				if (
					!originalImage ||
					image.input_params?.originalImage.imageUrl !== imagesStore.smartImageForm.originalImage.imageUrl
				) {
					await uploadAndRegisterBackgroundImages(
						{} as ChangeEvent<HTMLInputElement>,
						image.input_params?.originalImage.imageUrl,
						_currentScene,
					);

					// Setting these states to reflects the smartImage selections
					imagesStore.handleSmartImageChange(
						"backgroundOption",
						(image.input_params as ISmartImageParams).backgroundOption,
					);
					imagesStore.handleSmartImageChange(
						"backgroundDescription",
						(image.input_params as ISmartImageParams).backgroundDescription,
					);
					imagesStore.handleSmartImageChange(
						"colorCode",
						(image.input_params as ISmartImageParams).colorCode,
					);
					imagesStore.handleSmartImageChange(
						"originalImage",
						(image.input_params as ISmartImageParams).originalImage,
					);
				}
				await drawSmartImage(image, _currentScene);
				if (image?.input_params?.backgroundOption === BackgroundOptionsEnum.KEEP_BACKGROUND) {
					imagesStore.handleSmartImageChange("backgroundDescription", "");
				}

				const updatedPreview = (await editor?.renderer.render(_currentScene)) as string;
				const updatedScene = { ..._currentScene, preview: updatedPreview, duration: 1000 };
				setCurrentScene(updatedScene);
				setLoadingSmartImages(false);
			}
		} catch (error) {
			console.error("Error processing smart image:", error);
			setLoadingSmartImages(false);
		}
	};

	return (
		<Box className={styles.smartImageContainer}>
			{imagesStore.smartImageBackgroundOptions.length === 0 ? (
				<>
					<Box className={styles.uploadContainer}>
						<Typography className={styles.title}>{t("smartImage.title")}</Typography>
						<ImageUploader
							className={styles.imageUploader}
							emptyStateClassName={styles.emptyState}
							icon={CloudUploadSVG}
							largeIcon={true}
							loading={imagesStore.isUploadingSmartImageMainObject}
							descriptionClassName={styles.support}
							description={
								<Box className={styles.imageUploaderDesc}>
									<Box className={styles.header}>{t("emptyState.smartImageHeader")}</Box>
								</Box>
							}
							onUpload={async (e) => {
								if (editor) {
									const _currentScene = editor.scene.exportToJSON();
									await uploadAndRegisterBackgroundImages(e, undefined, _currentScene);
									await resetRectangleInAGroup(_currentScene, ObjectsEnum.OuterRectangle);

									const updatedPreview = (await editor?.renderer.render(_currentScene)) as string;
									const updatedScene = { ..._currentScene, preview: updatedPreview, duration: 1000 };
									const updatedScenes = scenes.map((scene: IScene) => {
										if (scene.id === updatedScene.id) {
											return { ...updatedScene, preview: updatedPreview };
										}
										return editor.scene.formalizeSceneAttributes(scene);
									}) as IScene[];
									setScenes(updatedScenes);
									setCurrentScene(updatedScene);
								}
							}}
							inputProps={{ accept: "image/png, image/jpeg, image/jpg, image/webp" }}
						/>
					</Box>
					<Box className={styles.separator_20} />
					<GenerateImagesSection
						textToGenarate={playgroundStore.textToGenerate}
						setTextToGenarate={playgroundStore.setTextToGenerate}
						selctedAspectRatio={playgroundStore.aspectRatio}
						handleGenAspectRatioChange={handleGenAspectRatioChange}
						handleGenSubmit={openText2ImagePopup}
					/>

					{isPopupView && (
						<Box ref={resultsContainerRef} className={styles.generatedImagesGridContainer}>
							<Typography className={styles.title}>{t("smartImage.generatedSmartImages")}</Typography>
							{imagesStore.smartImagesHistory.length > 0 && (
								<ImageGrid
									menuItems={menuItems}
									numberOfColumns={numberOfColumns}
									galleryData={{ showroomImages: { images: imagesStore.smartImagesHistory } }}
									addImageObject={(image) => {
										drawOriginalAndSmartImages(image);
									}}
									tabType={SecondaryTabTypeEnum.SMART_IMAGE}
									hideLoadMoreButton={true}
									loading={loadingSmartImages}
									hideMenuItems={isPopupView}
								/>
							)}
						</Box>
					)}
				</>
			) : (
				<>
					<Box className={styles.subHeaderContainer}>
						<Typography className={styles.title}>{t("smartImage.editBackground")}</Typography>
						<img
							className={styles.icon}
							src={ReplaceImage}
							alt=""
							onClick={async () => {
								if (editor) {
									const _currentScene = editor.scene.exportToJSON();
									await resetRectangleInAGroup(_currentScene, ObjectsEnum.OuterRectangle);
									await resetRectangleInAGroup(_currentScene, ObjectsEnum.InnerRectangle);
									imagesStore.setProperty("smartImageBackgroundOptions", []);
									imagesStore.clearSmartImageForm();

									const updatedPreview = (await editor?.renderer.render(_currentScene)) as string;
									const updatedScene = { ..._currentScene, preview: updatedPreview, duration: 1000 };
									const updatedScenes = scenes.map((scene: IScene) => {
										if (scene.id === updatedScene.id) {
											return { ...updatedScene, preview: updatedPreview };
										}
										return editor.scene.formalizeSceneAttributes(scene);
									}) as IScene[];
									setScenes(updatedScenes);
									setCurrentScene(updatedScene);
								}
							}}
						/>
					</Box>
					<BriaSwitchTab items={imagesStore.smartImageBackgroundOptions} />
					{imagesStore.smartImageForm.backgroundOption === BackgroundOptionsEnum.REPLACE_BACKGROUND ? (
						<ReplaceBackground
							addImageObject={(image) => {
								drawOriginalAndSmartImages(image);
							}}
						/>
					) : imagesStore.smartImageForm.backgroundOption === BackgroundOptionsEnum.KEEP_BACKGROUND ? (
						<GenerateBackground
							addImageObject={(image) => {
								drawOriginalAndSmartImages(image);
							}}
						/>
					) : null}

					<Box ref={resultsContainerRef} className={styles.generatedImagesGridContainer}>
						<Typography className={styles.title}>{t("smartImage.generatedSmartImages")}</Typography>
						{imagesStore.smartImagesHistory.length > 0 && (
							<ImageGrid
								menuItems={menuItems}
								numberOfColumns={numberOfColumns}
								galleryData={{ showroomImages: { images: imagesStore.smartImagesHistory } }}
								addImageObject={(image) => {
									drawOriginalAndSmartImages(image);
								}}
								tabType={SecondaryTabTypeEnum.SMART_IMAGE}
								hideLoadMoreButton={true}
								loading={loadingSmartImages}
								hideMenuItems={isPopupView}
							/>
						)}
					</Box>
				</>
			)}
		</Box>
	);
};

const ObservedComponent = observer(SmartImage);
export default ObservedComponent;
