import { makeAutoObservable, runInAction } from "mobx";
import { Template, defaultTemplate } from "./models/template.ts";

import useErrorToast from "../hooks/useErrorToast";
import { IRootStore } from "../mobx/root-store.tsx";
import { CampaignEntityStatus, CampaignViewModes, PaginatedItems } from "../models/common";
import QueryService from "../utils/QueryService";
import { Editor } from "./core";
import { Placement } from "./models/placement";
import { ILayer, IStaticImage } from "./types";

export enum CampaignPreviewBtn {
	PRIVATE = "private",
	SHARED = "shared",
}

export enum TemplatePreviewBtn {
	ORGANIZATION = "organization",
	PUBLIC = "public",
}
interface VectorColors {
	colors: string[];
	colorMap: Record<string, string>;
}

export interface ICampaignStore {
	templates: PaginatedItems<Template>;
	vectorColors: VectorColors;
	prevColor: string;
	orderedLayers: ILayer[];
	placements: PaginatedItems<Placement>;
	isLoadingTemplates: boolean;
	isTemplateUpdating: boolean;
	isCreatingTemplate: boolean;
	isFirstTimeBrandApplied: boolean;
	isTemplateDuplicationInProgress: boolean;
	isLoadingPlacements: boolean;
	isError: boolean;
	formErrors: { save?: boolean; invalidName?: boolean };
	canvasColorPicker: string;
	isAdminMode: boolean;
	viewMode: CampaignViewModes.FULL_VIEW | CampaignViewModes.GRID_VIEW;
	editor: Editor | null;
	selectedTemplate: Template | null; // DB selected Campaign or template
	selectedWizardTemplate: Template | null;
	templateForm: Template;
	isEditingAllAds: boolean;
	selectedTemplatePreviewBtn: string;
	newCampaignId: number | null;
	selectedCampaignPreviewBtn: string;
	originalLogoObjects: Record<number, IStaticImage[] | undefined>;
	cacheBustingVersion: number;

	loadTemplates(type: CampaignEntityStatus, fetchOrgTemplates: boolean, getPublicTemplates: boolean): Promise<void>;
	getFilteredTemplatesBasesOnPlacementsAndName(
		placementsOptions: any,
		search_term: string,
		type: CampaignEntityStatus,
		fetchOrgTemplates: boolean,
		getPublicTemplates: boolean,
	): Promise<void>;

	updateTemplate(updatedTemplate: Template): Promise<void>;
	duplicateTemplate(template: Template): Promise<number | null>;
	createTemplate(template: Template): Promise<void>;
	loadPlacements(): Promise<void>;
	handleAdminModeChange(isAdmin: boolean): void;
	handleViewModeChange(viewMode: CampaignViewModes): void;
	handleSetEditor(editor: Editor | null): void;
	handleSetSelectedTemplate(template: Template | null): void;
	handleSetSelectedWizardTemplate(template: Template | null): void;
	handleSetSelectedTemplatePreviewButton(btn: TemplatePreviewBtn.ORGANIZATION | TemplatePreviewBtn.PUBLIC): void;
	handleSetSelectedCampaignPreviewButton(btn: CampaignPreviewBtn.PRIVATE | CampaignPreviewBtn.SHARED): void;
	storeOriginalLogoObjects(sceneIndex: number, objects: IStaticImage[]): void;
	getOriginalLogoObjects(sceneIndex: number): IStaticImage[] | undefined;
	setProperty<K extends keyof ICampaignStore>(key: K, value: ICampaignStore[K]): Promise<void>;
}

export default class CampaignStore implements ICampaignStore {
	rootStore: IRootStore;
	errorToast = useErrorToast();
	isLoadingTemplates: boolean = false;
	isLoadingPlacements: boolean = false;
	isError: boolean = false;
	formErrors: { save?: boolean; invalidName?: boolean } = {};
	templates: PaginatedItems<Template> = { total: 0, items: [] };
	placements: PaginatedItems<Placement> = { total: 0, items: [] };
	orderedLayers: ILayer[] = [];
	canvasColorPicker: string = "#fff";
	vectorColors: VectorColors = { colors: [], colorMap: {} };
	prevColor: string = "";
	isAdminMode: boolean = false;
	viewMode: CampaignViewModes.FULL_VIEW | CampaignViewModes.GRID_VIEW = CampaignViewModes.FULL_VIEW;
	editor: Editor | null = null;
	selectedTemplate: Template | null = null;
	selectedWizardTemplate: Template | null = null;
	isEditingAllAds: boolean = false;
	isTemplateUpdating: boolean = false;
	isTemplateDuplicationInProgress: boolean = false;
	isCreatingTemplate: boolean = false;
	templateForm: Template = defaultTemplate;
	newCampaignId: number | null = null;
	selectedTemplatePreviewBtn: string = TemplatePreviewBtn.ORGANIZATION;
	selectedCampaignPreviewBtn: string = CampaignPreviewBtn.PRIVATE;
	isFirstTimeBrandApplied: boolean = true;
	originalLogoObjects: Record<number, IStaticImage[] | undefined> = {};
	cacheBustingVersion: number = Math.floor(Math.random() * 1000) + 1;
	private campaignQueryService: QueryService = new QueryService("/campaign");

	constructor(rootStore: IRootStore) {
		makeAutoObservable(this);
		this.rootStore = rootStore;
	}

	setProperty = async <K extends keyof CampaignStore>(key: K, value: CampaignStore[K]) => {
		runInAction(() => ((this as CampaignStore)[key] = value));
	};

	loadTemplates = async (
		type: CampaignEntityStatus,
		fetchOrgTemplates: boolean,
		getPublicTemplates: boolean,
	): Promise<void> => {
		try {
			this.isError = false;
			this.isLoadingTemplates = true;

			const templates: PaginatedItems<Template> = await this.campaignQueryService.get("/templatesV2", {
				params: {
					templateStatus: type,
					fetchOrgTemplates: fetchOrgTemplates,
					getPublicTemplates: getPublicTemplates,
				},
			});

			runInAction(() => {
				this.templates = templates;
				this.isError = false;
				this.isLoadingTemplates = false;
			});
		} catch (e) {
			runInAction(() => {
				this.isLoadingTemplates = false;
				this.isError = true;
			});
		}
	};

	getFilteredTemplatesBasesOnPlacementsAndName = async (
		selected_placements_resolution: any,
		search_term: string,
		type: CampaignEntityStatus,
		fetchOrgTemplates: boolean,
		getPublicTemplates: boolean,
	): Promise<void> => {
		try {
			this.isError = false;
			this.isLoadingTemplates = true;

			const templates: PaginatedItems<Template> = await this.campaignQueryService.get(
				"/placementsV2/filteredPlacements",
				{
					params: {
						search_term: search_term,
						selected_placements_resolution: selected_placements_resolution,
						templateStatus: type,
						fetchOrgTemplates: fetchOrgTemplates,
						getPublicTemplates: getPublicTemplates,
					},
				},
			);

			runInAction(() => {
				this.templates = templates;
				this.isError = false;
				this.isLoadingTemplates = false;
			});
		} catch (e) {
			runInAction(() => {
				this.isLoadingTemplates = false;
				this.isError = true;
			});
		}
	};

	updateTemplate = async (updatedTemplate: Template): Promise<void> => {
		this.isError = false;
		this.isTemplateUpdating = true;
		try {
			await this.campaignQueryService.put("/templatesV2", updatedTemplate);
			runInAction(() => {
				this.templates.items = this.templates?.items
					.map((template) => (template.id === updatedTemplate.id ? updatedTemplate : template))
					.filter((template) => template.status !== CampaignEntityStatus.DELETED);
				this.isError = false;
				this.isTemplateUpdating = false;
				this.cacheBustingVersion = Math.floor(Math.random() * 1000) + 1;
			});
		} catch (e: any) {
			runInAction(() => {
				this.isError = true;
				this.isTemplateUpdating = false;
			});
		}
	};

	duplicateTemplate = async (template: Template): Promise<number | null> => {
		this.isError = false;
		this.isTemplateDuplicationInProgress = true;
		try {
			const templateId = await this.campaignQueryService.post("/templatesV2/duplicate", template);
			runInAction(() => {
				this.isError = false;
				this.isTemplateDuplicationInProgress = false;
				this.cacheBustingVersion = Math.floor(Math.random() * 1000) + 1;
			});
			return templateId;
		} catch (e: any) {
			runInAction(() => {
				this.isError = true;
				this.isTemplateDuplicationInProgress = false;
			});
			return null;
		}
	};

	createTemplate = async (template: Template): Promise<void> => {
		this.isError = false;
		this.isCreatingTemplate = true;
		try {
			const template_id = await this.campaignQueryService.post("/templatesV2/create", template);
			template.id = template_id;
			runInAction(() => {
				this.isError = false;
				this.isCreatingTemplate = false;
				this.handleSetSelectedTemplate(template);
				this.cacheBustingVersion = Math.floor(Math.random() * 1000) + 1;
			});
		} catch (e: any) {
			runInAction(() => {
				this.isError = true;
				this.isCreatingTemplate = false;
			});
		}
	};

	loadPlacements = async (): Promise<void> => {
		try {
			this.isError = false;
			this.isLoadingPlacements = true;
			const placements: PaginatedItems<Placement> = await this.campaignQueryService.get("/placementsV2");

			runInAction(() => {
				this.placements = placements;
				this.isLoadingPlacements = false;
				this.isError = false;
			});
		} catch (e) {
			runInAction(() => {
				this.isLoadingPlacements = false;
				this.isError = true;
			});
			throw e;
		}
	};

	handleAdminModeChange = (isAdmin: boolean) => {
		runInAction(() => {
			this.isAdminMode = isAdmin;
		});
	};

	handleViewModeChange = (viewMode: CampaignViewModes) => {
		runInAction(() => {
			this.viewMode = viewMode;
		});
	};

	handleSetEditor = (editor: Editor | null) => {
		runInAction(() => {
			this.editor = editor;
		});
	};

	handleSetSelectedTemplate = (template: Template | null) => {
		runInAction(() => {
			this.selectedTemplate = template;
		});
	};

	handleSetSelectedWizardTemplate = (template: Template | null) => {
		runInAction(() => {
			this.selectedWizardTemplate = template;
		});
	};

	handleSetSelectedTemplatePreviewButton(button: TemplatePreviewBtn.ORGANIZATION | TemplatePreviewBtn.PUBLIC): void {
		this.selectedTemplatePreviewBtn = button;
	}

	handleSetSelectedCampaignPreviewButton(button: CampaignPreviewBtn.PRIVATE | CampaignPreviewBtn.SHARED): void {
		this.selectedCampaignPreviewBtn = button;
	}
	storeOriginalLogoObjects(sceneIndex: number, objects: IStaticImage[]) {
		this.originalLogoObjects[sceneIndex] = objects;
	}

	getOriginalLogoObjects(sceneIndex: number): IStaticImage[] | undefined {
		return this.originalLogoObjects[sceneIndex];
	}
}
