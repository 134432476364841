export enum APPS {
	TEXT_TO_IMAGE = "textToImage",
	TEXT_TO_VECTOR = "textToVector",
	IMAGE_TO_IMAGE = "imageToImage",
	PRODUCT_PLACEMENT = "productPlacement",
	SANDBOX_API = "sandboxAPI",
	DESIGN_EDITOR = "designEditor",
}

export const SANDBOX_API_BASE_URL = "https://engine.prod.bria-api.com/v1";
