import { Box, ButtonProps, CircularProgress } from "@mui/material";
import clsx from "clsx";
import { useState } from "react";
import { useAppStore } from "../../../../../../../../../hooks/useStores.tsx";
import { BackgroundOption, BackgroundOptionsEnum } from "../../../index.ts";
import styles from "./BriaSwitchTab.module.scss";

interface IProps {
	items: BackgroundOption[];
}

export default function BriaSwitchTab({ items }: IProps & ButtonProps) {
	const { imagesStore } = useAppStore();
	const [currentGenerationOption, setCurrentGenerationOption] = useState<BackgroundOptionsEnum | null>(
		BackgroundOptionsEnum.KEEP_BACKGROUND,
	);

	const handleClick = (title: BackgroundOptionsEnum) => {
		if (!imagesStore.isGeneratingImages) {
			setCurrentGenerationOption(title);
		}
		imagesStore.handleSmartImageChange("backgroundOption", title);
	};

	return (
		<Box className={styles.container}>
			{items.map((item: BackgroundOption) => (
				<Box
					key={item.title}
					className={clsx({
						[styles.item]: true,
						[styles.selected]: item.title === imagesStore.smartImageForm.backgroundOption,
					})}
					onClick={() => handleClick(item.title)}
				>
					{((item.title === currentGenerationOption && imagesStore.isGeneratingImages) ||
						imagesStore.isUploadingSmartImageMainObject) && (
						<>
							<Box className={styles.blurLayer} />
							<CircularProgress size={24} className={styles.loader} />
						</>
					)}
					<img src={item.imageUrl} className={styles.trash} alt={`${item.title}`} />
					<Box className={styles.title}>{item.title}</Box>
				</Box>
			))}
		</Box>
	);
}
