import { Alert, Box, Snackbar, Tooltip } from "@mui/material";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CopyIcon } from "../../../assets/images/icons/CopyIcon.tsx";
import DeleteIcon from "../../../assets/images/icons/DeleteIcon.tsx";
import { EditIcon2 } from "../../../assets/images/icons/EditIcon2.tsx";
import EyeIcon from "../../../assets/images/icons/EyeIcon.tsx";
import { PaginationOptions } from "../../../components/common/BriaTable/BriaTable.tsx";
import ConfirmationPopup from "../../../components/common/ConfirmationPopup/ConfirmationPopup.tsx";
import EmptyTable from "../../../components/common/EmptyTable/EmptyTable.tsx";
import RouterConstants from "../../../constants/RouterConstants.ts";
import { TAILORED_GENERATION_DATASET_STATUS, TAILORED_GENERATION_MODEL_STEP } from "../../../constants/TGConstants.ts";
import useSecureNavigate from "../../../hooks/useSecureNavigate.tsx";
import { useAppStore } from "../../../hooks/useStores.tsx";
import TableLayout, { TableRow } from "../../../layout/TableLayout/TableLayout.tsx";
import { DatasetTableRow, ITailoredGenerationDataset } from "../../../models/tailoredGeneration.ts";
import styles from "./TgDatasets.module.scss";

const TgDatasets = () => {
	const navigate = useSecureNavigate();
	const { tailoredGenerationStore, uiStore, authStore } = useAppStore();
	const [selectedDataset, setSelectedDataset] = useState<ITailoredGenerationDataset>();
	const [openDeletePopup, setOpenDeletePopup] = useState(false);
	const { t } = useTranslation("translation", { keyPrefix: "tailored_generation.datasets" });
	const { t: t1 } = useTranslation("translation", { keyPrefix: "tailored_generation" });
	const [tableData, setTableData] = useState<TableRow<DatasetTableRow>[]>([]);
	const headerArray = [t("datasetName"), t("images"), t("creationDate"), t("status"), t("actions")];

	const paginationOptions: PaginationOptions = {
		rowsPerPage: tailoredGenerationStore.rowsPerPage,
		totalRows: tailoredGenerationStore.paginatedDatasets.total,
		loadNextPage: async (pageNumber: number) => {
			tailoredGenerationStore.paginatedDatasets.items = tailoredGenerationStore.datasets.slice(
				pageNumber * tailoredGenerationStore.rowsPerPage,
				pageNumber * tailoredGenerationStore.rowsPerPage + tailoredGenerationStore.rowsPerPage,
			);
		},
	};

	useEffect(() => {
		const loadData = async () => {
			if (tailoredGenerationStore.datasets.length === 0) {
				await tailoredGenerationStore.loadDatasets();
			}
		};

		const initialize = async () => {
			try {
				loadData();
				uiStore.updateTgStep(TAILORED_GENERATION_MODEL_STEP.LIST_DATASETS);
			} catch (error) {
				console.error(error);
			}
		};

		initialize();
	}, []);

	useEffect(() => {
		setTableData(
			tailoredGenerationStore.paginatedDatasets.items.map((dataset: ITailoredGenerationDataset) =>
				createRow(dataset),
			),
		);
	}, [tailoredGenerationStore.paginatedDatasets.items]);

	const createRow = (dataset: ITailoredGenerationDataset): TableRow<DatasetTableRow> => {
		const name = (
			<Box className={styles.item}>
				<Box>{dataset.name}</Box>
			</Box>
		);
		const images = (
			<Box className={styles.item}>
				<Box>{dataset.numberOfAssets}</Box>
			</Box>
		);
		const creationDate = (
			<Box className={styles.item}>
				<Box>{dataset.creationDate}</Box>
			</Box>
		);
		const status = (
			<Box className={styles.status}>
				<Box>{dataset.status}</Box>
			</Box>
		);
		const actions = (
			<Box className={styles.actions}>
				{dataset.status === TAILORED_GENERATION_DATASET_STATUS.DRAFT && (
					<EditIcon2
						className={clsx({
							[styles.actionBtn]: true,
							[styles.hidden]: true,
						})}
						onClick={() => {}}
						sx={{ width: "18px !important" }}
					/>
				)}
				{dataset.status === TAILORED_GENERATION_DATASET_STATUS.COMPLETED && (
					<Tooltip title={t("viewDataset")} placement="top">
						<Box className={styles.iconContainer}>
							<EyeIcon
								className={styles.actionBtn}
								onClick={() => {
									uiStore.updateTailoredGenerationDataset();
									navigate(`${RouterConstants.DATASET.editPath}/${dataset.id}`);
								}}
								sx={{ width: "21px !important" }}
							/>
						</Box>
					</Tooltip>
				)}
				<Tooltip title={t("duplicateDataset")} placement="top">
					<Box
						className={styles.copyIconContainer}
						onClick={() => {
							tailoredGenerationStore.createNewDataset = true;
							tailoredGenerationStore.handleFormChange("dataset", dataset);
							tailoredGenerationStore.selectedDatasetOption = dataset;
							uiStore.setIsDuplicateDatasetFlow();
							navigate(RouterConstants.TG_CREATE_DATASET.fullPath);
						}}
					>
						<CopyIcon width="16" height="16" />
					</Box>
				</Tooltip>

				{dataset.status !== TAILORED_GENERATION_DATASET_STATUS.DELETED && (
					<Tooltip title={t("deleteDataset")} placement="top">
						<Box className={styles.iconContainer}>
							<DeleteIcon className={styles.actionBtn} onClick={() => handleOpenDeletePopup(dataset)} />
						</Box>
					</Tooltip>
				)}
			</Box>
		);

		const newRow: TableRow<DatasetTableRow> = {
			key: dataset.id,
			data: {
				datasetName: name,
				images: images,
				creationDate: creationDate,
				status: status,
				actions: actions,
			},
		};

		return newRow;
	};

	const deleteDataset = async (datasetToDelete: ITailoredGenerationDataset) => {
		try {
			await tailoredGenerationStore.deleteDataset(datasetToDelete);
			await tailoredGenerationStore.loadDatasets();
			closeDeletePopup();
		} catch (e) {
			console.error(e);
		}
	};

	const handleOpenDeletePopup = (dataset: ITailoredGenerationDataset) => {
		setOpenDeletePopup(true);
		setSelectedDataset(dataset);
	};

	const closeDeletePopup = () => {
		setOpenDeletePopup(false);
		setSelectedDataset(undefined);
	};
	const handleCloseSnackbar = () => {
		uiStore.hideSnackbar("datasetCreationSuccessSnackbar");
	};

	return (
		<>
			<TableLayout
				title={t("mainTitle")}
				headerArray={headerArray}
				tableData={tableData}
				enableSearch={false}
				paginationOptions={paginationOptions}
				loading={authStore.isLoadingOrgSubscriptions || tailoredGenerationStore.isLoading}
				primaryButtonLoading={authStore.isLoadingOrgSubscriptions}
				disablePrimaryButton={authStore.isOrgBlocked}
				primaryButtonText={t("createDataset")}
				primaryButtonClick={() => navigate(RouterConstants.TG_CREATE_DATASET.fullPath)}
				secondaryButtonText={t1("datasetBestPractices")}
				secondaryButtonClick={() => {
					uiStore.showDialog("GuidelinesForTailoredModelsDialog");
				}}
				emptyStateComponent={<EmptyTable message={t("emptyState")} />}
			/>
			<ConfirmationPopup
				onClose={closeDeletePopup}
				title={t("deletePopup.title")}
				description={t("deletePopup.description")}
				confirmButtonText={t("deletePopup.confirmText")}
				open={openDeletePopup}
				onClick={() => selectedDataset && deleteDataset(selectedDataset)}
				loading={tailoredGenerationStore.isDeleting || tailoredGenerationStore.isLoading}
			/>
			<Snackbar
				open={uiStore.datasetCreationSuccessSnackbar}
				autoHideDuration={2000}
				onClose={handleCloseSnackbar}
			>
				<Alert onClose={handleCloseSnackbar} severity="success">
					{t("datasetCreatedSuccessfully")}
				</Alert>
			</Snackbar>
		</>
	);
};

const ObservedComponent = observer(TgDatasets);
export default ObservedComponent;
