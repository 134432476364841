import { styled } from "baseui";
import React, { useContext } from "react";
import { DesignEditorContext } from "../../../../contexts/DesignEditor";
import { useActiveObject } from "../../../../hooks/useActiveObject";
import useAppContext from "../../../../hooks/useAppContext";
import { useEditor } from "../../../../hooks/useEditor";
import type { ILayer } from "../../../../types";
import getSelectionType from "../../../../utils/get-selection-type";
import Items from "./Items";

const DEFAULT_TOOLBOX = "Canvas";

interface ToolboxState {
	toolbox: string;
}

const Container = styled("div", (_props) => ({}));

const Toolbox = () => {
	const [state, setState] = React.useState<ToolboxState>({ toolbox: "Text" });
	const { setActiveSubMenu } = useAppContext();
	const activeObject = useActiveObject() as ILayer;
	const editor = useEditor();
	const { isPopupView } = useContext(DesignEditorContext);
	React.useEffect(() => {
		const selectionType = getSelectionType(activeObject, isPopupView);
		if (selectionType) {
			if (selectionType.length > 1) {
				setState({ toolbox: "Multiple" });
			} else {
				setState({ toolbox: selectionType[0] });
			}
		} else {
			setState({ toolbox: DEFAULT_TOOLBOX });
			setActiveSubMenu("");
		}
	}, [activeObject]);

	React.useEffect(() => {
		let watcher = async () => {
			if (activeObject) {
				// @ts-ignore
				const selectionType = getSelectionType(activeObject, isPopupView) as any;

				if (selectionType.length > 1) {
					setState({ toolbox: "Multiple" });
				} else {
					setState({ toolbox: selectionType[0] });
				}
			}
		};
		if (editor) {
			editor.on("history:changed", watcher);
		}
		return () => {
			if (editor) {
				editor.off("history:changed", watcher);
			}
		};
	}, [editor, activeObject]);

	// @ts-ignore
	const Component = state.toolbox == "Frame" ? Items["Canvas"] : Items[state.toolbox];

	return <Container>{Component ? <Component /> : state.toolbox}</Container>;
};

export default Toolbox;
