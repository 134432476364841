import { Box, Divider, FormControlLabel, FormGroup, SelectChangeEvent, TextField } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CrownIcon } from "../../../../assets/images/icons//CrownIcon.tsx";
import BriaCheckbox from "../../../../components/common/BriaCheckbox/BriaCheckbox.tsx";
import BriaDropdown, { DropDownItem } from "../../../../components/common/BriaDropDown/BriaDropDown";
import ColorPicker from "../../../../components/common/ColorPicker/ColorPicker";
import DebouncedTextField from "../../../../components/common/DebouncedInput/DebouncedInput";
import StaticDropdown, { AspectRatio, GenModel } from "../../../../components/common/DropDowns/StaticDropdown.tsx";
import useStaticDropdown from "../../../../components/common/DropDowns/useStaticDropdown.ts";
import TextColorPicker from "../../../../components/common/TextColorPicker/TextColorPicker";
import useBriaAxios from "../../../../hooks/useBriaAxios";
import { useAppStore } from "../../../../hooks/useStores.tsx";
import InputLayout from "../../../../layout/InputLayout/InputLayout";
import { OrgFeatures } from "../../../../models/billing.ts";
import { Gallery } from "../../../../models/gallery";
import { Iframe } from "../../../../models/iframe.ts";
import { ITailoredGenerationEndpoint } from "../../../../models/tailoredGeneration.ts";
import { isAccentureOrg, isAmanaOrg, isBriaOrg, isPublicisOrg } from "../../../../utils";
import {
	FeaturesLabels,
	IframeFormType,
	ImportExportLabels,
	LanguagesEnum,
	PagesEnum,
	PagesLabels,
} from "../../iframeTypes";
import IframeCheckboxGroup from "./IframeCheckboxGroup";
import styles from "./IframeForm.module.scss";

interface Props {
	iframeForm: IframeFormType;
	pagesOptions: DropDownItem[];
	setPreviewPage: (previewPage?: PagesEnum) => void;
	handleFormChange: <K extends keyof IframeFormType>(key: K, value: IframeFormType[K]) => void;
}

const IframeForm = ({ iframeForm, pagesOptions, setPreviewPage, handleFormChange }: Props) => {
	const {
		data: orgGalleries,
		loading: loadingGalleries,
		error: errorGalleries,
	} = useBriaAxios<Gallery[]>({
		method: "get",
		url: `/org_galleries`,
	});

	const {
		data: orgTailoredEndpoints,
		loading: loadingTailoredEndpoints,
		error: errorTailoredEndpoints,
	} = useBriaAxios<ITailoredGenerationEndpoint[]>({
		method: "get",
		url: `/tailored_generation/endpoints/`,
	});

	const [galleryOptions, setGalleryOptions] = useState<DropDownItem[]>([]);
	const [tailoredModelsOptions, setTailoredModelsOptions] = useState<DropDownItem[]>([]);
	const [selectedGallery, setSelectedGallery] = useState("");
	const { t } = useTranslation("translation", { keyPrefix: "iframeEditor.form" });
	const { authStore, pricingStore } = useAppStore();
	const { items: genModelsItems } = useStaticDropdown<GenModel>({ tKeyPrefix: "genModels" });

	const pagesLabels: PagesLabels = Object.fromEntries(
		Object.keys(PagesEnum)
			.filter(
				(page) =>
					(PagesEnum[page as keyof typeof PagesEnum] !== PagesEnum.Assets &&
						PagesEnum[page as keyof typeof PagesEnum] !== PagesEnum.Campaign) ||
					(PagesEnum[page as keyof typeof PagesEnum] === PagesEnum.Assets && isPublicisOrg()) ||
					(PagesEnum[page as keyof typeof PagesEnum] === PagesEnum.Campaign &&
						(isBriaOrg() || isAccentureOrg())),
			)
			.map((page) => [PagesEnum[page as keyof typeof PagesEnum], page]),
	);
	const languagesOptions: DropDownItem[] = Object.entries(iframeForm.enabled_languages || {}).map(([key]) => ({
		key:
			Object.keys(LanguagesEnum).find(
				(enumKey) => LanguagesEnum[enumKey as keyof typeof LanguagesEnum] === key,
			) || "",
		value: key,
	}));
	const featuresLabels: FeaturesLabels = t("enabled_features.checkboxes", {
		returnObjects: true,
	});
	const importCheckboxLabels: ImportExportLabels = t("importExport.checkboxes", {
		returnObjects: true,
	});

	useEffect(() => {
		if (orgGalleries) {
			const galleryOptions: DropDownItem[] = orgGalleries.map((gallery: Gallery) => ({
				key: gallery.name,
				value: gallery.id,
			}));
			const briaGalleryId = 3;
			if (!galleryOptions.some((gallery) => gallery.value === briaGalleryId)) {
				galleryOptions.push({ key: "Public Gallery", value: "use_public_gallery" });
			}
			setGalleryOptions(galleryOptions);
			setSelectedGallery(
				iframeForm.use_public_gallery
					? "use_public_gallery"
					: ((orgGalleries.find((gal) => gal.org_id === (iframeForm as Iframe).organization)?.id ??
							"") as string),
			);
		}
	}, [orgGalleries]);

	useEffect(() => {
		if (orgTailoredEndpoints) {
			const tailoredModelsOptions: DropDownItem[] = orgTailoredEndpoints.map(
				(tailoredModel: ITailoredGenerationEndpoint) => ({
					key: tailoredModel.name,
					value: tailoredModel.id as string,
					icon: !authStore.isFeatureEnabled(OrgFeatures.TAILORED_GENERATION) ? <CrownIcon /> : "",
				}),
			);
			setTailoredModelsOptions(tailoredModelsOptions);
		}
	}, [orgTailoredEndpoints, authStore.isLoadingOrgSubscriptions]);

	const handleStartingPageChange = (e: SelectChangeEvent<PagesEnum>) => {
		const selectedTarget = e.target.value as PagesEnum;
		handleFormChange("target", selectedTarget);
		handleFormChange("enabled_pages", { ...iframeForm["enabled_pages"], [selectedTarget]: true });
		setPreviewPage(selectedTarget);
	};

	const handleLanguageChange = (e: SelectChangeEvent<LanguagesEnum>) => {
		const selectedLanguage = e.target.value as LanguagesEnum;
		if (iframeForm.enabled_languages) {
			Object.keys(iframeForm.enabled_languages).forEach((key) => {
				if (iframeForm.enabled_languages) {
					iframeForm.enabled_languages[key as LanguagesEnum] = key === selectedLanguage;
				}
			});
		}
		handleFormChange("enabled_languages", iframeForm.enabled_languages);
	};

	const handleGenAspectRatioChange = (e: SelectChangeEvent<AspectRatio | AspectRatio[]>) => {
		iframeForm.default_gen_aspect_ratio = e.target.value as AspectRatio;
		handleFormChange("default_gen_aspect_ratio", iframeForm.default_gen_aspect_ratio);
	};

	const handleGalleryChange = (e: SelectChangeEvent<typeof selectedGallery>) => {
		handleFormChange("use_public_gallery", e.target.value === "use_public_gallery");
		setSelectedGallery(e.target.value);
	};

	const handleGenModelChange = (e: SelectChangeEvent<GenModel | GenModel[]>) => {
		const selectedModels = e.target.value as GenModel[];
		const updatedGenModels = Object.fromEntries(
			selectedModels.map((value) => [
				value,
				iframeForm.gen_models
					? iframeForm.gen_models[value as GenModel] ??
					  genModelsItems.find((model) => model.value === value)?.key
					: genModelsItems.find((model) => model.value === value)?.key,
			]),
		);
		handleFormChange("gen_models", updatedGenModels);
	};

	const handleDeleteGenModel = (keyToDelete: string | number): void => {
		const updatedGenModels = { ...iframeForm?.gen_models };
		delete updatedGenModels[keyToDelete as GenModel];
		handleFormChange("gen_models", updatedGenModels);
	};

	const handleEditGenModelLabel = (keyToEdit: string | number, newValue: string | number): void => {
		if (newValue) {
			const updatedGenModels = { ...iframeForm?.gen_models };
			updatedGenModels[keyToEdit as GenModel] = newValue as string;
			handleFormChange("gen_models", updatedGenModels);
		}
	};

	const handleTgModelChange = (e: SelectChangeEvent<string[]>) => {
		if (authStore.isFeatureEnabled(OrgFeatures.TAILORED_GENERATION)) {
			handleFormChange("enabled_tg_models", e.target.value as string[]);
		} else {
			pricingStore.openPricingFlow();
		}
	};

	const handleDeleteTgModel = (keyToDelete: string | number): void => {
		const updatedTgModels = iframeForm?.enabled_tg_models ?? [];
		const index = updatedTgModels.indexOf(keyToDelete as string);
		if (index > -1) {
			updatedTgModels.splice(index, 1);
		}
		handleFormChange("enabled_tg_models", updatedTgModels);
	};

	const enabledLanguages = Object.entries(iframeForm?.enabled_languages ?? {})
		.filter(([_key, value]) => value)
		.map(([key, _value]) => key);
	const enabledLanguage = enabledLanguages.length > 0 ? enabledLanguages[0] : LanguagesEnum.English;

	useEffect(() => {
		const enabledPages = Object.entries(iframeForm.enabled_pages ?? []).filter(
			([page, value]) => Object.keys(pagesLabels).includes(page) && value,
		) as [PagesEnum, boolean][];

		if (enabledPages.length === 1) {
			handleFormChange("target", enabledPages[0][0]);
		}
	}, [iframeForm.enabled_pages]);

	return (
		<Box className={styles.container}>
			<InputLayout label={t("name.label")}>
				<TextField
					value={iframeForm.name}
					onChange={(e) => handleFormChange("name", e.target.value)}
					placeholder={t("name.placeholder")}
					fullWidth
					InputProps={{ classes: { root: styles.textField } }}
				/>
			</InputLayout>
			<InputLayout label={t("description.label")}>
				<TextField
					value={iframeForm.description}
					onChange={(e) => handleFormChange("description", e.target.value)}
					placeholder={t("description.placeholder")}
					fullWidth
					multiline
					minRows={3}
					InputProps={{
						classes: {
							root: styles.bigTextField,
						},
					}}
				/>
			</InputLayout>
			<InputLayout label={t("availablePages.label")}>
				<IframeCheckboxGroup
					checkboxes={pagesLabels}
					checkboxConfig="enabled_pages"
					{...{ iframeForm, handleFormChange, setPreviewPage }}
				/>
			</InputLayout>
			{(isAmanaOrg() || isBriaOrg() || isAccentureOrg()) && (
				<InputLayout label={t("displayLanguage.label")}>
					<BriaDropdown
						value={enabledLanguage as LanguagesEnum}
						onChange={handleLanguageChange}
						placeholder={t("displayLanguage.placeholder")}
						items={languagesOptions}
						className={styles.dropDown}
						width={"-webkit-fill-available"}
					/>
				</InputLayout>
			)}
			<InputLayout label={t("startingPage.label")}>
				<BriaDropdown
					value={iframeForm?.target}
					onChange={handleStartingPageChange}
					placeholder={t("startingPage.placeholder")}
					items={pagesOptions}
					className={styles.dropDown}
					width={"-webkit-fill-available"}
				/>
			</InputLayout>
			{iframeForm.enabled_pages?.gallery && (
				<InputLayout label={t("chooseGallery.label")}>
					<BriaDropdown
						value={selectedGallery}
						onChange={handleGalleryChange}
						placeholder={t("chooseGallery.placeholder")}
						items={galleryOptions}
						className={styles.dropDown}
						width={"-webkit-fill-available"}
						loading={loadingGalleries}
						error={!!errorGalleries}
					/>
				</InputLayout>
			)}
			{iframeForm.enabled_pages?.gallery && (
				<InputLayout label={t("genModels.label")}>
					<BriaDropdown
						value={
							Object.entries(iframeForm?.gen_models ?? {})
								.filter(([_key, value]) => value)
								.map(([key, _value]) => key) as GenModel[]
						}
						items={genModelsItems.map((item) => ({
							key: iframeForm.gen_models
								? iframeForm.gen_models[item.value as GenModel] ?? item.key
								: item.key,
							value: item.value,
						}))}
						onChange={handleGenModelChange}
						handleDelete={handleDeleteGenModel}
						handleEditItem={handleEditGenModelLabel}
						placeholder={t("genModels.placeholder")}
						multiple
						className={styles.dropDown}
						width={"-webkit-fill-available"}
					/>
				</InputLayout>
			)}
			{iframeForm.enabled_pages?.gallery && (
				<InputLayout label={t("enabledTgModels.label")}>
					<BriaDropdown
						height={(iframeForm?.enabled_tg_models ?? []).length > 0 ? "auto" : undefined}
						menuMaxHeight={"300px"}
						value={iframeForm?.enabled_tg_models ?? []}
						onChange={handleTgModelChange}
						handleDelete={handleDeleteTgModel}
						loading={loadingTailoredEndpoints || authStore.isLoadingOrgSubscriptions}
						error={!!errorTailoredEndpoints}
						placeholder={
							tailoredModelsOptions.length > 0
								? t("enabledTgModels.placeholder")
								: t("enabledTgModels.noModels")
						}
						multiple
						disabled={tailoredModelsOptions.length === 0 || authStore.isLoadingOrgSubscriptions}
						items={tailoredModelsOptions}
						className={styles.dropDown}
						width={"-webkit-fill-available"}
					/>
				</InputLayout>
			)}
			{iframeForm.enabled_pages?.gallery && (
				<InputLayout label={t("defaultGenAspectRatio.label")}>
					<StaticDropdown<AspectRatio>
						type="aspectRatio"
						value={iframeForm.default_gen_aspect_ratio}
						onChange={handleGenAspectRatioChange}
						placeholder={t("defaultGenAspectRatio.placeholder")}
						className={styles.dropDown}
						width={"-webkit-fill-available"}
					/>
				</InputLayout>
			)}
			<Divider className={styles.divider} />
			<InputLayout label={t("customStyle")}>
				<InputLayout label={t("primaryColor.label")}>
					<Box className={styles.colorWrapper}>
						<ColorPicker
							circleWidth="33px"
							color={iframeForm?.custom_style?.primaryColor}
							onColorChange={(color) => {
								handleFormChange("custom_style", { ...iframeForm.custom_style, primaryColor: color });
							}}
						/>
						<TextColorPicker
							color={iframeForm?.custom_style?.primaryColor}
							onColorChange={(color) => {
								handleFormChange("custom_style", { ...iframeForm.custom_style, primaryColor: color });
							}}
						/>
					</Box>
				</InputLayout>
			</InputLayout>
			<Divider />
			<InputLayout label={t("controlsConfiguration.label")}>
				<FormGroup>
					{iframeForm.enabled_pages?.gallery && (
						<FormControlLabel
							control={
								<BriaCheckbox
									checked={!iframeForm.embedded_iframe}
									onChange={(e) => {
										handleFormChange("embedded_iframe", !e.target.checked);
									}}
								/>
							}
							label={t("controlsConfiguration.checkboxes.galleryHeader")}
						/>
					)}
					<FormControlLabel
						control={
							<BriaCheckbox
								checked={iframeForm.enable_close_button}
								onChange={(e) => {
									handleFormChange("enable_close_button", e.target.checked);
								}}
							/>
						}
						label={t("controlsConfiguration.checkboxes.closeWindowButton")}
					/>
				</FormGroup>
			</InputLayout>
			<InputLayout label={t("headerInfo.label")}>
				<DebouncedTextField
					value={iframeForm.usageText}
					onChange={(e) => handleFormChange("usageText", e.target.value)}
					placeholder={t("headerInfo.placeholder")}
					fullWidth
					multiline
					minRows={3}
					InputProps={{ classes: { root: styles.bigTextField } }}
				/>
			</InputLayout>
			<InputLayout label={t("enabled_features.label")}>
				<IframeCheckboxGroup
					checkboxes={featuresLabels}
					checkboxConfig="enabled_features"
					{...{ iframeForm, handleFormChange }}
				/>
			</InputLayout>
			<InputLayout label={t("importExport.label")}>
				<IframeCheckboxGroup
					checkboxes={importCheckboxLabels}
					checkboxConfig="importExportConfig"
					{...{ iframeForm, handleFormChange }}
				/>
			</InputLayout>
		</Box>
	);
};

const ObservedComponent = observer(IframeForm);
export default ObservedComponent;
