import { makeAutoObservable, runInAction } from "mobx";
import { MutableRefObject, useRef } from "react";
import { IRootStore } from "../mobx/root-store.tsx";
import { FontTypeEnum, HorizontalEnum, LogoTypeEnum, VerticalEnum } from "../models/common.ts";
import { BASE_ITEMS, PanelType } from "./constants/app-options.ts";
import { PopUpSteps } from "./constants/mock-data.ts";
import { Template } from "./models/template.ts";
import { ILayer } from "./types";

export interface IDesignEditorStore {
	isLoading: boolean;
	isError: boolean;
	designEditorPopup: boolean;
	activeStep: number;
	newStep: number;
	campaignName: string;
	editorStepperSteps: { id: string; title: string; tabName: PanelType }[];
	editorStepperDisabledStep: number | undefined;
	isTemplateEmptyState: boolean;
	hideCampaignResultContent: boolean;
	isGenerateAdsLoading: boolean;
	numberOfProcessedAdvertisements: number;
	isLoadingTemplate: boolean;
	campaignUpdatedTemplate: Template | undefined;
	hasSmartImages: boolean;
	originalInnerRectangle?: ILayer;
	isLoadingSmartImageStep: boolean;
	containerRef: MutableRefObject<null | HTMLDivElement>;
	activeObjectSettings: {
		colorNumber?: number;
		fontType?: FontTypeEnum;
		logoType?: LogoTypeEnum;
		horizontalAlignment?: HorizontalEnum;
		verticalAlignment?: VerticalEnum;
	};

	setProperty<K extends keyof IDesignEditorStore>(key: K, value: IDesignEditorStore[K]): Promise<void>;

	getItemPropertiesById(id: string): { name: string; stepperTitle: string };

	resetCampaignPopup(): void;
}

export default class DesignEditorStore implements IDesignEditorStore {
	rootStore: IRootStore;
	isLoading: boolean = false;
	isError: boolean = false;
	designEditorPopup: boolean = false;
	activeStep: number = 0;
	newStep: number = 0;
	campaignName: string = "";
	editorStepperSteps: { id: string; title: string; tabName: PanelType }[] = [];
	isTemplateEmptyState: boolean = true;
	hideCampaignResultContent: boolean = true;
	isGenerateAdsLoading: boolean = false;
	numberOfProcessedAdvertisements: number = 1;
	isLoadingTemplate: boolean = false;
	campaignUpdatedTemplate: Template | undefined = undefined;
	editorStepperDisabledStep: number | undefined = undefined;
	hasSmartImages: boolean = false;
	originalInnerRectangle?: ILayer;
	isLoadingSmartImageStep: boolean = false;
	containerRef = useRef<null | HTMLDivElement>(null);
	activeObjectSettings = {
		colorNumber: undefined,
		fontType: undefined,
		logoType: undefined,
		horizontalAlignment: undefined,
		verticalAlignment: undefined,
	};

	constructor(rootStore: IRootStore) {
		makeAutoObservable(this);
		this.initializeEditorStepperSteps();
		this.rootStore = rootStore;
	}

	initializeEditorStepperSteps = () => {
		this.editorStepperSteps = PopUpSteps.map((id) => {
			const { name, stepperTitle, tabName } = this.getItemPropertiesById(id);
			return { id: name, title: stepperTitle, tabName: tabName };
		});
	};

	setProperty = async <K extends keyof DesignEditorStore>(key: K, value: DesignEditorStore[K]) => {
		runInAction(() => ((this as DesignEditorStore)[key] = value));
	};

	getItemPropertiesById = (id: string) => {
		const item = BASE_ITEMS.find((item) => item.id === id);
		return {
			name: item?.name || "",
			stepperTitle: item?.stepperTitle || "",
			tabName: item?.tabName || PanelType.TEMPLATES,
		};
	};

	resetCampaignPopup = () => {
		runInAction(() => {
			this.setProperty("activeStep", 0);
			this.setProperty("campaignName", "");
			this.setProperty("isTemplateEmptyState", true);
			this.setProperty("hideCampaignResultContent", true);
			this.setProperty("isGenerateAdsLoading", false);
			this.setProperty("numberOfProcessedAdvertisements", 1);
		});
	};
}
