import { Box } from "@mui/material";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useEffect, useRef, useState } from "react";
import BriaInput from "../BriaInput/BriaInput";
import SolidGradientColorPicker from "../SolidGradientColorPicker/SolidGradientColorPicker";
import styles from "./BriaColorPicker.module.scss";

export type IProps = {
	color: string;
	onChange: (color: string) => void;
	showInputField?: boolean;
	className?: string;
};

const BriaColorPicker = ({ color, onChange, showInputField, className }: IProps) => {
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const colorPickerRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (colorPickerRef.current && !colorPickerRef.current.contains(event.target as Node)) {
				setIsOpen(false);
			}
		};

		if (isOpen) {
			document.addEventListener("mousedown", handleClickOutside);
		} else {
			document.removeEventListener("mousedown", handleClickOutside);
		}

		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [isOpen, setIsOpen]);

	const rgbaToHex = (rgba: string) => {
		const rgb = rgba.match(/\d+/g);
		return rgb
			? `#${((1 << 24) + (parseInt(rgb[0]) << 16) + (parseInt(rgb[1]) << 8) + parseInt(rgb[2]))
					.toString(16)
					.slice(1)
					.toUpperCase()}`
			: "";
	};

	return (
		<Box className={clsx(styles.colorPickerContainer, className)} ref={colorPickerRef}>
			{isOpen && (
				<Box className={styles.colorPicker}>
					<SolidGradientColorPicker
						hideColorTypeBtns
						hideOpacity
						color={color}
						onChange={(color) => onChange(rgbaToHex(color))}
					/>
				</Box>
			)}
			<Box
				className={styles.colorPreview}
				sx={{ backgroundColor: color }}
				onClick={() => {
					setIsOpen(!isOpen);
				}}
			/>
			{showInputField && (
				<BriaInput
					className={styles.colorInputField}
					height="40px"
					value={color}
					onChange={(e) => onChange(e.target.value)}
				/>
			)}
		</Box>
	);
};

export default observer(BriaColorPicker);
