import { Box, Grid, Link as MuiLink, Paper, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import styles from "../../pages/API/BriaAPIs/BriaAPIs.module.scss";

export interface IAPICardsProps {
	type: string;
	title: string;
	description: string;
	link?: any;
	image?: string;
	badges?: string[];
	demoLink?: string;
}

export const APICardComponent = ({ title, description, link, image, badges, demoLink }: IAPICardsProps) => {
	const { t } = useTranslation("translation", { keyPrefix: "apis_page" });
	return (
		<Grid item xs={12} sm={12} md={12} lg={6}>
			<Paper elevation={0} className={styles.apiCard}>
				<Box className={styles.card}>
					<Box>
						<Box className={styles.imgContainter}>
							<img className={styles.image} src={image} alt="" />
						</Box>
					</Box>
					<Box className={styles.textAndButtonContainer}>
						<Typography className={styles.title}>{t(title)} </Typography>
						<Typography className={styles.description}>{t(description)}</Typography>
						<Box className={styles.apiDocButton}>
							<MuiLink className={styles.apiDocLink} component={RouterLink} to={link} target={"_blank"}>
								{t("apiDocumentation")}
							</MuiLink>

							{demoLink && <MuiLink className={styles.apiDemoLink} component={RouterLink} to={demoLink}>
								{t("apiDemo")}
							</MuiLink>
							}
							<Box className={styles.badges} >
								{badges?.map((badge: string, index: number) =>
									(
										<Box
											key={index}
											className={styles.badge}
										>
											{t(badge)}
										</Box>
									)
								)}
							</Box>
						</Box>
					</Box>
				</Box>
			</Paper>
		</Grid>
	);
};
