import { Box, CircularProgress, Menu, MenuItem } from "@mui/material";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import React, { ReactNode, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { LazyLoadImage, LazyLoadImageProps } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { IconButton } from "rsuite";
import FullscreenIcon from "../../../assets/images/icons/Image/FullscreenIcon";
import downloadIcon from "../../../assets/images/svgs/downloadIcon.svg";
import { APPS } from "../../../constants/AppsConstants.ts";
import { HubgalleryImage } from "../../../constants/HubGalleryImagesConstants.ts";
import { useAppStore } from "../../../hooks/useStores.tsx";
import { PlaygroundImage } from "../../../models/image-to-image.ts";
import { TextToImageConfigType } from "../../../models/text-to-image.ts";
import useImageUtils, { AsyncProps } from "../../../utils/useImageUtils";
import BriaButton from "../BriaButton/BriaButton.tsx";
import BriaTooltip from "../BriaTooltip/BriaTooltip";
import { AspectRatio } from "../DropDowns/StaticDropdown";
import FullScreenViewer from "../FullScreenViewer/FullScreenViewer";
import { FullScreenImageType } from "../FullScreenViewer/Image/FullScreenImage";
import ImageError from "../ImageError/ImageError";
import "../Selectable/Selected.scss";
import { SkeletonImage } from "../Skeleton/SkeletonGallery";
import styles from "./BriaImage.module.scss";

export type ImageErrorType = "offensive" | "unavailable";

export type BriaImageProps = {
	image: (BriaImageProps & PlaygroundImage) | FullScreenImageType | HubgalleryImage;
	url: string;
	handleClick?: (e?: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
	handleFullScreen?: () => void;
	onZoom?: (zoomed: boolean) => void;
	hideFullScreenButton?: boolean;
	hideDownloadIcon?: boolean;
	aspectRatio?: AspectRatio;
	ImageOverlay?: ReactNode;
	displayOverlay?: "on" | "off" | "hover" | "customHover" | "fullScreenHover";
	loading?: boolean;
	errorType?: ImageErrorType;
	selectable?: boolean;
	selected?: boolean;
	className?: string;
	htmlJsonData?: any;
	async?: boolean;
	zoomable?: boolean;
	onSuccessPulling?: () => Promise<void>;
	onErrorPulling?: (errorType: ImageErrorType) => Promise<void>;
	lazyImageProps?: LazyLoadImageProps;
	fullScreenProps?: Partial<FullScreenImageType>;
	asyncProps?: AsyncProps;
	zoomProps?: ZoomProps;
	onDownload?: () => void;
	onDownloadPsd?: () => void;
	imageErrorCallBack?: (visual_hash?: string) => void;
	visualHash?: string;
	downloadProps?: { fileName: string };
	isProductPlacement?: boolean;
	disabled?: boolean;
};

const BriaImage = ({
	image,
	url,
	onDownload,
	onDownloadPsd,
	handleClick,
	handleFullScreen,
	hideFullScreenButton = false,
	hideDownloadIcon = true,
	aspectRatio = "auto" as AspectRatio,
	ImageOverlay,
	displayOverlay = "on",
	loading,
	errorType,
	selectable = false,
	selected,
	className,
	htmlJsonData,
	async,
	zoomable,
	onZoom,
	onSuccessPulling,
	onErrorPulling,
	lazyImageProps,
	fullScreenProps,
	asyncProps,
	zoomProps = { scale: 6 },
	downloadProps,
	imageErrorCallBack,
	visualHash,
	isProductPlacement = false,
	disabled = false,
}: BriaImageProps) => {
	const { t } = useTranslation("translation", { keyPrefix: "briaImage" });
	const abortControllerRef = useRef(new window.AbortController());
	const { isBlob, getBlob, pullUntilAvailable, downloadImage, uploadImageAndExportPsd } = useImageUtils();
	const { playgroundStore } = useAppStore();
	const [imageUrl, setImageUrl] = useState(url);
	const [error, setError] = useState(errorType);
	const [localLoading, setLocalLoading] = useState(loading);
	const [isLazyLoaded, setIsLazyLoaded] = useState(false);
	const [isViewerOpen, setIsViewerOpen] = useState(false);
	const [zoomed, setZoomed] = useState(false);
	const [offsetX, setOffsetX] = useState(0);
	const [offsetY, setOffsetY] = useState(0);
	const imageRef = useRef<HTMLImageElement>(null);
	const [isPsdDownloading, setIsPsdDownloading] = useState(false);
	const [isDropDownOpen, setIsDropdownOpen] = useState<boolean>(false);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const isHoverDisplayOverlay = displayOverlay === "customHover" && !error && !isDropDownOpen;

	useEffect(() => {
		const fetchImage = async () => {
			if (async && url && url.length > 0 && !isBlob(url)) {
				try {
					setLocalLoading(true);
					await pullUntilAvailable(url, abortControllerRef.current, asyncProps);
					const imageBlob = await getBlob(url);
					const fileSize = imageBlob.size;
					if (fileSize !== undefined && fileSize === 0) {
						setError("offensive");
						onErrorPulling?.("offensive");
					} else {
						setImageUrl(url);
						onSuccessPulling?.();
					}
				} catch (e) {
					setError("unavailable");
					onErrorPulling?.("unavailable");
				} finally {
					setLocalLoading(false);
				}
			} else {
				setImageUrl(url);
			}
		};

		fetchImage();
	}, [url]);

	const onFullScreen = (e: React.MouseEvent<SVGSVGElement>) => {
		e.stopPropagation();
		handleFullScreen ? handleFullScreen() : setIsViewerOpen(true);
	};

	const handleImageLoad = () => {
		setIsLazyLoaded(true);
		lazyImageProps?.onLoad?.();
	};

	const handleImageClick = (event: React.MouseEvent<HTMLImageElement>) => {
		event.preventDefault();
		event.stopPropagation();
		if (zoomable) {
			if (zoomed) {
				onZoom && onZoom(false);
				setZoomed(false);
				setOffsetX(0);
				setOffsetY(0);
			} else {
				onZoom && onZoom(true);
				const rect = imageRef.current?.getBoundingClientRect();
				const x = event.clientX - (rect?.left || 0);
				const y = event.clientY - (rect?.top || 0);
				setOffsetX(x);
				setOffsetY(y);
				setZoomed(true);
			}
		}

		handleClick?.();
	};
	const handleZoomedScroll = (event: React.WheelEvent<HTMLImageElement>) => {
		if (zoomed) {
			const { width = 0, height = 0 } = imageRef.current?.getBoundingClientRect() || {};
			setOffsetX((prevOffsetX) => Math.max(0, Math.min(prevOffsetX + event.deltaX, width)));
			setOffsetY((prevOffsetY) => Math.max(0, Math.min(prevOffsetY + event.deltaY, height)));
		}
	};

	const handleClickDownloadMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation();
		setAnchorEl(event.currentTarget);
		setIsDropdownOpen(true);
	};

	const handleCloseDownloadMenu = () => {
		setAnchorEl(null);
		setIsDropdownOpen(false);
	};

	const handleDownloadImageAsPsd = async (event: React.MouseEvent<HTMLElement>) => {
		event.stopPropagation();
		setIsPsdDownloading(true);
		try {
			onDownloadPsd && onDownloadPsd();
			await uploadImageAndExportPsd(imageUrl);
			setIsPsdDownloading(false);
		} catch {
			console.error("Failed to upload image as PSD");
			setIsPsdDownloading(false);
		}
	};

	const onImageError = (visual_hash?: string) => {
		if (imageErrorCallBack) {
			imageErrorCallBack(visual_hash);
		}
	};

	const downloadButton = () => {
		if ((image as BriaImageProps & PlaygroundImage)?.type === APPS.PRODUCT_PLACEMENT) {
			return (
				<>
					<IconButton
						aria-label={t("downloadOptions")}
						aria-controls="download-menu"
						aria-haspopup="true"
						onClick={handleClickDownloadMenu}
						className={clsx(styles.downloadIcon, {
							["iconHover"]: !anchorEl,
							[styles.onlyIcon]: true,
						})}
					>
						<img src={downloadIcon} />
					</IconButton>
					<Menu
						id="download-menu"
						anchorEl={anchorEl}
						keepMounted
						open={open}
						onClose={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
							e?.stopPropagation();
							handleCloseDownloadMenu();
						}}
						PopoverClasses={{
							paper: styles.downloadMenu,
						}}
					>
						<MenuItem
							onClick={(e) => {
								e.stopPropagation();
								const playgroundImage = image as PlaygroundImage;
								imageUrl && downloadImage(imageUrl, playgroundStore.getFileName(playgroundImage));
								onDownload && onDownload();
							}}
							classes={{
								root: styles.menuItemRoot,
							}}
						>
							{t("fullScreenView.overlay.download")}
						</MenuItem>
						<MenuItem
							onClick={handleDownloadImageAsPsd}
							classes={{
								root: styles.menuItemRoot,
							}}
							disabled={isPsdDownloading}
						>
							{isPsdDownloading ? (
								<CircularProgress color="inherit" size={14} />
							) : (
								t("fullScreenView.overlay.downloadAsPsd")
							)}
						</MenuItem>
					</Menu>
				</>
			);
		} else {
			return (
				<BriaTooltip title={t("fullScreenView.overlay.download")}>
					<BriaButton
						buttonType="tertiaryMedium"
						className={clsx(styles.downloadIcon, {
							["iconHover"]: true,
						})}
						onClick={() => {
							if (downloadProps) {
								downloadImage(imageUrl, downloadProps?.fileName ?? t("fullScreenView.overlay.image"));
							} else {
								const playgroundImage = image as PlaygroundImage;
								playgroundImage.url &&
									downloadImage(playgroundImage.url, playgroundStore.getFileName(playgroundImage));
							}

							onDownload && onDownload();
						}}
					>
						{(
							((image as BriaImageProps & PlaygroundImage)?.config as TextToImageConfigType)?.format ??
							"png"
						)?.toUpperCase()}
						<img src={downloadIcon} />
					</BriaButton>
				</BriaTooltip>
			);
		}
	};

	return (
		<>
			{localLoading || loading ? (
				<SkeletonImage aspectRatio={aspectRatio} />
			) : (
				<>
					<Box
						ref={imageRef}
						className={clsx(
							styles.imageWrapper,
							"imageWrapper",
							imageUrl && !error && selectable && "selectableImage",
							selectable && selected && "selected",
							{
								[styles.zoomable]: zoomable,
								[styles.zoomed]: zoomed,
								[styles.isProductPlacement]: isProductPlacement,
								[styles.disabled]: disabled,
							},
						)}
						onClick={handleImageClick}
						onWheel={handleZoomedScroll}
						component="span"
						sx={{
							aspectRatio: aspectRatio.replace(":", "/"),
							opacity: 1,
						}}
						aria-valuetext={JSON.stringify(htmlJsonData)}
					>
						<Box
							className={clsx(styles.overLayer, {
								[styles.displayHover]: isHoverDisplayOverlay,
							})}
						>
							<>
								{!error ? (
									<>
										<Box className={styles.iconContainer}>
											{!hideDownloadIcon && downloadButton()}
											{!hideFullScreenButton && (
												<BriaTooltip title={t("fullScreenView.tooltip")}>
													<FullscreenIcon
														className={clsx(styles.fullScreenIcon, {
															["iconHover"]:
																isHoverDisplayOverlay ||
																displayOverlay === "fullScreenHover",
														})}
														onClick={onFullScreen}
													/>
												</BriaTooltip>
											)}
										</Box>
										{displayOverlay !== "off" &&
											displayOverlay !== "fullScreenHover" &&
											ImageOverlay}
									</>
								) : (
									<ImageError
										imageWidth={`100%`}
										imageHeight={`100%`}
										titleText={t(`errors.${error}.title`)}
										massageText={t(`errors.${error}.message`)}
										titleFontSize="14px"
										massageTextSize="14px"
										largeSvg
									/>
								)}
							</>
						</Box>
						<LazyLoadImage
							className={`${className} ${styles.image} ${isLazyLoaded ? styles.loaded : styles.loading} ${
								error && styles.error
							}`}
							key={imageUrl}
							alt={imageUrl}
							placeholderSrc={t("lazyLoadPlaceholder")}
							effect="blur"
							src={imageUrl}
							threshold={100}
							width="100%"
							onLoad={handleImageLoad}
							style={{
								aspectRatio: aspectRatio.replace(":", "/"),
								opacity: 1,
								transformOrigin: `${offsetX}px ${offsetY}px`,
								transform: zoomed ? `scale(${zoomProps.scale})` : "",
							}}
							wrapperProps={{
								style: {
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								},
							}}
							loading="lazy"
							{...lazyImageProps}
							onError={() => onImageError(visualHash)}
						/>
					</Box>
					{isViewerOpen && (
						<FullScreenViewer
							open={isViewerOpen}
							images={[{ src: imageUrl, ...fullScreenProps }]}
							onClose={() => setIsViewerOpen(false)}
							onDownload={onDownload}
						/>
					)}
				</>
			)}
		</>
	);
};

const ObservedComponent = observer(BriaImage);
export default ObservedComponent;

export type ImageOverlayComponent = React.ComponentType<any>;

export type ZoomProps = {
	scale: number;
};
