export default class RouterConstants {
	public static readonly CONSOLE = {
		path: "/console",
		fullPath: "/console",
	};

	public static readonly ACCOUNT = {
		path: "account",
		fullPath: `${this.CONSOLE.path}/account`,
		requiresOrg: true,
	};

	public static readonly FORGOT_PASSWORD = {
		path: "/forgot-password",
	};

	public static readonly SIGNUP = {
		path: "/register",
	};

	public static readonly LOGIN = {
		path: "/login",
	};

	public static readonly USER_INFO = {
		path: "/user-info",
	};

	public static readonly PASSWORD_RESET = {
		path: "/auth/",
	};

	public static readonly MODELS = {
		path: "models",
		fullPath: `${this.CONSOLE.path}/models`,
		requiresOrg: true,
	};

	public static readonly IFRAME = {
		path: "iframe",
		fullPath: `${this.CONSOLE.path}/iframe`,
		requiresOrg: true,
	};
	public static readonly NEW_IFRAMES = {
		path: "new-iframes",
		fullPath: `${this.CONSOLE.path}/new-iframes`,
		requiresOrg: true,
	};
	public static readonly NEW_IFRAMES_EDITOR = {
		path: `new-iframes`,
		fullPath: `${this.NEW_IFRAMES.fullPath}/:id`,
		editPath: `${this.NEW_IFRAMES.fullPath}`,
		createPath: `${this.NEW_IFRAMES.fullPath}/new`,
		requiresOrg: true,
		private: true,
	};
	public static readonly TAILORED_GENERATION = {
		path: "tailored-generation",
		fullPath: `${this.CONSOLE.path}/tailored-generation`,
		requiresOrg: true,
	};
	public static readonly TG_MODELS = {
		path: "models",
		fullPath: `${this.TAILORED_GENERATION.fullPath}/models`,
		requiresOrg: true,
	};
	public static readonly TG_DATASET = {
		path: "datasets",
		fullPath: `${this.TAILORED_GENERATION.fullPath}/datasets`,
		requiresOrg: true,
	};
	public static readonly DATASET = {
		path: "dataset",
		fullPath: `${this.TAILORED_GENERATION.fullPath}/dataset/:datasetId`,
		editPath: `${this.TAILORED_GENERATION.fullPath}/dataset`,
		requiresOrg: true,
		private: true,
	};
	public static readonly TG_PLAYGROUND = {
		path: "playground",
		fullPath: `${this.TAILORED_GENERATION.fullPath}/playground/:modelId`,
		editPath: `${this.TAILORED_GENERATION.fullPath}/playground`,
		requiresOrg: true,
		private: true,
	};
	public static readonly TG_CREATE_MODEL = {
		path: "create-model",
		fullPath: `${this.TAILORED_GENERATION.fullPath}/create-model`,
		requiresOrg: true,
	};
	public static readonly TG_CREATE_DATASET = {
		path: "create-dataset",
		fullPath: `${this.TAILORED_GENERATION.fullPath}/create-dataset`,
		requiresOrg: true,
	};

	public static readonly CREATE_ORGANIZATION = {
		path: "/create-organization",
	};
	public static readonly SELECT_ORGANIZATION = {
		path: "/select-organization",
	};
	public static readonly NEW_IFRAMES_CONFIG = {
		path: "config",
		fullPath: `${this.NEW_IFRAMES.fullPath}/config`,
		requiresOrg: true,
	};
	public static readonly IFRAME_CONFIG = {
		path: "config",
		fullPath: `${this.IFRAME.fullPath}/config`,
		requiresOrg: true,
	};
	public static readonly IFRAME_EDITOR = {
		path: "iframe-editor",
		fullPath: `${this.CONSOLE.path}/iframe-editor`,
		requiresOrg: true,
	};
	public static readonly AUTH_KEYS = {
		path: "api-keys",
		fullPath: `${this.ACCOUNT.fullPath}/api-keys`,
		requiresOrg: true,
	};
	public static readonly USAGE_BILLING = {
		path: "usage",
		fullPath: `${this.ACCOUNT.fullPath}/usage`,
		requiresOrg: true,
	};
	public static readonly CAMPAIGN = {
		path: "campaign",
		fullPath: `${this.CONSOLE.path}/campaign`,
		requiresOrg: true,
	};
	public static readonly TEMPLATES = {
		path: "templates",
		fullPath: `${this.CAMPAIGN.fullPath}/templates`,
		requiresOrg: true,
	};
	public static readonly TEMPLATE_EDITOR = {
		path: `campaign/templates`,
		fullPath: `${this.TEMPLATES.fullPath}/:id`,
		editPath: `${this.TEMPLATES.fullPath}`,
		requiresOrg: true,
		private: true,
	};
	public static readonly NEW_TEMPLATE_EDITOR = {
		path: `campaign/templates/new`,
		fullPath: `${this.TEMPLATES.fullPath}/new`,
		requiresOrg: true,
	};
	public static readonly LAYOUT_EDITOR = {
		path: "campaign/layouts",
		fullPath: `${this.CAMPAIGN.fullPath}/layouts/:id`,
		editPath: `${this.CAMPAIGN.fullPath}/layouts`,
		requiresOrg: true,
		private: true,
	};
	public static readonly NEW_LAYOUT_EDITOR = {
		path: "campaign/layouts/new",
		fullPath: `${this.CAMPAIGN.fullPath}/layouts/new`,
		requiresOrg: true,
	};
	public static readonly BRANDS = {
		path: "brands",
		fullPath: `${this.CAMPAIGN.fullPath}/brands`,
		requiresOrg: true,
	};
	public static readonly BRAND_EDITOR = {
		path: `campaign/brands`,
		fullPath: `${this.BRANDS.fullPath}/:id`,
		editPath: `${this.BRANDS.fullPath}`,
		requiresOrg: true,
		private: true,
	};

	public static readonly NEW_BRAND_EDITOR = {
		path: `campaign/brands/new`,
		fullPath: `${this.BRANDS.fullPath}/new`,
		requiresOrg: true,
	};
	public static readonly FONTS = {
		path: "fonts",
		fullPath: `${this.CAMPAIGN.fullPath}/fonts`,
		requiresOrg: true,
	};
	public static readonly PLACEMENTS = {
		path: "placements",
		requiresOrg: true,
	};
	public static readonly USERS = {
		path: "users",
		fullPath: `${this.ACCOUNT.fullPath}/users`,
		requiresOrg: true,
	};
	public static readonly SUBSCRIPTION = {
		path: "subscription",
		fullPath: `${this.ACCOUNT.fullPath}/subscription`,
		requiresOrg: true,
	};
	public static readonly API = {
		path: "api",
		fullPath: `${this.CONSOLE.fullPath}/api`,
	};
	public static readonly BASE_PLATFORM_APIS = {
		path: "base-platform",
		fullPath: `${this.API.fullPath}/base-platform`,
	};
	public static readonly ECOMMERCE_SUITE_APIS = {
		path: "ecommerce-suite",
		fullPath: `${this.API.fullPath}/ecommerce-suite`,
	};
	public static readonly BRANDED_GEN_APIS = {
		path: "branded-generation",
		fullPath: `${this.API.fullPath}/branded-generation`,
	};
	public static readonly PRODUCT_CUTOUT_APP = {
		path: "product-cutout",
		fullPath: `${this.API.fullPath}/product-cutout`,
	};
	public static readonly ERASER_APP = {
		path: "eraser",
		fullPath: `${this.API.fullPath}/eraser`,
	};
	public static readonly PRODUCT_PACKSHOT_APP = {
		path: "product-packshot",
		fullPath: `${this.API.fullPath}/product-packshot`,
	};
	public static readonly PRODUCT_SHADOW_APP = {
		path: "product-shadow",
		fullPath: `${this.API.fullPath}/product-shadow`,
	};
	public static readonly LIFESTYLE_PRODUCT_SHOT_BY_TEXT_APP = {
		path: "lifestyle-product-shot-by-text",
		fullPath: `${this.API.fullPath}/lifestyle-product-shot-by-text`,
	};
	public static readonly REMOVE_BACKGROUND_APP = {
		path: "remove-background",
		fullPath: `${this.API.fullPath}/remove-background`,
	};
	public static readonly BLUR_BACKGROUND_APP = {
		path: "blur-background",
		fullPath: `${this.API.fullPath}/blur-background`,
	};
	public static readonly GENERATE_BACKGROUND_APP = {
		path: "generate-background",
		fullPath: `${this.API.fullPath}/generate-background`,
	};
	public static readonly EXPAND_IMAGE_APP = {
		path: "expand-image",
		fullPath: `${this.API.fullPath}/expand-image`,
	};
	public static readonly INCREASE_RESOLUTION_APP = {
		path: "increase-resolution",
		fullPath: `${this.API.fullPath}/increase-resolution`,
	};
	public static readonly DELAYER_IMAGE_APP = {
		path: "delayer-image",
		fullPath: `${this.API.fullPath}/delayer-image`,
	};
	public static readonly CROP_OUT_FOREGROUND_APP = {
		path: "crop-out-foreground",
		fullPath: `${this.API.fullPath}/crop-out-foreground`,
	};
	public static readonly GET_MASKS_APP = {
		path: "get-masks",
		fullPath: `${this.API.fullPath}/get-masks`,
	};
	public static readonly FOUNDATION_MODELS = {
		path: "foundation-models",
		fullPath: `${this.MODELS.fullPath}/foundation-models`,
	};
	public static readonly AUXILIARY_MODELS = {
		path: "auxiliary-models",
		fullPath: `${this.MODELS.fullPath}/auxiliary-models`,
	};
	public static readonly ACCESS_MODELS = {
		path: "access-models",
		fullPath: `${this.MODELS.fullPath}/access-models`,
	};
	public static readonly PAGE_NOT_FOUND = {
		path: "page-not-found",
		fullPath: "page-not-found",
	};
	public static readonly APPS = {
		path: "apps",
	};
	public static readonly FOX_APPS = {
		path: "fox-apps",
	};
	public static readonly TEXT_TO_IMAGE = {
		path: "text-to-image",
		fullPath: `${this.APPS.path}/text-to-image`,
	};
	public static readonly TEXT_TO_VECTOR = {
		path: "text-to-vector",
		fullPath: `${this.APPS.path}/text-to-vector`,
	};
	public static readonly IMAGE_TO_IMAGE = {
		path: "image-to-image",
		fullPath: `${this.APPS.path}/image-to-image`,
	};
	public static readonly AI_EDITOR = {
		path: "ai-editor",
		fullPath: `${this.APPS.path}/ai-editor`,
	};
	public static readonly PRODUCT_PLACEMENT = {
		path: "product-placement/generator",
		fullPath: `${this.APPS.path}/product-placement/generator`,
	};
	public static readonly PRODUCT_PLACEMENT_GALLERY = {
		path: "product-placement",
		fullPath: `${this.APPS.path}/product-placement`,
	};
	public static readonly DESIGN_EDITOR = {
		path: "campaign-editor",
		fullPath: `/${this.APPS.path}/campaign-editor`,
	};
}
