import { onAuthStateChanged } from "firebase/auth";
import { makeAutoObservable, runInAction } from "mobx";
import { BriaAxios } from "../../config/axios";
import { firebaseAuth } from "../../config/firebase.ts";
import { ORG_BLOCK_REASONS, ORG_SUBSCRIPTION_PLANS } from "../../constants/billing.ts";
import { AESCipher } from "../../helpers/encryption.ts";
import { getUserWithUID } from "../../helpers/firebase.ts";
import {
	clearSelectedOrganization,
	getSelectedOrganization,
	setSelectedOrganization,
} from "../../helpers/localStorage.ts";
import { IRootStore } from "../../mobx/root-store.tsx";
import { OrgFeatures, OrgSubscription, OrgUsage } from "../../models/billing.ts";
import { UserOrganization } from "../../models/organization.ts";
import User from "../../models/user.ts";
import BillingService from "../../services/BillingService.ts";
import iframeStore from "../IframeNew/iframe-store.tsx";

export interface IAuthStore {
	user: User | null;
	isLoadingUserOrganizations: boolean;
	userOrganizations: UserOrganization[];
	isLoggedIn: boolean;
	isLoading: boolean;
	isLoadingOrgSubscriptions: boolean;
	isLoadingOrgUsage: boolean;
	isOrgBlocked: boolean;
	isPremiumOrgSubscription: boolean;
	isError: boolean;
	orgSubscription: OrgSubscription | null;
	orgUsage: OrgUsage | null;
	registrationSource?: string | null;

	logout(): Promise<void>;

	setUser(user: User | null): Promise<void>;

	grantUserPermission(orgId: string): Promise<void>;

	createOrganization(org_name: string): Promise<any>;

	searchOrganizations(query: string, signal?: AbortSignal): Promise<UserOrganization[]>;

	loadUserData(): Promise<void>;

	loadOrgSubscription(): Promise<void>;

	isFeatureEnabled(feature: OrgFeatures): boolean;

	loadOrgUsage(): Promise<void>;

	loadOrganization(orgId: string): Promise<UserOrganization>;

	setPostRegistrationConfigs(): Promise<void>;
}

export default class AuthStore implements IAuthStore {
	rootStore: IRootStore;
	user: User | null = null;
	userOrganizations: UserOrganization[] = [];
	isLoggedIn = false;
	isError = false;
	isLoading = true;
	isLoadingOrgSubscriptions = false;
	isLoadingUserOrganizations = false;
	isOrgBlocked = false;
	isPremiumOrgSubscription = false;
	isLoadingOrgUsage = false;
	orgSubscription: OrgSubscription | null = null;
	orgUsage: OrgUsage | null = null;
	billingService = new BillingService();
	registrationSource = undefined;

	constructor(rootStore: IRootStore) {
		makeAutoObservable(this);
		this.rootStore = rootStore;
		onAuthStateChanged(firebaseAuth, (user) => {
			if (user) {
				this.isLoading = true;
				this.isError = false;

				getUserWithUID(user.uid)
					.then((userObject) => {
						if (userObject) {
							this.setUser(userObject);
						} else {
							this.setUser(null);
						}
					})
					.catch((error) => {
						console.log(error);
					});
			} else {
				this.setUser(null);
			}
		});
	}

	logout = async (): Promise<void> => {
		try {
			this.orgSubscription = null;
			this.orgUsage = null;
			await firebaseAuth.signOut();
			this.isLoggedIn = false;
			localStorage.clear();
		} catch (e) {
			runInAction(() => {
				this.isLoading = false;
				this.isError = true;
			});
			return Promise.reject(e);
		}
	};

	setUser = async (user: User | null): Promise<void> => {
		try {
			this.user = user;
			if (user != null) {
				this.rootStore.analyticsStore.setUser(user, this.registrationSource);
				await this.loadUserData();
			}
			this.isLoggedIn = user !== null;
			this.isLoading = false;
			this.isError = false;
		} catch (e) {
			runInAction(() => {
				this.isLoading = false;
				this.isError = true;
			});
			return Promise.reject(e);
		}
	};

	createOrganization = async (org_name: string): Promise<any> => {
		this.isLoading = false;
		this.isError = false;
		const data = {
			name: org_name,
		};
		try {
			const response = await (await BriaAxios()).post(`/create_organization/`, data);
			runInAction(() => {
				this.isLoading = false;
				this.isError = false;
			});
			if (this.user) {
				getUserWithUID(this.user.uid).then((userObject) => {
					if (userObject) {
						this.setUser(userObject);
					} else {
						this.setUser(null);
					}
				});
			}
			return response.data;
		} catch (e) {
			runInAction(() => {
				this.isLoading = false;
				this.isError = true;
			});
			return Promise.reject(e);
		}
	};

	loadOrganization = async (orgId: string): Promise<UserOrganization> => {
		try {
			const response = await (await BriaAxios()).get(`/organization/${orgId}`);
			return response.data as UserOrganization;
		} catch (e) {
			return Promise.reject(e);
		}
	};

	loadOrganizations = async (): Promise<any> => {
		this.isLoadingUserOrganizations = true;
		try {
			let response: any;
			if (this.user?.isSuperAdmin()) {
				response = await this.searchOrganizations("bria");
			} else {
				response = await (await BriaAxios()).get("/organizations/");
				response = response.data;
			}

			runInAction(() => {
				this.userOrganizations = response;

				const selectedOrg = getSelectedOrganization();
				if (this.userOrganizations.length > 0) {
					if (!selectedOrg) {
						if (this.userOrganizations.length === 1 || this.user?.isSuperAdmin()) {
							setSelectedOrganization(this.userOrganizations[0]);
						}
					} else {
						const foundOrg = this.userOrganizations.find(
							(org) => org.organization.uid === selectedOrg.organization.uid,
						);
						if (foundOrg) {
							setSelectedOrganization(foundOrg);
						} else {
							if (this.user?.isSuperAdmin()) {
								this.userOrganizations = [selectedOrg, ...this.userOrganizations];
							} else {
								setSelectedOrganization(this.userOrganizations[0]);
							}
						}
					}
				} else {
					clearSelectedOrganization();
				}
			});
		} catch (e) {
			this.isLoading = false;
			this.isError = true;
			return Promise.reject(e);
		} finally {
			this.isLoadingUserOrganizations = false;
		}
	};

	loadUserData = async () => {
		if (!iframeStore.isIframe()) {
			await this.loadOrganizations();
			if (getSelectedOrganization() && this.userOrganizations.length > 0) {
				this.loadOrgSubscription();
				this.loadOrgUsage();
			}
		}
	};

	searchOrganizations = async (query: string, signal?: AbortSignal) => {
		const res = await (
			await BriaAxios()
		).get("/search_organizations/", {
			params: {
				query,
			},
			signal,
		});
		return res.data;
	};

	setPostRegistrationConfigs = async (): Promise<void> => {
		try {
			await (await BriaAxios()).post("/users/set_post_registration_configs", {});
		} catch (e) {
			return Promise.reject(e);
		}
	};

	isFeatureEnabled = (feature: OrgFeatures) => {
		// you should use this only if the isLoadingOrgSubscriptions is false, otherwise, add a loader in the frontend based on isLoadingOrgSubscriptions
		return this.orgSubscription?.features?.includes(feature) ?? false;
	};

	loadOrgSubscription = async (): Promise<void> => {
		runInAction(() => {
			this.isError = false;
			this.isLoadingOrgSubscriptions = true;
		});
		try {
			const response = await this.billingService.loadOrgSubscription();
			runInAction(() => {
				this.orgSubscription = response;
				this.isError = false;

				const plan = this.orgSubscription?.plan_name ?? ORG_SUBSCRIPTION_PLANS.free.name;
				this.isPremiumOrgSubscription =
					plan !== ORG_SUBSCRIPTION_PLANS.free.name && plan !== ORG_SUBSCRIPTION_PLANS.starter.name;
				this.isOrgBlocked =
					(this.orgSubscription?.is_blocked ?? false) &&
					this.orgSubscription?.block_reason !== ORG_BLOCK_REASONS.PASSED_FREE_LIMITS;
				this.isLoadingOrgSubscriptions = false;
			});
		} catch (e) {
			runInAction(() => {
				this.isLoadingOrgSubscriptions = false;
				this.isError = true;
			});
			return Promise.reject(e);
		}
	};

	loadOrgUsage = async (): Promise<void> => {
		runInAction(() => {
			this.isError = false;
			this.isLoadingOrgUsage = true;
		});
		try {
			const response = await this.billingService.loadOrgUsage();
			runInAction(() => {
				this.orgUsage = response;
				this.isError = false;
				this.isLoadingOrgUsage = false;
			});
		} catch (e) {
			runInAction(() => {
				this.isLoadingOrgUsage = false;
				this.isError = true;
			});
			return Promise.reject(e);
		}
	};

	grantUserPermission = async (orgId: string): Promise<void> => {
		const aesCipher = new AESCipher();
		const encryptedData = await aesCipher.encrypt(orgId);
		await (await BriaAxios()).post(`/grant_permission/`, { enc: encryptedData });
	};
}
