import { useEffect, useState } from "react";
import { useAppStore } from "./useStores";

export const useImageToImageConfig = () => {
	const { imageToImageStore, playgroundStore } = useAppStore();
	const countSelected = playgroundStore.getSelectedImages().length;
	const [allSelectedImagesAvailable, setAllSelectedImagesAvailable] = useState(true);

	const checkImagesAvailability = async () => {
		const selectedImages = playgroundStore.getSelectedImages();

		if (!selectedImages.length) {
			setAllSelectedImagesAvailable(true);
			return;
		}

		let allAvailable = true;
		for (const image of selectedImages) {
			try {
				const res = await fetch(image.url);
				if (res.status !== 200) {
					allAvailable = false;
					break;
				}
			} catch (error) {
				allAvailable = false;
				break;
			}
		}
		setAllSelectedImagesAvailable(allAvailable);
	};

	useEffect(() => {
		checkImagesAvailability();
	}, [
		playgroundStore
			.getSelectedImages()
			.map((image) => image.url)
			.join(","),
	]);

	const canStyleBackground = (): boolean => {
		return !!(
			imageToImageStore.config.style &&
			imageToImageStore.config.style.tailored_style &&
			imageToImageStore.config.style.sub_style
		);
	};

	const canRemoveBackground = (): boolean => {
		return !!imageToImageStore.config.background?.remove;
	};

	const canResizeImage = (): boolean => {
		const { size } = imageToImageStore.config;
		return !!(
			(size?.aspect_ratio?.selected && size?.aspect_ratio?.value?.length) ||
			size?.crop ||
			(size?.custom_size?.selected && size?.custom_size?.width && size?.custom_size?.height) ||
			(size?.medium_destination?.destinations?.length && size?.medium_destination?.selected)
		);
	};

	const canExpandBackground = (): boolean => {
		return !!imageToImageStore.config.background?.expand;
	};

	const canApplySolidBackground = (): boolean => {
		const { solid } = imageToImageStore.config.background || {};
		return !!(solid?.selected && solid?.color_code);
	};

	const canGenerateBackgroundByText = (): boolean => {
		const { generateByText } = imageToImageStore.config.background || {};
		return !!(generateByText?.selected && generateByText?.prompt?.length);
	};

	const canGenerateForegroundPlacement = (): boolean => {
		return !!(
			imageToImageStore.config.foreground_placement?.placements?.selected &&
			imageToImageStore.config.foreground_placement?.placements.values?.length
		);
	};

	const canGenerateImageReference = (): boolean => {
		const { image_reference } = imageToImageStore.config || {};
		return !!image_reference?.prompt;
	};

	const isGenerateEnabled = (): number | boolean => {
		const hasStyleOrBackground =
			canStyleBackground() ||
			canRemoveBackground() ||
			canExpandBackground() ||
			canApplySolidBackground() ||
			canGenerateBackgroundByText() ||
			canResizeImage() ||
			canGenerateForegroundPlacement() ||
			canGenerateImageReference();

		const isUnderLimit = countSelected * imageToImageStore.config.num_results <= 35;
		const isNotLoading = !playgroundStore.playgroundResults[
			playgroundStore.playgroundResults.length - 1
		]?.images.some((image) => image.loading);

		return hasStyleOrBackground && countSelected && isUnderLimit && isNotLoading && allSelectedImagesAvailable;
	};

	const updateSelectedBackgroundConfigs = () => {
		const backgroundConfig = imageToImageStore.config.background;
		const newSelectedConfigs: string[] = [];

		if (backgroundConfig?.remove) {
			newSelectedConfigs.push("remove");
		}
		if (backgroundConfig?.expand) {
			newSelectedConfigs.push("expand");
		}
		if (backgroundConfig?.solid?.selected && backgroundConfig.solid.color_code) {
			newSelectedConfigs.push("solid");
		}
		if (
			backgroundConfig?.generateByText?.selected &&
			backgroundConfig?.generateByText?.prompt &&
			backgroundConfig.generateByText.prompt?.trim() !== "" &&
			backgroundConfig?.generateByText?.num_results
		) {
			// newSelectedConfigs.push("generateByText");
			for (let i = 0; i < backgroundConfig?.generateByText?.num_results; i++) {
				newSelectedConfigs.push(`generateByText_${i}`);
			}
		}

		imageToImageStore.handleSelectedConfigsChange("background", newSelectedConfigs);
	};

	const updateSelectedSizeConfigs = () => {
		const sizeConfig = imageToImageStore.config.size;
		const newSelectedConfigs: string[] = [];

		if (sizeConfig?.crop) {
			newSelectedConfigs.push("crop");
		}
		if (
			sizeConfig?.aspect_ratio?.selected &&
			sizeConfig.aspect_ratio?.value &&
			sizeConfig.aspect_ratio?.value?.length > 0
		) {
			sizeConfig.aspect_ratio?.value.forEach((item) => newSelectedConfigs.push(item));
		}
		if (sizeConfig?.custom_size?.selected && sizeConfig.custom_size.width && sizeConfig.custom_size.height) {
			newSelectedConfigs.push("custom_size");
		}
		if (
			sizeConfig?.medium_destination?.selected &&
			sizeConfig.medium_destination.destinations &&
			sizeConfig.medium_destination.destinations?.length > 0
		) {
			sizeConfig.medium_destination?.destinations.forEach((item) => newSelectedConfigs.push(item.name));
		}

		imageToImageStore.handleSelectedConfigsChange("size", newSelectedConfigs);
	};

	const updateSelectedForegroundPlacementConfigs = () => {
		const foregroundPlacementConfig = imageToImageStore.config.foreground_placement?.placements;
		const newSelectedConfigs: string[] = [];

		if (
			foregroundPlacementConfig?.selected &&
			foregroundPlacementConfig?.values &&
			foregroundPlacementConfig?.values.length > 0
		) {
			foregroundPlacementConfig.values.forEach((item) => newSelectedConfigs.push(item));
		}

		imageToImageStore.handleSelectedConfigsChange("foreground_placement", newSelectedConfigs);
	};

	const updateSelectedImageReferenceConfigs = () => {
		const imageReference = imageToImageStore.config.image_reference;
		const results = imageReference?.num_results || 1;
		const newSelectedConfigs: string[] = [];

		if (imageReference?.prompt && imageReference?.prompt?.trim()?.length > 0) {
			for (let i = 1; i <= results; i++) {
				newSelectedConfigs.push(`reference_${i}`);
			}
		}

		imageToImageStore.handleSelectedConfigsChange("image_reference", newSelectedConfigs);
	};

	return {
		countSelected,
		canStyleBackground,
		canRemoveBackground,
		canResizeImage,
		canExpandBackground,
		canApplySolidBackground,
		canGenerateBackgroundByText,
		isGenerateEnabled,
		updateSelectedBackgroundConfigs,
		updateSelectedSizeConfigs,
		updateSelectedForegroundPlacementConfigs,
		updateSelectedImageReferenceConfigs,
	};
};
