import { FoxTeam } from "../../../models/aiEditor";
import BriaDropDown, { BriaDropDownProps } from "../BriaDropDown/BriaDropDown";
import useStaticDropdown from "./useStaticDropdown";

type Props<T> = {
	type: "aspectRatio" | "tailoredStyles" | "tailoredSubStyles" | "genModels" | "desiredResolution" | "sandboxAPIFamily";
} & Partial<BriaDropDownProps<T>>;

const StaticDropdown = <T,>({ type, ...rest }: Props<T>) => {
	return <BriaDropDown<T> {...rest} {...useStaticDropdown<T>({ tKeyPrefix: type })} />;
};

export default StaticDropdown;

export type AspectRatio = "4:3" | "3:4" | "1:1" | "16:9" | "9:16" | "5:4" | "4:5" | "3:2" | "2:3";
export type TailoredStyles = "fox_nfl";
export type TailoredSubStyles = FoxTeam;
export type GenModel = "bria2_3_fast" | "bria2_2_hd" | "bria2_3";
export type DesiredResolutionType =
	| "original"
	| "nhd"
	| "svga"
	| "xga"
	| "hd"
	| "wuxga"
	| "uwfhd"
	| "wqxga"
	| "uwqhd"
	| "uhd";
