import { Environment, getCurrentEnv } from "../config/env.ts";
import RouterConstants from "../constants/RouterConstants.ts";
import { AESCipher } from "../helpers/encryption.ts";
import { Iframe, IframePages } from "../models/new-iframe.ts";
import iframeStore from "../pages/IframeNew/iframe-store.tsx";
import { useAuthService } from "./useAuthService.tsx";
import useSecureNavigate from "./useSecureNavigate.tsx";

export const useIframe = () => {
	const navigate = useSecureNavigate();
	const { loginWithEmail } = useAuthService();
	const queryParams = new URLSearchParams(location.search);

	const getIframeHost = () => {
		switch (getCurrentEnv()) {
			case Environment.local:
				return "http://localhost:3001";
			case Environment.integration:
				return "https://int.iframe.platform.bria.ai";
			default:
				return "https://prod.iframe.platform.bria.ai";
		}
	};

	const getIframePreviewUrl = () => {
		return `${getIframeHost()}/?iframeId=preview`;
	};

	const getIframeId = () => {
		return iframeStore.iframe?.id || queryParams.get("iframeId");
	};

	const createIframeUrl = (iframe: Iframe) => {
		return `${getIframeHost()}/?iframeId=${iframe.id}`;
	};

	const navigateToLandingPage = () => {
		if (iframeStore.iframe) {
			const routes: Partial<{ [landingPage in IframePages]: string }> = {
				textToImage: RouterConstants.TEXT_TO_IMAGE.path,
				textToVector: RouterConstants.TEXT_TO_VECTOR.path,
				imageToImage: RouterConstants.IMAGE_TO_IMAGE.path,
				campaign: RouterConstants.DESIGN_EDITOR.path,
				aiEditor: RouterConstants.AI_EDITOR.path,
			};
			const landing_route = routes[iframeStore.iframe.config.landing_page];
			landing_route && navigate(landing_route);
		} else {
			throw new Error("Iframe does not exists");
		}
	};

	const authenticateIframe = async (iframe: Iframe) => {
		const aesCipher = new AESCipher(iframe.org_id);
		const [decryptedEmail, decryptedPassword] = await Promise.all([
			aesCipher.decrypt(iframe.username),
			aesCipher.decrypt(iframe.password),
		]);

		return loginWithEmail(decryptedEmail, decryptedPassword);
	};

	return {
		getIframeHost,
		getIframePreviewUrl,
		getIframeId,
		createIframeUrl,
		navigateToLandingPage,
		authenticateIframe,
	};
};
