import { Box, CircularProgress, Container, Grid, TextField, ToggleButton, Typography } from "@mui/material";
import clsx from "clsx";
import _ from "lodash";
import { observer } from "mobx-react-lite";
import { nanoid } from "nanoid";
import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import BriaButton from "../../../../../../../components/common/BriaButton/BriaButton.tsx";
import { DropDownItem } from "../../../../../../../components/common/BriaDropDown/BriaDropDown.tsx";
import BriaToggleButtonGroup from "../../../../../../../components/common/BriaToggleButtonGroup/BriaToggleButtonGroup.tsx";
import ConfirmationPopup from "../../../../../../../components/common/ConfirmationPopup/ConfirmationPopup.tsx";
import DebouncedInput from "../../../../../../../components/common/DebouncedInput/DebouncedInput.tsx";
import BriaImage, { BriaImageProps } from "../../../../../../../components/common/Galleries/BriaImage.tsx";
import LoadingPlaceholder from "../../../../../../../components/common/LoadingPlaceholder/LoadingPlaceholder.tsx";
import TemplateImageOverlay from "../../../../../../../components/common/TemplateImageOverlay/TemplateImageOverlay.tsx";
import { firebaseAuth } from "../../../../../../../config/firebase.ts";
import { APPS } from "../../../../../../../constants/AppsConstants.ts";
import { BRIA_PUBLIC_ORG_ID } from "../../../../../../../constants/OrgConstants.ts";
import { getSelectedOrganization } from "../../../../../../../helpers/localStorage.ts";
import { useAppStore } from "../../../../../../../hooks/useStores.tsx";
import InputLayout from "../../../../../../../layout/InputLayout/InputLayout.tsx";
import { CampaignEntityStatus } from "../../../../../../../models/common.ts";
import { PlaygroundImage } from "../../../../../../../models/image-to-image.ts";
import useCampaignHeaderUtils from "../../../../../../CustomUtils/CampaignHeaderUtils.tsx";
import useTemplateUtils from "../../../../../../CustomUtils/UseTemplateUtils.tsx";
import duplicate from "../../../../../../assets/svgs/Duplicate.svg";
import Download from "../../../../../../assets/svgs/download.svg";
import trash from "../../../../../../assets/svgs/trash.svg";
import { CampaignPreviewBtn, TemplatePreviewBtn } from "../../../../../../campaign-store.tsx";
import MainTabHeader from "../../../../../../components/common/MainTabHeader/MainTabHeader.tsx";
import { PanelType } from "../../../../../../constants/app-options.ts";
import { getDefaultTemplate } from "../../../../../../constants/design-editor.ts";
import { PopUpSteps } from "../../../../../../constants/mock-data.ts";
import { DesignEditorContext } from "../../../../../../contexts/DesignEditor.tsx";
import useAppContext from "../../../../../../hooks/useAppContext.tsx";
import { useEditor } from "../../../../../../hooks/useEditor.tsx";
import useIsSidebarOpen from "../../../../../../hooks/useIsSidebarOpen.tsx";
import { IDesign } from "../../../../../../interfaces/DesignEditor.ts";
import { Template, defaultTemplate } from "../../../../../../models/template.ts";
import { IScene } from "../../../../../../types/scene.ts";
import { ObjectsEnum } from "../index.ts";
import MediaComponent from "./MediaComponent/MediaComponent.tsx";
import styles from "./Templates.module.scss";

interface Iprops {
	type: CampaignEntityStatus;
}

export interface IMenuItem {
	label: string;
	icon: string;
	onClick: (image: Template) => void;
}

const Templates = ({ type }: Iprops) => {
	const { activePanel } = useAppContext();
	const editor = useEditor();
	const { getPreviewImageForFirstScene } = useCampaignHeaderUtils();
	const isSidebarOpen = useIsSidebarOpen();
	const { campaignStore, designEditorStore, uiStore, brandsDefinitionStore } = useAppStore();
	const { isPopupView } = useContext(DesignEditorContext);
	const { loadTemplate, handleDownloadTemplate, exportTemplate } = useTemplateUtils();
	const [savedTuples, setSavedTuples] = useState<[number, number][]>([]);
	const [searchBoxVisible, setSearchBoxVisible] = useState(false);
	const [selectedPlacements, setSelectedPlacements] = useState<string[]>([]);
	const [selectedTemplate, setSelectedTemplate] = useState<Template>();
	const [templateToLoad, setTemplateToLoad] = useState<Template>();
	const [searchTerm, setSearchTerm] = useState<string | undefined>();
	const [newCampaignName, setNewCampaignName] = useState<string>("");
	const [isValidCampaignName, setIsValidCampaignName] = useState<boolean>(true);
	const [openTemplatePopup, setOpenTemplatePopup] = useState<boolean>(true);
	const { t } = useTranslation("translation", { keyPrefix: "editor.tabs.templates" });
	const allOptionsKey = t("allMediaDestination");
	const placements = campaignStore.placements.items.map((plac) => ({
		key: plac.name,
		value: plac.name,
	}));
	const items: DropDownItem[] = [{ key: allOptionsKey, value: allOptionsKey }, ...placements];
	const prevValueRef = useRef(selectedPlacements);
	const [imagesGallery, _setImageGallery] = useState<number[]>(new Array(2).fill(0));
	const [openDeletePopup, setOpenDeletePopup] = useState(false);
	const [ConfirmTemplateReplacePopup, setConfirmTemplateReplacePopup] = useState(false);
	const [templateToDelete, setTemplateToDelete] = useState<Template>();
	const [templateToDuplicate, setTemplateToDuplicate] = useState<Template>();
	const { setScenes, setCurrentScene, setCurrentDesign } = useContext(DesignEditorContext);
	const selectedOrg = getSelectedOrganization();
	const organizationName = selectedOrg?.organization?.name;
	const capitalizedOrganizationName = organizationName
		? organizationName?.charAt(0)?.toUpperCase() + organizationName?.slice(1)
		: "";
	const isCampaignWizardLastStep = designEditorStore.activeStep + 1 === designEditorStore.editorStepperSteps.length;
	const menuItems: IMenuItem[] = [
		{
			label: t("Download"),
			icon: Download,
			onClick: (template) => {
				handleDownloadTemplate(template);
			},
		},
		{
			label: t("Delete"),
			icon: trash,
			onClick: (image) => {
				handleOpenDeletePopup(image);
			},
		},
	];

	if (type === CampaignEntityStatus.DRAFT) {
		menuItems.splice(1, 0, {
			label: t("duplicateTo", {
				folder:
					campaignStore.selectedCampaignPreviewBtn === CampaignPreviewBtn.SHARED
						? t("private.toggleBtn")
						: t("shared.toggleBtn"),
			}),
			icon: duplicate,
			onClick: (template) => {
				setOpenTemplatePopup(false);
				openSaveNewPopup();
				setTemplateToDuplicate(template);
			},
		});
	}

	if (
		campaignStore.isAdminMode &&
		campaignStore?.selectedTemplatePreviewBtn == TemplatePreviewBtn.PUBLIC &&
		getSelectedOrganization()?.organization.uid != BRIA_PUBLIC_ORG_ID
	) {
		// Find the index of the "Delete" item
		const deleteIndex = menuItems.findIndex((item) => item.label === t("Delete"));
		// Remove it if it exists
		if (deleteIndex !== -1) {
			menuItems.splice(deleteIndex, 1);
		}
	}

	useEffect(() => {
		const loadPlacementsAndTemplates = async () => {
			const fetchOrgTemplates = !(
				type == CampaignEntityStatus.DRAFT &&
				campaignStore.selectedCampaignPreviewBtn === CampaignPreviewBtn.PRIVATE
			);
			const getPublicTemplates =
				activePanel === PanelType.TEMPLATES &&
				campaignStore.selectedTemplatePreviewBtn === TemplatePreviewBtn.PUBLIC;
			await campaignStore.loadTemplates(type, fetchOrgTemplates, getPublicTemplates);
			await campaignStore.loadPlacements();
		};

		const loadTemplateAndPlacements = async () => {
			if (designEditorStore.campaignUpdatedTemplate) {
				loadTemplate(designEditorStore.campaignUpdatedTemplate?.ads_json);
			}

			await loadPlacementsAndTemplates();
		};

		(!isPopupView || (isPopupView && !isCampaignWizardLastStep)) && loadTemplateAndPlacements();
	}, []);

	useEffect(() => {
		const loadNewlyCreatedCampaign = async () => {
			if (campaignStore.newCampaignId) {
				for (const template of campaignStore.templates.items) {
					if (campaignStore.newCampaignId === template.id) await loadTemplateScenes(template);
				}
				campaignStore.newCampaignId = null;
			}
		};
		loadNewlyCreatedCampaign();
	}, [campaignStore.newCampaignId, campaignStore.templates.items]);

	const filterTemplates = async (tuples: [number, number][], searchTerm: string | "") => {
		const getPublicTemplates =
			activePanel === PanelType.TEMPLATES &&
			campaignStore.selectedTemplatePreviewBtn === TemplatePreviewBtn.PUBLIC;
		const fetchOrgTemplates = !(
			type == CampaignEntityStatus.DRAFT &&
			campaignStore.selectedCampaignPreviewBtn === CampaignPreviewBtn.PRIVATE
		);

		await campaignStore.getFilteredTemplatesBasesOnPlacementsAndName(
			JSON.stringify(tuples),
			searchTerm,
			type,
			fetchOrgTemplates,
			getPublicTemplates,
		);
	};

	const handleSearchChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
		const newSearchTerm = e.target.value;
		setSearchTerm(newSearchTerm);

		await filterTemplates(savedTuples, newSearchTerm);
	};

	const updateTuples = (selectedPlacements: string[]) => {
		const tuples = selectedPlacements
			.map((selected) => {
				const placement = campaignStore.placements.items.find((p) => p.name === selected);
				return placement ? [placement.width, placement.height] : null;
			})
			.filter((tuple): tuple is [number, number] => tuple !== null);
		setSavedTuples(tuples);

		return tuples;
	};

	const onChangePlacementDropdown = async (value: string | string[]) => {
		if (Array.isArray(value)) {
			if (value.includes(allOptionsKey)) {
				if (selectedPlacements.includes(allOptionsKey)) {
					if (value.length - 1 != placements.length) {
						value = value.filter(function (item) {
							return item !== allOptionsKey;
						});
						setSelectedPlacements(value);
					}
				} else {
					setSelectedPlacements(items.map((item) => item.key));
				}
			} else {
				if (selectedPlacements.includes(allOptionsKey) && prevValueRef.current.includes(allOptionsKey)) {
					setSelectedPlacements([]);
				} else {
					setSelectedPlacements(value);
					if (value.length == placements.length) {
						value.push(allOptionsKey);
						setSelectedPlacements(value);
					}
				}
			}
			prevValueRef.current = value;
			const tuples = updateTuples(Array.isArray(value) ? value : [value]);
			await filterTemplates(tuples, searchTerm || "");
		}
	};

	const handleSetSelectedTemplatePreviewButton = async (value: TemplatePreviewBtn) => {
		campaignStore.handleSetSelectedTemplatePreviewButton(value);
		await filterTemplates(savedTuples, searchTerm || "");
	};

	const handleCampaignPreviewBtnChange = async (value: CampaignPreviewBtn) => {
		campaignStore.handleSetSelectedCampaignPreviewButton(value);
		await filterTemplates(savedTuples, searchTerm || "");
	};

	const deleteTemplate = async () => {
		if (templateToDelete) {
			const updateTemplate: Template = {
				...templateToDelete,
				status: CampaignEntityStatus.DELETED,
			};
			closeDeletePopup();
			await campaignStore.updateTemplate(updateTemplate);
		}
	};

	const duplicateTemplate = async (template: Template, duplicateToShared: boolean) => {
		if (!newCampaignName) {
			setIsValidCampaignName(false);
			return;
		}
		let updatedJson = template.ads_json;
		if (campaignStore.selectedTemplate && campaignStore?.selectedTemplate?.id == template.id) {
			updatedJson = await exportTemplate();
		}
		if (updatedJson) {
			const new_template: Template = {
				...template,
				name: newCampaignName,
				org_id: duplicateToShared ? getSelectedOrganization()?.organization?.uid : null,
				user_id: !duplicateToShared ? firebaseAuth.currentUser?.uid : null,
				ads_json: updatedJson,
				preview: (await getPreviewImageForFirstScene(updatedJson)) || "",
			};
			await campaignStore.duplicateTemplate(new_template);
			closeDuplicatePopup();
			campaignStore.handleSetSelectedCampaignPreviewButton(
				duplicateToShared ? CampaignPreviewBtn.SHARED : CampaignPreviewBtn.PRIVATE,
			);
		}
	};

	const openSaveNewPopup = async () => {
		uiStore.opensaveNewTemplatePopup = true;
	};

	const handleOpenDeletePopup = (template: Template) => {
		setOpenDeletePopup(true);
		setTemplateToDelete(template);
	};

	const closeDeletePopup = () => {
		setOpenDeletePopup(false);
		setTemplateToDelete(undefined);
	};
	const closeDuplicatePopup = () => {
		uiStore.hideDialog("opensaveNewTemplatePopup");
		setTemplateToDuplicate(undefined);
	};
	const closeOpenUnsavedChangesPopup = () => {
		setConfirmTemplateReplacePopup(false);
	};

	const saveNewTemplatePopup = () => {
		return (
			<ConfirmationPopup
				onClose={closeDuplicatePopup}
				title={openTemplatePopup ? t("savenewTemplatePopup.title") : t("savenewCampaignPopup.title")}
				description={
					openTemplatePopup ? t("savenewTemplatePopup.description") : t("savenewCampaignPopup.description")
				}
				confirmButtonText={
					openTemplatePopup ? t("savenewTemplatePopup.button") : t("savenewCampaignPopup.button")
				}
				disableConfirm={campaignStore.isTemplateDuplicationInProgress}
				loading={campaignStore.isTemplateDuplicationInProgress}
				open={uiStore.opensaveNewTemplatePopup}
				onClick={() => {
					if (!openTemplatePopup) {
						templateToDuplicate &&
							duplicateTemplate(
								templateToDuplicate,
								campaignStore.selectedCampaignPreviewBtn === CampaignPreviewBtn.PRIVATE,
							);
					} else {
						createNewTemplate();
					}
				}}
				maxWidth="420px"
			>
				<Box>
					<InputLayout
						showLabel={false}
						validateOn={!isValidCampaignName}
						validationMessage={
							openTemplatePopup
								? t("savenewTemplatePopup.validation.CampaignName")
								: t("savenewTemplatePopup.validation.CampaignName")
						}
					>
						<TextField
							value={newCampaignName}
							onChange={(e) => {
								setNewCampaignName(e.target.value);
							}}
							placeholder={t("savenewTemplatePopup.campaignName")}
							fullWidth
							InputProps={{ classes: { root: styles.textFieldDataSetCreation } }}
						/>
					</InputLayout>
				</Box>
			</ConfirmationPopup>
		);
	};

	const createNewTemplate = async () => {
		if (!newCampaignName) {
			setIsValidCampaignName(false);
			return;
		}
		if (editor) {
			const updatedJson = await exportTemplate();
			const newTemplate: Template = {
				...defaultTemplate,
				name: newCampaignName,
				ads_json: updatedJson,
				status: CampaignEntityStatus.ACTIVE,
			};
			campaignStore.selectedTemplate = newTemplate;
			const defaultTemp = getDefaultTemplate({
				width: 1080,
				height: 1080,
			});
			setCurrentDesign({
				id: nanoid(),
				frame: defaultTemp.frame,
				metadata: {},
				name: t("untitledDesign"),
				preview: "",
				scenes: [],
			});
			editor.scene
				.importFromJSON(defaultTemp, false)
				.then(() => {
					editor.history.reset();
					const initialDesign = editor.scene.exportToJSON() as IScene;
					editor.renderer.render(initialDesign).then((data) => {
						setCurrentScene({ ...initialDesign, preview: data });
						setScenes([{ ...initialDesign, preview: data }]);
					});
				})
				.catch((error) => {
					console.error("Error importing scene from JSON:", error);
				});
			uiStore.hideDialog("opensaveNewTemplatePopup");
		}
	};

	const checkIfTemplateHasSmartImages = (template: Template) => {
		let innerRectangle;
		let outerRectangle;
		// We only check if the template first scene has a smart image, Because this function is more related to the wizard
		for (const layer of template.ads_json.scenes[0].layers) {
			if (layer.type === "Group") {
				for (const object of layer.objects) {
					if (object.id === ObjectsEnum.InnerRectangle) {
						innerRectangle = object;
					} else if (object.id === ObjectsEnum.OuterRectangle) {
						outerRectangle = object;
					}
				}
			}
		}
		if (!innerRectangle || !outerRectangle) {
			designEditorStore.setProperty("editorStepperDisabledStep", PopUpSteps.indexOf("images"));
		} else {
			designEditorStore.setProperty("editorStepperDisabledStep", undefined);
		}
	};

	const loadTemplateScenes = async (template: Template) => {
		if (!isPopupView) {
			brandsDefinitionStore.templateScenesBeforeApplyBrand = _.cloneDeep(template.ads_json as IDesign);
		} else {
			brandsDefinitionStore.templateScenesBeforeApplyBrandInWizard = _.cloneDeep(template.ads_json as IDesign);
		}
		editor?.history.reset();
		await designEditorStore.setProperty("isLoadingTemplate", true);
		setSelectedTemplate(template);
		setTimeout(() => {
			loadTemplate(template.ads_json)
				.then(() => {
					if (!isPopupView) {
						campaignStore.handleSetSelectedTemplate(template);
					} else {
						campaignStore.handleSetSelectedWizardTemplate(template);
					}
					designEditorStore.setProperty("isTemplateEmptyState", false);
					designEditorStore.setProperty("isLoadingTemplate", false);
					checkIfTemplateHasSmartImages(template);
				})
				.catch((error) => {
					designEditorStore.setProperty("isLoadingTemplate", false);
					console.error("Error loading template scenes: ", error);
				});
		}, 50);
	};

	return (
		<Box
			className={clsx({
				[styles.templatesTabContainer]: true,
				[styles.hide]: !isSidebarOpen,
			})}
		>
			<MainTabHeader
				title={type == CampaignEntityStatus.DRAFT ? t("Campaigns") : t("title")}
				setSearchBoxVisible={setSearchBoxVisible}
				searchBoxVisible={searchBoxVisible}
				enableSearch={true}
			/>

			<Box className={`${styles.tabs} ${styles.fullHeight}`}>
				<Box className={styles.mainTabContent}>
					{type === CampaignEntityStatus.DRAFT ? (
						<Box className={styles.toggleButtons}>
							<BriaToggleButtonGroup
								value={campaignStore.selectedCampaignPreviewBtn}
								onChange={(_e, value) => handleCampaignPreviewBtnChange(value)}
							>
								<ToggleButton
									disabled={campaignStore.isLoadingTemplates}
									value={CampaignPreviewBtn.PRIVATE}
								>
									{t("private.toggleBtn")}
								</ToggleButton>
								<ToggleButton
									disabled={campaignStore.isLoadingTemplates}
									value={CampaignPreviewBtn.SHARED}
								>
									{capitalizedOrganizationName}
								</ToggleButton>
							</BriaToggleButtonGroup>
						</Box>
					) : (
						<Box className={styles.toggleButtons}>
							<BriaToggleButtonGroup
								value={campaignStore.selectedTemplatePreviewBtn}
								onChange={(_e, value) => handleSetSelectedTemplatePreviewButton(value)}
							>
								<ToggleButton
									disabled={campaignStore.isLoadingTemplates}
									value={TemplatePreviewBtn.ORGANIZATION}
								>
									{capitalizedOrganizationName}
								</ToggleButton>
								<ToggleButton
									disabled={campaignStore.isLoadingTemplates}
									value={TemplatePreviewBtn.PUBLIC}
								>
									{t("public.toggleBtn")}
								</ToggleButton>
							</BriaToggleButtonGroup>
						</Box>
					)}
					{searchBoxVisible && (
						<DebouncedInput
							onChange={handleSearchChange}
							className={styles.customBriaInput}
							onKeyDown={(e) => e.stopPropagation()}
							height="40px"
							placeHolder={"Search"}
						/>
					)}

					<MediaComponent
						selectedPlacements={selectedPlacements}
						onChangePlacementDropdown={onChangePlacementDropdown}
						items={items}
						loading={campaignStore.isLoadingPlacements}
					/>

					<LoadingPlaceholder
						className={styles.circleLoader}
						size="25px"
						isLoading={campaignStore.isLoadingTemplates}
					>
						<Grid item className={styles.GalleryGrid}>
							<Box className={styles.galleyImagesBox}>
								<Container disableGutters maxWidth={false} className={styles.gallery}>
									{imagesGallery.map((_, index: number) => (
										<Box
											className={styles.imageContainer}
											key={index}
											marginRight={index < imagesGallery.length - 1 ? 1 : 0}
										>
											{campaignStore.templates.items
												?.filter((_, i: number) => i % 2 === index)
												.map((template: Template, index2: number) => (
													<Box
														className={clsx(styles.imageWrapper, {
															[styles.selectedTemplate]:
																template.id === selectedTemplate?.id,
														})}
														key={index2}
														sx={{ position: "relative" }}
													>
														{template.id === selectedTemplate?.id &&
															designEditorStore.isLoadingTemplate && (
																<>
																	<Box className={styles.blurLayer} />
																	<CircularProgress
																		size={24}
																		className={styles.loader}
																	/>
																</>
															)}
														<BriaImage
															image={
																{
																	id: "",
																	url: template.preview,
																	type: APPS.DESIGN_EDITOR,
																} as BriaImageProps & PlaygroundImage
															}
															{...template}
															// visualHash={image.visual_hash}
															url={`${`${template.preview}?v=${campaignStore.cacheBustingVersion}`}`}
															handleClick={async () => {
																campaignStore.originalLogoObjects = {};
																if (campaignStore.selectedTemplate && !isPopupView) {
																	setTemplateToLoad(template);
																	setConfirmTemplateReplacePopup(true);
																} else {
																	loadTemplateScenes(template);
																}
															}}
															hideFullScreenButton={true}
															ImageOverlay={
																<TemplateImageOverlay
																	menuItems={menuItems}
																	template={template}
																	isPopupView={isPopupView ?? false}
																/>
															}
															displayOverlay="on"
															disabled={designEditorStore.isLoadingTemplate}
														/>
													</Box>
												))}
										</Box>
									))}
								</Container>
							</Box>
						</Grid>
					</LoadingPlaceholder>
					{type !== CampaignEntityStatus.DRAFT && campaignStore.isAdminMode && !isPopupView && (
						<Box className={styles.createNewTemplate}>
							<BriaButton
								onClick={() => {
									setOpenTemplatePopup(true);
									openSaveNewPopup();
								}}
								buttonType="primaryMedium"
								loading={false}
								fullWidth
								size="small"
							>
								{t("createNewTemplate")}
							</BriaButton>
						</Box>
					)}

					{type === CampaignEntityStatus.DRAFT && (
						<Box className={styles.createNewTemplate}>
							<BriaButton
								onClick={() => {
									designEditorStore.setProperty("isTemplateEmptyState", true);
									designEditorStore.setProperty("designEditorPopup", true);
									campaignStore.setProperty("isEditingAllAds", true);
									campaignStore.handleSetSelectedTemplatePreviewButton(
										TemplatePreviewBtn.ORGANIZATION,
									);
								}}
								buttonType="primaryMedium"
								loading={false}
								fullWidth
								size="small"
								disabled={campaignStore.isLoadingTemplates}
							>
								{t("createNewCampaign")}
							</BriaButton>
						</Box>
					)}
				</Box>
			</Box>
			{saveNewTemplatePopup()}
			<ConfirmationPopup
				onClose={closeDeletePopup}
				title={t("deletePopup.title")}
				description={t("deletePopup.description")}
				confirmButtonText={t("deletePopup.confirmText")}
				open={openDeletePopup}
				onClick={deleteTemplate}
			/>
			<ConfirmationPopup
				onClose={closeOpenUnsavedChangesPopup}
				title={t("confirmTemplateReplacePopup.title")}
				description={t("confirmTemplateReplacePopup.description")}
				subDescription={
					<Typography className={styles.subDescription}>
						{t("confirmTemplateReplacePopup.subDescription")}
					</Typography>
				}
				confirmButtonText={t("confirmTemplateReplacePopup.confirmText")}
				open={ConfirmTemplateReplacePopup}
				hideCancel={true}
				onClick={async () => {
					if (templateToLoad) await loadTemplateScenes(templateToLoad);
					closeOpenUnsavedChangesPopup();
				}}
				maxWidth="565px"
				paperClassName={styles.paper}
			/>
		</Box>
	);
};
const ObservedComponent = observer(Templates);
export default ObservedComponent;
