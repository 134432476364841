import { Box, Divider } from "@mui/material";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BriaButton from "../../../../components/common/BriaButton/BriaButton.tsx";
import GenericInput from "../../../../components/common/GenericInput/GenericInput.tsx";
import { APPS } from "../../../../constants/AppsConstants.ts";
import RouterConstants from "../../../../constants/RouterConstants.ts";
import { BrushActions } from "../../../../hooks/brush/useBrushActions.tsx";
import useSecureNavigate from "../../../../hooks/useSecureNavigate.tsx";
import { useAppStore } from "../../../../hooks/useStores.tsx";
import InputLayout from "../../../../layout/InputLayout/InputLayout.tsx";
import { isCanvasRequiredForThisApp } from "../../../../utils";
import APIFamilyDropdown from "../APIFamilyDropdown/APIFamilyDropdown";
import { validateField } from "../../../../utils/validators";
import { APIParameter } from "../../../../models/sandboxAPI.ts";
import styles from "./SandboxAPIConfig.module.scss";

export enum PlacementTypeEnum {
	Original = "original",
	Automatic = "automatic",
	ManualPlacement = "manual_placement",
	ManualPadding = "manual_padding",
}

export enum ShadowTypeEnum {
	Regular = "regular",
	Float = "float",
}

const SandboxAPIConfig = () => {
	const { t } = useTranslation("translation", { keyPrefix: "playground.sandboxAPI" });
	const navigate = useSecureNavigate();
	const { sandboxAPIStore, playgroundStore } = useAppStore();
	const [payload, setPayload] = useState<Record<string, any>>({});

	useEffect(() => {
		if (!sandboxAPIStore.config.apiConfig) {
			navigate(`/${RouterConstants.PAGE_NOT_FOUND.path}`, { replace: true });
		}
		playgroundStore.clearResults();
		playgroundStore.handleAppChange(APPS.SANDBOX_API);

		return () => {
			playgroundStore.clearResults();
			playgroundStore.handleAppChange(APPS.TEXT_TO_IMAGE);
			sandboxAPIStore.config.selectedSubAPI = undefined;
		};
	}, []);

	useEffect(() => {
		const parameters = sandboxAPIStore.config.apiConfig?.parameters;
		const initialPayload: Record<string, any> = {};

		parameters?.forEach((field: any) => {
			if (field.defaultValue !== undefined) {
				initialPayload[field.name] = field.defaultValue;
			} else if (field.type === "intArray") {
				initialPayload[field.name] = Array.from({ length: field.numberOfInputs });
			} else {
				initialPayload[field.name] = null;
			}
		});

		setPayload((_prevPayload) => ({
			...initialPayload,
		}));
	}, [sandboxAPIStore.config.selectedSubAPI]);

	const handleColorChange = (name: string, value: string) => {
		setPayload((prevPayload) => ({
			...prevPayload,
			[name]: value,
		}));
	};

	const handleIntArrayChange = (
		field: APIParameter,
		index: number,
		value: number | null
	) => {
		if(field?.validationMessage) {
			const ruleSet = field.validationSchema;
			if(ruleSet) {
				field.validationMessage = validateField(ruleSet, value);
			}
		}

		setPayload((prevPayload) => {
			// To initiate the updated inputs that were hidden
			if (prevPayload[field.name] === undefined) {
				prevPayload[field.name] = field.defaultValue;
			}
			prevPayload[field.name][index] = value;
			return { ...prevPayload };
		});
	};

	const handlePlacementType = (placement: string) => {
		const manualPlacement = sandboxAPIStore.config.apiConfig?.parameters.find(
			(field) => field.name === "manual_placement_selection",
		);
		const paddingValues = sandboxAPIStore.config.apiConfig?.parameters.find(
			(field) => field.name === "padding_values",
		);
		const numResults = sandboxAPIStore.config.apiConfig?.parameters.find((field) => field.name === "num_results");
		const shotSize = sandboxAPIStore.config.apiConfig?.parameters.find((field) => field.name === "shot_size");
		if (!manualPlacement || !paddingValues || !numResults || !shotSize) return;
		switch (placement) {
			case PlacementTypeEnum.Original:
				shotSize.hidden = true;
				numResults.hidden = false;
				manualPlacement.hidden = true;
				paddingValues.hidden = true;
				break;
			case PlacementTypeEnum.Automatic:
				shotSize.hidden = false;
				numResults.hidden = true;
				manualPlacement.hidden = true;
				paddingValues.hidden = true;
				break;
			case PlacementTypeEnum.ManualPlacement:
				shotSize.hidden = false;
				numResults.hidden = true;
				manualPlacement.hidden = false;
				paddingValues.hidden = true;
				break;
			case PlacementTypeEnum.ManualPadding:
				shotSize.hidden = true;
				numResults.hidden = false;
				manualPlacement.hidden = true;
				paddingValues.hidden = false;
				break;
		}
	};

	const handleShadowType = (type: string) => {
		const shadowWidth = sandboxAPIStore.config.apiConfig?.parameters.find((field) => field.name === "shadow_width");
		const shadowHeight = sandboxAPIStore.config.apiConfig?.parameters.find(
			(field) => field.name === "shadow_height",
		);
		if (!shadowWidth || !shadowHeight) return;

		switch (type) {
			case ShadowTypeEnum.Regular:
				shadowWidth.hidden = true;
				shadowHeight.hidden = true;
				break;
			case ShadowTypeEnum.Float:
				shadowWidth.hidden = false;
				shadowHeight.hidden = false;
				break;
		}
	};

	const handleInputChange = (field: APIParameter, e: ChangeEvent<HTMLInputElement>) => {
		let value: string | number | boolean | null;
		const type = e.target.type;
		switch (type) {
			case "checkbox":
				value = e.target.checked;
				break;
			case "number":
				if (e.target.value === null || e.target.value === "") {
					value = null;
				} else {
					value = isNaN(Number(e.target.value)) ? "" : Number(e.target.value);
				}
				break;
			default:
				value = e.target.value;
		}

		switch (field.name) {
			case "brush_size":
				if (typeof value === "number") {
					sandboxAPIStore.brushConfigs.lineWidth = value;
				}
		}

		if (sandboxAPIStore.config.apiConfig?.id === "ECOMMERCE_API_4" && field.name === "placement_type") {
			handlePlacementType(value as string);
		}

		if (sandboxAPIStore.config.apiConfig?.id === "ECOMMERCE_API_3" && field.name === "type") {
			handleShadowType(value as string);
		}

		if(field?.validationMessage) {
			const ruleSet = field.validationSchema;
			if(ruleSet) {
				field.validationMessage = validateField(ruleSet, value);
			}
		}

		setPayload((prevPayload) => ({
			...prevPayload,
			[field.name]: value,
		}));
	};

	const generateResults = async (action?: string): Promise<void> => {
		const parameters = sandboxAPIStore.config.apiConfig?.parameters;
		const finalPayload: Record<string, any> = {};

		parameters?.forEach((field: APIParameter) => {
			const value = payload[field.name];

			const ruleSet = field.validationSchema;
			if(ruleSet) {
				if (field.type === "intArray") {
					(value as number[]).some(item => {
						field.validationMessage = validateField(ruleSet, item);
						return !!field.validationMessage;
					});
				} else {
					field.validationMessage = validateField(ruleSet, value);
				}
			}

			if (value === null || value === undefined || value === "" || field.hidden || field.type === "imageUpload") {
				return;
			}

			if (field.type === "intArray") {
				finalPayload[field.name] = (value as Array<number | null>).map((item) => (item === null ? 0 : item));
				return;
			}

			finalPayload[field.name] = value;
		});

		if(parameters?.some(item => item?.validationMessage)) {
			return;
		}

		await sandboxAPIStore.generateResults(JSON.stringify(finalPayload), action);
	};

	const isDisabled = (): boolean => {
		if (!playgroundStore.getSelectedImages().length) {
			return true;
		}

		let validationError = false;
		validationError = !!sandboxAPIStore.config.apiConfig?.parameters?.some((field: APIParameter) => {
			const value = payload[field.name];
			if (field.type === "intArray" && value) {
				return (value as number[]).some(() => {
					return !!field.validationMessage;
				});
			} else {
				return !!field.validationMessage;
			}
		})
		if(validationError) {
			return true;
		}

		const isLoading = playgroundStore.playgroundResults[playgroundStore.playgroundResults.length - 1]?.images.some(
			(image) => image.loading,
		);

		if (isLoading) {
			return true;
		}

		const requiredFields = sandboxAPIStore.config.apiConfig?.parameters.filter((item) => item.required);
		return requiredFields?.find(
			(item) => payload[item.name] === null || payload[item.name] === undefined || payload[item.name] === "",
		)
			? true
			: false;
	};

	const handleButtonClick = async (action: string): Promise<void> => {
		switch (action) {
			case "erase_object":
				if (sandboxAPIStore.brushCanvasRefs.length > 0 && sandboxAPIStore.brushCanvasRefs[0].canvasRef) {
					const brushActions = new BrushActions(
						sandboxAPIStore.brushCanvasRefs[0].canvasRef.current,
						sandboxAPIStore.brushCanvasRefs[0].canvasOverlayRef.current,
					);
					const imageUrl = playgroundStore.playgroundResults[0].images[0].url;
					const maskFileBase64 = await brushActions.handleCanvasDownload();
					if (maskFileBase64 && playgroundStore.playgroundResults.length > 0) {
						const newImageUrl = await sandboxAPIStore.eraseImageObject(imageUrl, maskFileBase64);
						if (newImageUrl) {
							playgroundStore.playgroundResults[0].images[0].url = newImageUrl;
						}
					}
				}
				break;

			case "reset_image":
				if (sandboxAPIStore.uploadImageSrc) {
					playgroundStore.playgroundResults[0].images[0].url = sandboxAPIStore.uploadImageSrc;
				}
		}
	};

	return (
		<Box className={styles.sandboxConfig}>
			<Box className={styles.config}>
				<APIFamilyDropdown />
				<Divider />
				{sandboxAPIStore.config.apiConfig?.parameters?.map((field: any) => {
					if (field.type === "imageUpload") return null;
					if (field.type === "button") {
						return (
							!field.hidden && (
								<InputLayout
									label={field.label}
									className={styles[field.className]}
									labelClassName={styles.inputLabel}
									info={field.info}
								>
									{sandboxAPIStore.isGeneratingResults ? (
										<BriaButton
											buttonType={field.buttonType}
											onClick={() => sandboxAPIStore.abortResultsGeneration()}
										>
											{t("stop")}
										</BriaButton>
									) : (
										<BriaButton
											buttonType={field.buttonType}
											onClick={() => {
												generateResults(field.action);
											}}
											disabled={isDisabled()}
										>
											{field.name}
										</BriaButton>
									)}
								</InputLayout>
							)
						);
					}

					const inputProps = {
						...field,
						value: payload[field.name],
						onChange: (e: ChangeEvent<HTMLInputElement>) => {
							handleInputChange(field, e);
						},
						...(field.type === "color" && { onColorChange: handleColorChange }),
						...(field.type === "intArray" && {
							onIntArrayChange: (index: number, value: number | null) =>
								handleIntArrayChange(field, index, value),
						}),
						...(field.type === "buttonsGroup" && {
							onButtonClick: (fieldName: string) => {
								handleButtonClick(fieldName);
							},
						}),
					};
					return <GenericInput key={field.name} {...inputProps} />;
				})}
			</Box>

			{!sandboxAPIStore.config.apiConfig?.parameters?.some((field: any) => field.type === "button") && (
				<>
					{sandboxAPIStore.isGeneratingResults ? (
						<BriaButton
							className={styles.stopButton}
							buttonType="primaryMedium"
							fullWidth
							onClick={() => sandboxAPIStore.abortResultsGeneration()}
						>
							{t("stop")}
						</BriaButton>
					) : (
						sandboxAPIStore.config.selectedSubAPI &&
						!isCanvasRequiredForThisApp() && (
							<BriaButton
								onClick={() => {
									generateResults();
								}}
								className={clsx({
									[styles.button]: true,
									[styles.bottomGenerate]: true,
								})}
								disabled={isDisabled()}
								buttonType="primaryMedium"
							>
								{t("generate")}
							</BriaButton>
						)
					)}
				</>
			)}
		</Box>
	);
};

export default observer(SandboxAPIConfig);
