import imageCompression from "browser-image-compression";
import { saveAs } from "file-saver";
import { makeAutoObservable, runInAction } from "mobx";
import { ChangeEvent } from "react";
import { v4 as uuidv4 } from "uuid";
import importedConfigs from "../../../APIConfigs/APIConfigs.json";
import { IMAGE_COMPRESSION_CONFIG } from "../../../config/image.ts";
import { APPS, SANDBOX_API_BASE_URL } from "../../../constants/AppsConstants.ts";
import { DEFAULT_BRUSH } from "../../../constants/ImageToImageConstants.ts";
import { IRootStore } from "../../../mobx/root-store.tsx";
import { PlaygroundImage, PlaygroundResult } from "../../../models/image-to-image.ts";
import {
	ApiRequestData,
	BrushCanvasRefs,
	IBrushSettings,
	ImageModificationsSubAPIType,
	SandboxAPIConfigType,
	SandboxAPIType,
	sandboxResultImage,
} from "../../../models/sandboxAPI.ts";
import QueryService from "../../../utils/QueryService.ts";
import { getSafeFileName } from "../../../utils/index.ts";
import useImageUtils from "../../../utils/useImageUtils";
import useErrorToast from "../../../hooks/useErrorToast.tsx";

const defaultConfig: SandboxAPIConfigType = {
	original_image: { id: "", url: "" },
	selectedAPI: SandboxAPIType.ECommerceSuite,
	selectedSubAPI: undefined,
	apiConfig: null,
	num_results: 1,
};

export interface ISandboxAPIStore {
	config: SandboxAPIConfigType;
	errorMessage: string | null;
	isUploadingImage: boolean;
	isGeneratingResults: boolean;
	MAX_FILES_LIMIT: number;
	MAX_FILE_SIZE: number;
	uploadImageSrc?: string;
	hasImageUpload?: boolean;
	apiRequestData?: ApiRequestData;
	isConsole?: boolean;
	JsonConfigs: any;
	generateResults: (payload?: string, action?: string) => Promise<void>;
	handleAPIConfigChange: <K extends keyof SandboxAPIConfigType>(key: K, value: SandboxAPIConfigType[K]) => void;
	clearConfig: () => void;
	handleUploadImages: (e: ChangeEvent<HTMLInputElement>) => Promise<void>;
	handleUploadDelete: () => Promise<void>;
	abortResultsGeneration: () => void;
	brushCanvasRefs: BrushCanvasRefs[];
	brushConfigs: IBrushSettings;
	isLoading: boolean;

	eraseImageObject(imageUrl: string, maskFileBase64: string): Promise<string>;
	setProperty<K extends keyof SandboxAPIStore>(key: K, value: SandboxAPIStore[K]): void;
}

export default class SandboxAPIStore implements ISandboxAPIStore {
	private eCommerceSuiteQueryService: QueryService = new QueryService("/ecommerce-suite");
	private imageModificationsService: QueryService = new QueryService("/image_modifications");
	private fileQueryService: QueryService = new QueryService("/upload-image");
	private abortController: AbortController = new AbortController();
	private imageUtils = useImageUtils();
	rootStore: IRootStore;
	config: SandboxAPIConfigType = defaultConfig;
	errorMessage: string | null = null;
	isUploadingImage: boolean = false;
	uploadImageSrc?: string = "";
	hasImageUpload: boolean = false;
	MAX_FILE_SIZE = 12 * 1024 * 1024;
	MAX_FILES_LIMIT = 1;
	apiRequestData?: ApiRequestData = undefined;
	isConsole: boolean = false;
	isGeneratingResults: boolean = false;
	JsonConfigs: any = importedConfigs;
	brushCanvasRefs = [];
	brushConfigs = {
		globalCompositeOperation: DEFAULT_BRUSH.globalCompositeOperation,
		strokeStyle: DEFAULT_BRUSH.strokeStyle,
		lineWidth: DEFAULT_BRUSH.lineWidth,
		brushColorOpacity: DEFAULT_BRUSH.brushColorOpacity,
		lineJoin: DEFAULT_BRUSH.lineJoin,
		lineCap: DEFAULT_BRUSH.lineCap,
		globalAlpha: DEFAULT_BRUSH.globalAlpha,
	};
	isLoading: boolean = false;

	errorToast = useErrorToast();

	constructor(rootStore: IRootStore) {
		makeAutoObservable(this);
		this.rootStore = rootStore;
	}

	setProperty<K extends keyof SandboxAPIStore>(key: K, value: SandboxAPIStore[K]): void {
		runInAction(() => {
			(this as any)[key] = value;
		});
	}

	handleAPIConfigChange = <K extends keyof SandboxAPIConfigType>(key: K, value: SandboxAPIConfigType[K]) => {
		runInAction(async () => {
			this.config[key] = value;
			if (this.config.selectedSubAPI) {
				this.config.apiConfig = (this.JsonConfigs as any)[this.config.selectedAPI]?.[
					this.config.selectedSubAPI
				];
			} else {
				this.config.apiConfig = null;
			}

			this.hasImageUpload = !!this.config.apiConfig?.parameters?.filter((field: any) => {
				return field.type === "imageUpload";
			}).length;
		});
	};

	removeNotLoadedImages = () => {
		const { playgroundStore } = this.rootStore;
		playgroundStore.playgroundResults = playgroundStore.playgroundResults
			.map((item) => {
				const hasNonLoadingImages = item?.images.some((image) => !image.loading);

				if (hasNonLoadingImages) {
					return item;
				}

				return null;
			})
			.filter((item): item is PlaygroundResult => item !== null);
	};

	abortResultsGeneration = () => {
		if (this.abortController) {
			this.abortController.abort();
			this.removeNotLoadedImages();
		}
	};

	clearConfig = () => {
		this.config = defaultConfig;
	};

	extractFileName = (url: string): string => {
		const cleanUrl = url.split("?")[0];
		const parts = cleanUrl.split("/");
		const fileWithExtension = parts.pop() || "";
		const lastDotIndex = fileWithExtension.lastIndexOf(".");
		const fileName = lastDotIndex !== -1 ? fileWithExtension.substring(0, lastDotIndex) : fileWithExtension;
		return fileName || "file";
	};

	downloadZipFile = async (url: string, fileName: string) => {
		const blob = await this.imageUtils.getBlob(url);
		saveAs(blob, `${getSafeFileName(fileName)}.zip`);
	};

	generateResults = async (payload?: string, action?: string): Promise<void> => {
		this.errorMessage = null;
		const { playgroundStore } = this.rootStore;
		const savedIndex = playgroundStore.playgroundResults.length;
		this.abortController = new AbortController();
		try {
			const apiConfig = this.config.apiConfig;
			if (!apiConfig) {
				throw new Error("No API Selected!");
			}
			runInAction(() => {
				this.isGeneratingResults = true;
			});

			let josnPayload: Record<string, any> = {};
			if (payload) {
				josnPayload = JSON.parse(payload);
			}
			const num_results = josnPayload?.num_results as number;

			if (!action || action === "generate") {
				const resultsSkeletons: PlaygroundResult = {
					id: uuidv4(),
					config: { ...this.config },
					type: APPS.SANDBOX_API,
					images: Array.from({ length: num_results ? num_results : this.config.num_results! }).map((_) => ({
						id: "",
						url: "",
						loading: true,
					})),
				};
				playgroundStore.playgroundResults = [...playgroundStore.playgroundResults, resultsSkeletons];
			}

			if (this.hasImageUpload) {
				if (!playgroundStore.getSelectedImages()?.[0]?.url) {
					throw new Error("No image uploaded or selected");
				}
				josnPayload["image_url"] = playgroundStore.getSelectedImages()?.[0]?.url;
			}

			const original_image = {
				...playgroundStore.getSelectedImages()?.[0],
				url: playgroundStore.getSelectedImages()?.[0]?.url || "",
			};

			// TODO: remove the registering images related code once all the APIs isolated versions are ready
			if (apiConfig.nonIsolated) {
				const selectedImage = playgroundStore.getSelectedImages()?.[0];
				if (selectedImage.vhash) {
					josnPayload["visual_id"] = selectedImage.vhash;
				} else {
					josnPayload["visual_id"] = await this.fileQueryService.post("/url", {
						image_url: josnPayload["image_url"],
					});
					selectedImage.vhash = josnPayload["visual_id"];
				}
			}

			let results: any = [];
			switch (this.config.selectedAPI) {
				case SandboxAPIType.ECommerceSuite:
					results = await this.eCommerceSuiteQueryService.post(apiConfig.endpoint, josnPayload, undefined, {
						signal: this.abortController.signal,
					});
					break;
				case SandboxAPIType.ImageModifications:
					results = await this.imageModificationsService.post(apiConfig.endpoint, josnPayload, undefined, {
						signal: this.abortController.signal,
					});
					break;
			}

			runInAction(() => {
				switch (action) {
					case "delayerImage": {
						const psdURL = results?.psd_url as string;
						this.imageUtils.downloadImageAsPsd(psdURL, this.extractFileName(psdURL));
						break;
					}
					case "getMasks": {
						const zipURL = results?.objects_masks as string;
						this.downloadZipFile(zipURL, this.extractFileName(zipURL));
						break;
					}
					default: {
						results.forEach((resultImage: sandboxResultImage, index: number) => {
							if(!resultImage.result_url) {
								throw new Error(resultImage?.description || "Something went wrong! No images generated.");
							}

							const generatedImage: PlaygroundImage = {
								id: uuidv4(),
								url: resultImage.result_url,
								seed: resultImage?.seed,
							};

							playgroundStore.playgroundResults[savedIndex].images[index] = {
								...generatedImage,
								id: uuidv4(),
								loading: true,
								type: APPS.SANDBOX_API,
								config: {
									...(playgroundStore.playgroundResults[savedIndex].config as SandboxAPIConfigType),
									original_image,
								},
							};
						});
						// API request data for the code view modal
						playgroundStore.playgroundResults[savedIndex].apiRequestData = {
							url: SANDBOX_API_BASE_URL + apiConfig.endpoint,
							method: apiConfig.method,
							headers: [
								{
									name: "Content-Type",
									value: apiConfig.contentType || "application/json",
								},
								{
									name: "api_token",
									value: "*****************",
								},
							],
							postData: {
								mimeType: apiConfig.contentType || "application/json",
								text: JSON.stringify(josnPayload, null, 2),
							},
						};
					}
				}
			});
		} catch (e: unknown) {
			runInAction(() => {
				playgroundStore.playgroundResults = playgroundStore.playgroundResults.filter(
					(_, index) => index !== savedIndex,
				);
				if (e instanceof Error) {
					this.errorMessage = `Error : ${e.message || e.toString()}`;
					this.errorToast.showError(this.errorMessage);
					throw new Error(`Error generating results: ${this.errorMessage}`);
				}
			});
		} finally {
			runInAction(() => {
				this.isGeneratingResults = false;
			});
		}
	};

	handleUploadImages = async (e: React.ChangeEvent<HTMLInputElement>) => {
		runInAction(() => {
			this.errorMessage = null;
			this.isUploadingImage = true;
			this.isGeneratingResults = true;
		});
		const file: File | null = e.target.files && e.target.files[0];
		if (!file) {
			throw new Error("No file selected");
		}
		e.target.value = "";

		const { playgroundStore } = this.rootStore;
		const savedIndex = playgroundStore.playgroundResults.length;
		this.abortController = new AbortController();

		try {
			const compressedBlob = await imageCompression(file, IMAGE_COMPRESSION_CONFIG);
			const compressedFile = new File([compressedBlob], file.name, { type: compressedBlob.type });
			const formData = new FormData();
			formData.append("file", compressedFile, file.name);

			const resultsSkeletons: PlaygroundResult = {
				id: uuidv4(),
				config: { ...this.config },
				type: APPS.SANDBOX_API,
				isFromUpload: true,
				images: Array.from({ length: this.config.num_results! }).map((_) => ({
					id: "",
					url: "",
					loading: true,
				})),
			};

			if (
				this.config.selectedAPI === SandboxAPIType.ImageModifications &&
				this.config.selectedSubAPI === ImageModificationsSubAPIType.Eraser
			) {
				if (playgroundStore.playgroundResults.length > 0) {
					playgroundStore.playgroundResults = [];
				}
			}

			const savedIndex = playgroundStore.playgroundResults.length;
			playgroundStore.playgroundResults = [...playgroundStore.playgroundResults, resultsSkeletons];

			const imageUrl = await this.fileQueryService.post(
				"/s3/temp_file",
				formData,
				{ "Content-Type": "multipart/form-data" },
				{ signal: this.abortController.signal },
			);

			runInAction(() => {
				const generatedImage: PlaygroundImage = {
					id: uuidv4(),
					url: imageUrl,
				};

				// images[0] because we upload one image right now - will be changed later to loop over all images if we need to support multi uploads
				playgroundStore.playgroundResults[savedIndex].images[0] = {
					...generatedImage,
					id: uuidv4(),
					loading: true,
					config: playgroundStore.playgroundResults[savedIndex].config as SandboxAPIConfigType,
					type: APPS.SANDBOX_API,
				};

				this.uploadImageSrc = imageUrl;
				playgroundStore.selectImages(playgroundStore.playgroundResults[savedIndex].images);
			});
		} catch (e: unknown) {
			runInAction(() => {
				playgroundStore.playgroundResults = playgroundStore.playgroundResults.filter(
					(_, index) => index !== savedIndex,
				);
				if (e instanceof Error) {
					this.errorMessage = `Error can't upload image: ${e.message || e.toString()}`;
					throw new Error(this.errorMessage);
				}
			});
		} finally {
			runInAction(() => {
				this.isUploadingImage = false;
				this.isGeneratingResults = false;
			});
		}
	};

	eraseImageObject = async (imageUrl: string, maskFileBase64: string): Promise<string> => {
		try {
			this.isLoading = true;
			const formData = new FormData();
			formData.append("imageUrl", imageUrl);
			formData.append("maskFile", maskFileBase64);
			const newImageUrl = await this.imageModificationsService.post("/erase", formData, {
				"Content-Type": "multipart/form-data",
			});
			return newImageUrl;
		} catch (e: any) {
			runInAction(() => {
				this.isLoading = false;
			});
			return Promise.reject(e);
		}
	};

	handleUploadDelete = async () => {
		runInAction(() => {
			this.uploadImageSrc = undefined;
			this.isUploadingImage = false;
		});
	};
}
