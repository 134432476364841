export const isValidEmail = (email: string) => {
	const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
	return emailRegex.test(email);
};

export const hasNonEmptyValues = (obj: any): boolean =>
	Object.values(obj).some((value) =>
		value !== null && typeof value === "object" ? hasNonEmptyValues(value) : !!value,
	);

export type ruleSetType = {
	required?: boolean;
	min?: number;
	max?: number;
	custom?: string;
	customErrorMessage?: string;
}

// This function will be updated later to add custom validations as needed
const customValidations: Record<string, (value: any) => boolean> = {
	isEven: (value: number) => value % 2 === 0, // Predefined custom validations
};

export const validateField = (ruleSet: ruleSetType, value: unknown): string | null => {
	if (ruleSet.required && (value === null || value === undefined || value === "")) {
		return "This field is required.";
	}

	if (typeof value === "number" && ruleSet.min !== undefined && value < ruleSet.min) {
		return `Value must be at least ${ruleSet.min}.`;
	}

	if (typeof value === "number" && ruleSet.max !== undefined && value > ruleSet.max) {
		return `Value must be no more than ${ruleSet.max}.`;
	}

	if (ruleSet.custom && customValidations[ruleSet.custom] && !customValidations[ruleSet.custom](value)) {
		return ruleSet.customErrorMessage || "Invalid value.";
	}

	return null; // No Errors
};
