import { Box, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect, useRef } from "react";
import CloseIcon from "../../../assets/images/icons/CloseIcon";
import BriaIconButton from "../../../components/common/BriaIconButton/BriaIconButton";
import { SubMenuPanelProps } from "../../../models/expandable-menu";
import styles from "./SubMenuPanel.module.scss";

const SubMenuPanel = ({ children, onBackButtonClick, title }: SubMenuPanelProps) => {
	const panelRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			const target = event.target as Node;

			if (panelRef.current && !panelRef.current.contains(target)) {
				if (target instanceof HTMLElement && target.classList.contains("generate-image-to-image")) {
					onBackButtonClick?.();
				}
			}
		};

		document.addEventListener("mousedown", handleClickOutside);

		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [onBackButtonClick]);

	return (
		<Box ref={panelRef}>
			<Box className={styles.subMenuHeader}>
				<Typography className={styles.subMenuTitle}>{title}</Typography>
				<BriaIconButton onClick={onBackButtonClick}>
					<CloseIcon />
				</BriaIconButton>
			</Box>
			<Box>{children}</Box>
		</Box>
	);
};

export default observer(SubMenuPanel);
