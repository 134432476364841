import { ChevronRight } from "@mui/icons-material";
import { Box, CircularProgress, Typography } from "@mui/material";
import clsx from "clsx";
import { FC } from "react";
import DeleteIcon from "../../../../../../../../assets/images/icons/DeleteIcon";
import { EditIcon } from "../../../../../../../../assets/images/icons/EditIcon";
import BriaIconButton from "../../../../../../../../components/common/BriaIconButton/BriaIconButton";
import { useAppStore } from "../../../../../../../../hooks/useStores";
import { sortById } from "../../../../../../../../utils/index.ts";
import useImageUtils from "../../../../../../../../utils/useImageUtils";
import { BrandDefinition } from "../../../../../../../models/brandDefinition";
import styles from "./BrandItem.module.scss";

interface BrandItemProps {
	isAdminMode: boolean | undefined;
	brand: BrandDefinition;
	key: number;
	onClick: (brand: BrandDefinition) => void;
	selectedBrand: BrandDefinition | null;
	handleShowBrand: () => void;
	loadBrands: () => void;
	setIsViewIconClicked: (value: boolean) => void;
	isViewIconClicked: boolean;
	setActiveBrand: (brand: BrandDefinition) => void;
	activeBrand: BrandDefinition | null;
}

const BrandItem: FC<BrandItemProps> = ({
	isAdminMode,
	brand,
	key,
	onClick,
	selectedBrand,
	handleShowBrand,
	loadBrands,
	setIsViewIconClicked,
	isViewIconClicked,
	setActiveBrand,
	activeBrand,
}) => {
	const { isS3Uri, convertS3UriToHttpsCloudFrontUrl } = useImageUtils();
	const { brandsDefinitionStore } = useAppStore();
	const brandImage = sortById(brand?.logos).find((logo) => logo.src)?.src ?? "";
	const src = isS3Uri(brandImage) ? convertS3UriToHttpsCloudFrontUrl(brandImage) : brandImage;

	const handleDeleteBrand = async () => {
		await brandsDefinitionStore.handleDeleteBrand(brand);
		loadBrands();
	};
	return (
		<Box
			className={clsx(styles.itemWrapper, {
				[styles.selectedBrand]:
					(selectedBrand && brand.id === selectedBrand.id && !isViewIconClicked) ||
					brand.id === activeBrand?.id,
				[styles.disabled]:
					brandsDefinitionStore.isApplyingBrandConfigurations ||
					brandsDefinitionStore.isResettingBrandConfigurations,
			})}
			key={key}
			onClick={() => {
				setIsViewIconClicked(false);
				onClick(brand);
				setActiveBrand(brand);
			}}
		>
			<Box className={styles.content}>
				<Box className={styles.logo}>
					<img src={src} />
				</Box>
				<Typography>{brand.name}</Typography>
			</Box>

			{selectedBrand && brand.id === selectedBrand.id && brandsDefinitionStore.isApplyingBrandConfigurations && (
				<>
					<Box className={styles.blurLayer} />
					<CircularProgress size={24} className={styles.loader} />
				</>
			)}

			<Box className={styles.actions}>
				{isAdminMode && (
					<BriaIconButton className={styles.showBrandBtn} onClick={handleDeleteBrand}>
						<DeleteIcon className={styles.deleteIcon} />
					</BriaIconButton>
				)}
				<BriaIconButton
					onClick={(event?: React.MouseEvent<HTMLButtonElement>) => {
						event && event.stopPropagation();
						setIsViewIconClicked(true);
						handleShowBrand();
						onClick(brand);
						brandsDefinitionStore.formErrors.invalidName = false;
					}}
					className={styles.showBrandBtn}
				>
					{isAdminMode ? (
						<EditIcon className={styles.editIcon} />
					) : (
						<ChevronRight className={styles.arrowIcon} />
					)}
				</BriaIconButton>
			</Box>
		</Box>
	);
};

export default BrandItem;
