import { Box } from "@mui/material";
import clsx from "clsx";
import { groupBy } from "lodash";
import { observer } from "mobx-react-lite";
import { useContext, useEffect } from "react";
import { useAppStore } from "../../../../../../hooks/useStores";
import { PanelType } from "../../../../../constants/app-options";
import { DesignEditorContext } from "../../../../../contexts/DesignEditor.tsx";
import { useActiveObject } from "../../../../../hooks/useActiveObject";
import useAppContext from "../../../../../hooks/useAppContext";
import useSetIsSidebarOpen from "../../../../../hooks/useSetIsSidebarOpen.tsx";
import EditAllAdsSwitch from "../../Panels/panelItems/Common/EditAllAdsSwitch/EditAllAdsSwitch";
import BrandSettings from "../BrandSettings/BrandSettings.tsx";
import styles from "./Vector.module.scss";

const Vector = () => {
	const { campaignStore } = useAppStore();
	const activeObject = useActiveObject() as any;
	const { setActivePanel } = useAppContext();
	const setIsSidebarOpen = useSetIsSidebarOpen();
	const { isPopupView } = useContext(DesignEditorContext);

	useEffect(() => {
		if (activeObject && activeObject.type === "StaticVector") {
			const objects = activeObject._objects[0]._objects;
			const objectColors = groupBy(objects, "fill");
			campaignStore.vectorColors = {
				...campaignStore.vectorColors,
				colors: Object.keys(objectColors),
				colorMap: activeObject.colorMap,
			};
		}
	}, [activeObject]);

	return (
		<Box
			className={clsx({
				[styles.container]: true,
				[styles.hide]: isPopupView,
			})}
		>
			<Box className={styles.vectorToolBar}>
				<Box>
					<Box className={styles.leftToolBarSide}>
						<Box className={styles.vectorColors}>
							{Object.keys(campaignStore.vectorColors.colorMap).map((c, _index) => {
								return (
									<Box
										style={{
											backgroundColor: campaignStore.vectorColors.colorMap[c],
											background: campaignStore.vectorColors.colorMap[c],
											opacity: activeObject?.opacity ?? 1,
										}}
										onClick={() => {
											setIsSidebarOpen(true);
											setActivePanel(PanelType.SHAPEFILLCOLOR);
											campaignStore.prevColor = c;
										}}
										className={styles.colorBox}
									></Box>
								);
							})}
						</Box>
					</Box>
				</Box>
				<EditAllAdsSwitch />
			</Box>
			<BrandSettings activeObject={activeObject} showBrandColorDropdown={true} />
		</Box>
	);
};

const ObservedComponent = observer(Vector);
export default ObservedComponent;
