import { Box, Divider, SelectChangeEvent, TextField } from "@mui/material";
import i18n from "i18next";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BriaDropDown, { DropDownItem } from "../../../../components/common/BriaDropDown/BriaDropDown.tsx";
import StaticDropdown, { AspectRatio } from "../../../../components/common/DropDowns/StaticDropdown.tsx";
import InputLayout from "../../../../layout/InputLayout/InputLayout.tsx";
import { IframePages } from "../../../../models/new-iframe.ts";
import iframeStore from "../../iframe-store.tsx";
import IframeAvailablePages from "./Checkboxes/IframeAvailablePages.tsx";
import IframeControls from "./Checkboxes/IframeControls.tsx";
import IframeGenModels from "./Dropdowns/IframeGenModels.tsx";
import IframeTgModels from "./Dropdowns/IframeTgModels.tsx";
import styles from "./NewIframeForm.module.scss";
import IframeCustomStyle from "./Style/IframeCustomStyle.tsx";

const NewIframeForm = () => {
	const { iframeForm, handleFormChange } = iframeStore;
	const { t } = useTranslation("translation", { keyPrefix: "newIframes.editor.form" });
	const [landingPageOptions, setLandingPageOptions] = useState<DropDownItem[]>([]);

	useEffect(() => {
		if (iframeForm.config.landing_page) {
			handleFormChange("config", {
				...iframeForm.config,
				landing_page: iframeForm.config.enabled_pages[iframeForm.config.landing_page]
					? iframeForm.config.landing_page
					: ("" as IframePages),
			});
		}
		setLandingPageOptions(
			Object.entries(iframeForm.config.enabled_pages)
				.filter(([, isEnabled]) => isEnabled)
				.map(([page]) => ({
					key: i18n.t(`staticDropdowns.appPages.${page}`),
					value: page,
				})),
		);
	}, [iframeForm.config.enabled_pages]);

	const handleStartingPageChange = (e: SelectChangeEvent<IframePages>) => {
		handleFormChange("config", { ...iframeForm.config, landing_page: e.target.value as IframePages });
	};

	const handleGenAspectRatioChange = (e: SelectChangeEvent<AspectRatio>) => {
		handleFormChange("config", {
			...iframeForm.config,
			gen_config: { ...iframeForm.config.gen_config, default_aspect_ratio: e.target.value as AspectRatio },
		});
	};

	return (
		<Box className={styles.container}>
			<InputLayout label={t("name.label")}>
				<TextField
					value={iframeForm.name}
					onChange={(e) => handleFormChange("name", e.target.value)}
					placeholder={t("name.placeholder")}
					fullWidth
					InputProps={{ classes: { root: styles.textField } }}
				/>
			</InputLayout>
			<InputLayout label={t("description.label")}>
				<TextField
					value={iframeForm.description}
					onChange={(e) => handleFormChange("description", e.target.value)}
					placeholder={t("description.placeholder")}
					fullWidth
					multiline
					minRows={3}
					InputProps={{ classes: { root: styles.bigTextField } }}
				/>
			</InputLayout>
			<InputLayout label={t("availablePages.label")}>
				<IframeAvailablePages />
			</InputLayout>
			<InputLayout label={t("startingPage.label")}>
				<BriaDropDown
					value={iframeForm?.config.landing_page}
					onChange={handleStartingPageChange}
					placeholder={t("startingPage.placeholder")}
					items={landingPageOptions}
				/>
			</InputLayout>
			<InputLayout label={t("genModels.label")}>
				<IframeGenModels />
			</InputLayout>
			<InputLayout label={t("tgModels.label")}>
				<IframeTgModels />
			</InputLayout>
			<InputLayout label={t("defaultGenAspectRatio.label")}>
				<StaticDropdown<AspectRatio>
					type="aspectRatio"
					value={iframeForm?.config.gen_config?.default_aspect_ratio}
					onChange={handleGenAspectRatioChange}
					placeholder={t("defaultGenAspectRatio.placeholder")}
					className={styles.dropDown}
					width={"-webkit-fill-available"}
				/>
			</InputLayout>
			<Divider className={styles.divider} />
			<InputLayout label={t("customStyle.label")}>
				<IframeCustomStyle />
			</InputLayout>
			<InputLayout label={t("controls.label")}>
				<IframeControls />
			</InputLayout>
		</Box>
	);
};

const ObservedComponent = observer(NewIframeForm);
export default ObservedComponent;
