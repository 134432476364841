import { Box } from "@mui/material";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import BriaInput from "../../../../../../components/common/BriaInput/BriaInput";
import BriaSlider from "../../../../../../components/common/BriaSlider/BriaSlider";
import { IGroupOption } from "../../../../../../components/common/CustomSingleSelectDropdown/ٍSingleSelectGroupedDropDown";
import TailoredModelsDropdown from "../../../../../../components/common/TailoredModelsDropdown/TailoredModelsDropdown";
import { useImageToImageConfig } from "../../../../../../hooks/useImageToImageConfig";
import { useAppStore } from "../../../../../../hooks/useStores";
import { OrgFeatures } from "../../../../../../models/billing";
import { ConfigValue } from "../../../../../../models/image-to-image";
import { isBriaMember } from "../../../../../../utils";
import Config from "../../Config/Config";
import styles from "./ImageReference.module.scss";

const ImageReference = () => {
	const { t } = useTranslation("translation", {
		keyPrefix: "playground.imageToImage.config.features.imageReference",
	});
	const { imageToImageStore, authStore } = useAppStore();
	const { updateSelectedImageReferenceConfigs } = useImageToImageConfig();

	const requestAccess = !(
		authStore.isFeatureEnabled(OrgFeatures.TAILORED_GENERATION) || isBriaMember(authStore.user)
	);

	const handleModelChange = (e: React.ChangeEvent<HTMLSelectElement>, selected: IGroupOption) => {
		imageToImageStore.handleConfigChange("image_reference", {
			...imageToImageStore.config.image_reference,
			tailored_model_id: selected.extraData.model_id,
			model_id: e.target.value,
		});
		updateSelectedImageReferenceConfigs();
	};

	const handleConfigChange = (field: string, value: ConfigValue) => {
		imageToImageStore.handleConfigChange("image_reference", {
			...imageToImageStore.config.image_reference,
			[field]: value,
		});
		updateSelectedImageReferenceConfigs();
	};

	return (
		<Box className={styles.ImageReferenceContainer}>
			<Config title={t("prompt.label")} onClick={() => {}} isActive={true} titleOnly={true}>
				<Box className={styles.modelsDropdown}>
					<BriaInput
						value={imageToImageStore.config.image_reference?.prompt}
						placeholder={t("prompt.placeholder")}
						minRows={2}
						multiline
						onChange={(e) => handleConfigChange("prompt", e.target.value)}
					/>

					<BriaSlider
						value={imageToImageStore.config.image_reference?.structure_ref_influence}
						onChange={(_, value) => handleConfigChange("structure_ref_influence", value as number)}
						step={0.05}
						min={0}
						max={1}
						marks={[
							{ value: 0, label: t("looseInfluence") },
							{ value: 1, label: t("strictInfluence") },
						]}
						valueLabelDisplay="auto"
						labelsAlwaysGray
					/>
				</Box>
			</Config>
			<Config title={t("models.label")} onClick={() => {}} isActive={true} titleOnly={true}>
				<Box className={styles.modelsDropdown}>
					<TailoredModelsDropdown
						selectedValue={imageToImageStore.config.image_reference?.model_id}
						onChange={handleModelChange}
						placeholder={t("models.placeholder")}
					/>
					<Box
						className={clsx({
							[styles.requestAccess]: requestAccess,
						})}
					>
						<BriaSlider
							value={imageToImageStore.config.image_reference?.tailored_model_influence}
							onChange={(_, value) => handleConfigChange("tailored_model_influence", value as number)}
							step={0.05}
							min={0}
							max={1}
							marks={[
								{ value: 0, label: t("looseInfluence") },
								{ value: 1, label: t("strictInfluence") },
							]}
							valueLabelDisplay="auto"
							labelsAlwaysGray
						/>
					</Box>
				</Box>
			</Config>

			<Config title={t("numOfResults")} onClick={() => {}} isActive={true} titleOnly={true}>
				<Box className={styles.modelsDropdown}>
					<BriaSlider
						value={imageToImageStore.config.image_reference?.num_results}
						onChange={(_, value) => handleConfigChange("num_results", value as number)}
						min={1}
						step={1}
						max={4}
						marks={Array.from({ length: 4 }, (_, index) => ({
							value: index + 1,
							label: `${index + 1}`,
						}))}
						valueLabelDisplay="auto"
					/>
				</Box>
			</Config>
		</Box>
	);
};

export default observer(ImageReference);
