import { Box, Divider, Typography } from "@mui/material";
import { groupBy } from "lodash";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SolidGradientColorPicker from "../../../../../../../components/common/SolidGradientColorPicker/SolidGradientColorPicker";
import { useAppStore } from "../../../../../../../hooks/useStores";
import { CampaignEntityStatus } from "../../../../../../../models/common.ts";
import useDesignEditorUtils, { FeatureType } from "../../../../../../CustomUtils/UseDesignEditor";
import MainTabHeader from "../../../../../../components/common/MainTabHeader/MainTabHeader";
import { useActiveObject } from "../../../../../../hooks/useActiveObject";
import { useEditor } from "../../../../../../hooks/useEditor";
import { BrandDefinition } from "../../../../../../models/brandDefinition";
import { LayerType } from "../../../../../../types";
import styles from "./ShapeFillColor.module.scss";

const ShapeFillColor = () => {
	const { t } = useTranslation("translation", { keyPrefix: "editor.tabs" });
	const [brands, setBrands] = useState<BrandDefinition[]>([]);
	const { brandsDefinitionStore, campaignStore } = useAppStore();
	const activeObject = useActiveObject() as any;
	const [color, setColor] = useState("");
	const { EditAllAds, getRgbAndOpacityFromRgba, updateColorOpacity } = useDesignEditorUtils();
	const editor = useEditor();

	useEffect(() => {
		if (brandsDefinitionStore.brands.items.length == 0) {
			loadBrands();
		} else {
			setBrands(brandsDefinitionStore.brands.items.filter((item) => item.status === CampaignEntityStatus.ACTIVE));
		}
	}, []);

	useEffect(() => {
		if (activeObject && activeObject.type === LayerType.STATIC_VECTOR) {
			const objects = activeObject._objects[0]._objects;
			const objectColors = groupBy(objects, "fill");
			campaignStore.vectorColors = {
				...campaignStore.vectorColors,
				colors: Object.keys(objectColors),
				colorMap: activeObject.colorMap,
			};

			Object.keys(campaignStore.vectorColors.colorMap).map((color, _index) => {
				campaignStore.prevColor = color;
			});

			setColor(
				updateColorOpacity(
					campaignStore?.vectorColors?.colorMap[campaignStore?.prevColor],
					activeObject.opacity,
				),
			);
		}
	}, [activeObject]);

	const loadBrands = () => {
		brandsDefinitionStore.loadBrandsDefinition().then(() => {
			setBrands(brandsDefinitionStore.brands.items.filter((item) => item.status === CampaignEntityStatus.ACTIVE));
		});
	};

	const changeBackgroundColor = (prev: string, next: string, brandColor: boolean = false) => {
		const objectRef = activeObject;
		const result = getRgbAndOpacityFromRgba(next);
		if (objectRef) {
			objectRef?.updateLayerColor(prev, result?.rgb ?? next);
			campaignStore.vectorColors = {
				...campaignStore.vectorColors,
				colorMap: {
					...campaignStore.vectorColors.colorMap,
					[prev]: result?.rgb ?? next,
				},
			};
			editor?.objects.update(
				{
					opacity: brandColor ? undefined : result?.opacity ?? 1,
					brandId: undefined,
				},
				undefined,
				!campaignStore.isEditingAllAds,
			);
			EditAllAds(LayerType.STATIC_VECTOR, activeObject?.shapeType, FeatureType.COLOR, result?.rgb ?? next);
		}
	};

	return (
		<>
			<Box className={styles.container}>
				<MainTabHeader title={t("shapeFillColor")} />
				<Box className={`${styles.tabs} ${styles.fullHeight}`}>
					<Box className={styles.ColorFillContent}>
						<Box className={styles.colorPickerContainer}>
							<SolidGradientColorPicker
								color={color}
								onChange={(color: string) => {
									setColor(color);
									changeBackgroundColor(campaignStore.prevColor, color);
								}}
								hideColorTypeBtns={true}
							/>
						</Box>
						<Divider className={styles.mainDivider} />
						<Typography className={styles.mainLabel}>{t("brandColors")}</Typography>
						<Box className={styles.BrandsContainer}>
							{brands.map((brand: BrandDefinition, idx: number) => {
								const colors = brand?.color_pallete?.colors;
								return (
									<>
										<Box className={styles.brandColorsRow}>
											<Typography className={styles.brandNameLabel}>
												{brand.name.length < 10 ? brand.name : brand.name.slice(0, 10) + "..."}
											</Typography>

											{colors?.map((color: string, index: number) => (
												<Box
													onClick={() => {
														setColor(color);
														changeBackgroundColor(campaignStore.prevColor, color, true);
													}}
													key={index}
												>
													<Box className={styles.itemContent}>
														<Box
															sx={{ background: color }}
															className={styles.itemPreview}
														></Box>
													</Box>
												</Box>
											))}
										</Box>
										{idx != brands.length - 1 && <Divider className={styles.divider} />}
									</>
								);
							})}
						</Box>
					</Box>
				</Box>
			</Box>
		</>
	);
};

const ObservedComponent = observer(ShapeFillColor);
export default ObservedComponent;
