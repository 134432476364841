import { FormControlLabel, FormGroup } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import BriaCheckbox from "../../../../../components/common/BriaCheckbox/BriaCheckbox";
import { IframePages } from "../../../../../models/new-iframe";
import iframeStore from "../../../iframe-store.tsx";

const IframeAvailablePages = () => {
	const { iframeForm, handleFormChange } = iframeStore;
	const { t } = useTranslation("translation", { keyPrefix: "staticDropdowns.appPages" });

	const handlePageChange = (page: IframePages, checked: boolean) => {
		handleFormChange("config", {
			...iframeForm.config,
			enabled_pages: { ...iframeForm.config.enabled_pages, [page]: checked },
		});
	};

	return (
		<FormGroup>
			{Object.entries(iframeForm.organization.allowed_pages)
				.filter(([, isAllowed]) => isAllowed)
				.map(([page]) => (
					<FormControlLabel
						key={page}
						control={
							<BriaCheckbox
								checked={iframeForm.config.enabled_pages?.[page as IframePages]}
								onChange={(e) => handlePageChange(page as IframePages, e.target.checked)}
							/>
						}
						label={t(page)}
					/>
				))}
		</FormGroup>
	);
};

export default observer(IframeAvailablePages);
