import CircularProgress, { CircularProgressProps } from "@mui/material/CircularProgress";
import clsx from "clsx";
import React from "react";
import styles from "./LoadingPlaceholder.module.scss";

interface LoadingPlaceholderProps extends CircularProgressProps {
	isLoading?: boolean;
	children?: React.ReactNode;
	className?: string;
	alwaysRenderChildren?: boolean;
	size?: number | string;
}

const LoadingPlaceholder = ({
	isLoading = false,
	children,
	className,
	alwaysRenderChildren,
	size = 40,
	...props
}: LoadingPlaceholderProps) => {
	if (alwaysRenderChildren) {
		return (
			<>
				{isLoading && (
					<div className={className}>
						<CircularProgress {...props} className={styles.loadingPlaceholder} size={size} />
					</div>
				)}
				<div className={clsx(styles.childrenContainer, { [styles.hidden]: isLoading })}>{children}</div>
			</>
		);
	} else {
		return isLoading ? (
			<div className={className}>
				<CircularProgress {...props} className={styles.loadingPlaceholder} size={size} />
			</div>
		) : (
			children
		);
	}
};

export default LoadingPlaceholder;
