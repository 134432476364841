import { makeAutoObservable, runInAction } from "mobx";
import { Ref, useRef } from "react";
import Selecto, { ElementType } from "selecto";
import { v4 as uuidv4 } from "uuid";
import { SecondaryTabTypeEnum } from "../../DesignEditor/views/DesignEditor/components/Panels/panelItems/index.ts";
import { ANALYTICS_EVENTS } from "../../analytics-store.tsx";
import { AspectRatio } from "../../components/common/DropDowns/StaticDropdown.tsx";
import { ImageErrorType } from "../../components/common/Galleries/BriaImage.tsx";
import useSelectable from "../../components/common/Selectable/useSelectable.tsx";
import { getSummerHost } from "../../config/env.ts";
import { APPS } from "../../constants/AppsConstants.ts";
import useErrorToast from "../../hooks/useErrorToast";
import { IRootStore } from "../../mobx/root-store.tsx";
import { ImageToImageConfigType, PlaygroundImage, PlaygroundResult } from "../../models/image-to-image.ts";
import { TextToImageConfigType } from "../../models/text-to-image.ts";
import ImagesService from "../../services/ImagesService.ts";
import UploadImageService from "../../services/UploadImageService.ts";
import { isFoxApps } from "../../utils";
import useImageUtils from "../../utils/useImageUtils";
import { Feedback } from "./Images/Overlay/PlaygroundImageOverlay.tsx";

export interface IPlaygroundStore {
	selectedConfig: PlaygroundConfigs;
	playgroundResults: PlaygroundResult[];
	selectoRef?: Ref<Selecto>;
	loadingDownload: boolean;
	loadingSaveToMyImages: boolean;
	loadingSaveToMyImagesAndInsertImageToAd: boolean;
	isText2ImagePopupOpened: boolean;
	showSuccessToastSavedImage: boolean;
	textToGenerate: string;
	aspectRatio: AspectRatio;
	setTextToGenerate: (textToGenerate: string) => void;
	setSelectedAspectRatio: (aspectRatio: AspectRatio) => void;
	openModal: () => void;
	closeModal: () => void;
	saveMyImages: (action: SaveImagesLoadingState) => void;
	deleteResult: (id: string) => void;
	getSelecto: () => Selecto | null;
	clearResults: () => void;
	downloadImages: (images: PlaygroundImage[], zipName?: string) => Promise<void>;
	getAvailableImages: () => PlaygroundImage[];
	getSelectedImages: () => PlaygroundImage[];
	openSelected: () => void;
	handleSelectImagesElements: (imagesElements?: ElementType[], isPopupView?: boolean) => void;
	selectImages: (images: PlaygroundImage[]) => void;
	getFileName: (images: PlaygroundImage) => string;
	onSuccessResult: (image: PlaygroundImage) => Promise<void>;
	onErrorResult: (image: PlaygroundImage, errorType: ImageErrorType) => Promise<void>;
	loadingFeedback: boolean;
	feedbackHistory: Feedback[];
	addOrUpdateFeedback: (newFeedback: Feedback) => Promise<void>;
	clearSelectedImages: () => void;
	handleSelectImage: (image: PlaygroundImage) => void; // New method
	uploadedImagesURLs: string[];
	handleAppChange: (app: APPS) => void;
	onAiEditorButtonClick: (image: PlaygroundImage) => Promise<void>;
}

export default class PlaygroundStore implements IPlaygroundStore {
	private uploadImageService = new UploadImageService();
	rootStore: IRootStore;
	selectedConfig: PlaygroundConfigs = APPS.TEXT_TO_IMAGE;
	playgroundResults: PlaygroundResult[] = [];
	selectedImages: PlaygroundImage[] = [];
	selectoRef = useRef<Selecto>(null);
	loadingDownload: boolean = false;
	loadingSaveToMyImages: boolean = false;
	loadingSaveToMyImagesAndInsertImageToAd: boolean = false;
	private imageUtils = useImageUtils();
	errorToast = useErrorToast();
	isText2ImagePopupOpened: boolean = false;
	showSuccessToastSavedImage: boolean = false;
	loadingFeedback: boolean = false;
	feedbackHistory: Feedback[] = [];
	private imagesQueryService: ImagesService = new ImagesService();
	textToGenerate: string = "";
	aspectRatio: AspectRatio = "4:3";
	uploadedImagesURLs: string[] = [];

	constructor(rootStore: IRootStore) {
		makeAutoObservable(this);
		this.rootStore = rootStore;
	}

	setTextToGenerate = (textToGenerate: string): void => {
		this.textToGenerate = textToGenerate;
	};

	setSelectedAspectRatio = (aspectRatio: AspectRatio): void => {
		this.aspectRatio = aspectRatio;
	};
	handleAppChange = (app: APPS) => {
		runInAction(() => {
			this.selectedConfig = app;
		});
	};

	deleteResult = (id: string): void => {
		this.playgroundResults = this.playgroundResults.filter((result) => result.id !== id);
	};

	onSuccessResult = async (image: PlaygroundImage): Promise<void> => {
		const foundImage = this.rootStore.playgroundStore.playgroundResults
			.flatMap((result) => result.images)
			.find((searchSameImg) => searchSameImg.id === image.id);
		if (foundImage) {
			foundImage.loading = false;
			if (
				(foundImage.type === APPS.TEXT_TO_IMAGE ||
					foundImage.type === APPS.PRODUCT_PLACEMENT ||
					foundImage.type === "upload") &&
				!isFoxApps() &&
				(image.config as TextToImageConfigType)?.format !== "svg"
			) {
				foundImage.aiEditorButtonLoading = true;
				this.uploadImageService
					.getVhash(image.url)
					.then((vhash) => (foundImage.vhash = vhash))
					.finally(() => (foundImage.aiEditorButtonLoading = false));
			}
		}
	};

	openLabsAiEditor = (vhash?: string) => {
		vhash && window.open(`${getSummerHost()}/gallery/${vhash}?isGenerated=true`, "_blank");
	};

	onAiEditorButtonClick = async (image: PlaygroundImage): Promise<void> => {
		if (!isFoxApps()) {
			if (!image.vhash && !image.aiEditorButtonLoading) {
				image.aiEditorButtonLoading = true;
				this.uploadImageService
					.getVhash(image.url)
					.then((vhash) => {
						image.vhash = vhash;
						this.openLabsAiEditor(image.vhash);
					})
					.finally(() => (image.aiEditorButtonLoading = false));
			} else {
				this.openLabsAiEditor(image.vhash);
			}
		} else {
			this.rootStore.aiEditorStore.setProperty("selectedImageUrl", image.url);
			this.rootStore.aiEditorStore.setProperty("aiEditorPopup", true);
			if (image.type === APPS.IMAGE_TO_IMAGE) {
				this.rootStore.objectsStore.setProperty("refine", {
					...this.rootStore.objectsStore.refine,
					tailored_style: (image.config as ImageToImageConfigType).style?.tailored_style,
					sub_style: (image.config as ImageToImageConfigType).style?.sub_style,
					originalImage: (image.config as ImageToImageConfigType).original_image.url,
				});
			}
		}
	};

	onErrorResult = async (image: PlaygroundImage, errorType: ImageErrorType): Promise<void> => {
		const foundImage = this.rootStore.playgroundStore.playgroundResults
			.flatMap((result) => result.images)
			.find((searchSameImg) => searchSameImg.id === image.id);
		if (foundImage) {
			foundImage.loading = false;
			foundImage.errorType = errorType;
		}
	};

	downloadImages = async (images: PlaygroundImage[], zipName: string = "bria_gen") => {
		try {
			this.loadingDownload = true;
			await this.imageUtils.downloadZipImages(
				images.map((image) => ({ fileName: this.getFileName(image), imageUrl: image.url })),
				zipName,
			);
			images.forEach((image) => {
				if (image.type === "imageToImage") {
					this.rootStore.analyticsStore.logImageToImageEvent(ANALYTICS_EVENTS.IMAGE_DOWNLOAD_ITI, image);
				}
				if (image.type === "productPlacement") {
					this.rootStore.analyticsStore.logEvent(ANALYTICS_EVENTS.IMAGE_DOWNLOAD_PP);
				}
			});
			runInAction(() => {
				this.loadingDownload = false;
			});
		} catch (e: any) {
			this.loadingDownload = false;
			return Promise.reject(`Error downloading images zip: ${e.message || e.toString()}`);
		}
	};

	clearResults = () => {
		runInAction(() => {
			this.playgroundResults = [];
			this.selectedImages = [];
		});
	};

	getSelecto = () => this.selectoRef.current;

	handleSelectImagesElements = (imagesElements?: ElementType[], isPopupView: boolean = false) => {
		if (!isPopupView && this.rootStore.imagesStore.secondaryTabType !== SecondaryTabTypeEnum.SMART_IMAGE) {
			const selectedImagesIds: string[] | undefined = imagesElements?.map((el) =>
				JSON.parse(el.ariaValueText ?? "{}"),
			);
			this.playgroundResults.forEach((result) =>
				result.images.forEach((image) => {
					image.selected = selectedImagesIds?.some((id) => id === image.id);
				}),
			);
		} else {
			// Get the ID of the last selected image element, if any
			if (imagesElements && imagesElements.length > 0 && imagesElements[0] != undefined) {
				const lastSelectedImageId = imagesElements?.length
					? JSON.parse(imagesElements[imagesElements.length - 1]?.ariaValueText ?? "")
					: null;

				// Update the selection state for each last Image Id
				this.playgroundResults.forEach((result) => {
					result.images.forEach((image) => {
						// Set selected to true only if it matches the last selected image ID
						image.selected = image.id === lastSelectedImageId;
					});
				});
			} else {
				imagesElements = [];
				const selectedImagesIds: string[] | undefined = imagesElements.map((element) =>
					JSON.parse(element.ariaValueText ?? "{}"),
				);
				this.playgroundResults.forEach((result) =>
					result.images.forEach((image) => {
						image.selected = selectedImagesIds?.some((id) => id === image.id);
					}),
				);
			}
		}
	};

	selectImages = (images: PlaygroundImage[]) => {
		// Timeout to assure that playgroundResults HTML Elements are rendered before
		setTimeout(() => {
			const elementsToSelect: ElementType[] | undefined = this.getSelecto()
				?.getSelectableElements()
				?.filter((el) => images.some((image) => image.id === JSON.parse(el.ariaValueText ?? "{}")));
			this.handleSelectImagesElements(elementsToSelect);
			useSelectable(this.getSelecto()).selectElements(elementsToSelect);
		}, 1000);
	};

	getFileName = (image: PlaygroundImage): string => {
		if (image.type === "textToImage") {
			return (image.config as TextToImageConfigType)?.prompt?.substring(0, 80) + "_" + image.vhash;
		} else if (image.type === "upload") {
			const fileNameWithoutExtension = image?.file?.name?.split(".")?.slice(0, -1)?.join(".");
			return `${fileNameWithoutExtension}_${image.id}`;
		} else if (image.type === "imageToImage") {
			const originalImage: PlaygroundImage = (image.config as ImageToImageConfigType).original_image;
			if (originalImage.type === "upload") {
				const fileNameWithoutExtension = originalImage?.file?.name?.split(".")?.slice(0, -1)?.join(".");
				return `${fileNameWithoutExtension}_${image.id}`;
			}
			if (originalImage.type === "textToImage")
				return `${(originalImage.config as TextToImageConfigType).prompt?.substring(0, 80)}_${image.id}`;
			return `${image.id}-${image.style_props?.face_sd_edit}-${image.style_props?.face_seed}-${image.style_props?.body_seed}`;
		}
		return `${image.type}_result`;
	};

	getAvailableImages = (): PlaygroundImage[] =>
		this.playgroundResults.flatMap((results) =>
			results.images.filter((image) => !image.loading && !image.errorType),
		);

	getSelectedImages = (): PlaygroundImage[] =>
		this.playgroundResults.flatMap((results) => results.images.filter((image) => !image.loading && image.selected));

	openSelected = () => {
		const selectedImages = this.getSelectedImages();
		if (selectedImages.length) {
			const newImages: PlaygroundImage[] = selectedImages.map((image) => ({ ...image, id: uuidv4() }));
			this.playgroundResults = [...this.playgroundResults, { id: uuidv4(), images: newImages, type: "new" }];
			this.selectImages(newImages);
		}
	};

	saveMyImages = async (action: SaveImagesLoadingState) => {
		try {
			this[action] = true;
			const selectedImages = this.getSelectedImages();
			for (const selectedImage of selectedImages) {
				const res = await this.rootStore.imagesStore.uploadImageViaAPI(
					undefined,
					this.rootStore.imagesStore.uploadToUserGallery.toString(),
					selectedImage.url,
				);
				if (res?.imageUrl) {
					this.uploadedImagesURLs.push(res?.imageUrl);
				}
			}
			this.showSuccessToastSavedImage = true;
			this.rootStore.imagesStore.uploadProcessDone = true;
			this[action] = false;
		} catch (e: any) {
			this[action] = false;
			return Promise.reject(`Error saving images : ${e.message || e.toString()}`);
		}
	};
	openModal = () => (this.isText2ImagePopupOpened = true);
	closeModal = () => {
		this.isText2ImagePopupOpened = false;
		this.setTextToGenerate("");
		this.setSelectedAspectRatio("4:3");
		const showDesignEditorContainer =
			this.rootStore?.designEditorStore?.containerRef && this.rootStore.designEditorStore.containerRef?.current;
		if (showDesignEditorContainer) this.rootStore.uiStore.showDesignEditorContainer();
	};
	addOrUpdateFeedback = async (newFeedback: Feedback): Promise<void> => {
		try {
			runInAction(() => {
				this.loadingFeedback = true;
				const index = this.feedbackHistory.findIndex(
					(feedback: Feedback) => feedback.imageUrl === newFeedback.imageUrl,
				);
				if (index !== -1) {
					this.feedbackHistory[index] = newFeedback;
				} else {
					this.feedbackHistory.push(newFeedback);
				}
			});

			await this.imagesQueryService.sendFeedback(newFeedback);
			this.loadingFeedback = false;
		} catch (e) {
			this.loadingFeedback = false;
			return Promise.reject(e);
		}
	};

	clearSelectedImages = () => {
		runInAction(() => {
			this.selectedImages = [];
			this.playgroundResults.forEach((result) =>
				result.images.forEach((image) => {
					image.selected = false;
				}),
			);
		});
	};

	handleSelectImage = (image: PlaygroundImage) => {
		runInAction(() => {
			const existingImageIndex = this.selectedImages.findIndex((selectedImage) => selectedImage.id === image.id);

			const resultIndex = this.playgroundResults.findIndex((result) =>
				result.images.some((img) => img.id === image.id),
			);

			if (resultIndex !== 0) {
				if (existingImageIndex !== -1) {
					this.selectedImages.splice(existingImageIndex, 1);
				} else {
					if (this.selectedImages.length < 2) {
						this.selectedImages.push(image);
					}
				}

				this.playgroundResults.forEach((result) => {
					result.images.forEach((img) => {
						img.selected = this.selectedImages.some((selectedImage) => selectedImage.id === img.id);
					});
				});
			}
		});
	};
}

export type PlaygroundConfigs = APPS | "campaign";

export enum SaveImagesLoadingState {
	Save = "loadingSaveToMyImages",
	SaveAndAddToCanvas = "loadingSaveToMyImagesAndInsertImageToAd",
}
