import { Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import { Outlet } from "react-router-dom";
import LoadingPlaceholder from "../../components/common/LoadingPlaceholder/LoadingPlaceholder";
import { useAppStore } from "../../hooks/useStores";
import styles from "./IframeLayout.module.scss";

const IframeLayout = () => {
	const { authStore } = useAppStore();
	return (
		<LoadingPlaceholder className={styles.loaderStyle} isLoading={authStore.isLoading}>
			<Box className={styles.iframeLayout}>
				{/* <AppHeader /> */}
				<Outlet />
			</Box>
		</LoadingPlaceholder>
	);
};

export default observer(IframeLayout);
