import { SelectChangeEvent } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BriaDropDown, { DropDownItem } from "../../../../../components/common/BriaDropDown/BriaDropDown";
import {
	getModelDefaultLabel,
	getModelKey,
	getModelName,
	getModelVersion,
} from "../../../../../components/common/DropDowns/TextToImageModelsDropdown/TextToImageModelsDropdown";
import { useAppStore } from "../../../../../hooks/useStores";
import iframeStore from "../../../iframe-store.tsx";

const IframeGenModels = () => {
	const { iframeForm, handleFormChange } = iframeStore;
	const { t } = useTranslation("translation", { keyPrefix: "newIframes.editor.form.genModels" });
	const { textToImageStore } = useAppStore();
	const [genModelsOptions, setGenModelsOptions] = useState<DropDownItem[]>([]);
	const selectedModelsKeys: string[] = Object.entries(iframeForm?.config.gen_config?.enabled_models ?? {}).flatMap(
		([model, versions]) => Object.keys(versions).map((version) => getModelKey(model, version)),
	);

	useEffect(() => {
		const createModelsOptions = async (): Promise<DropDownItem[]> => {
			return Object.entries(await textToImageStore.getModels()).flatMap(([modelType, model]) =>
				model.versions.map((version: string) => ({
					key: getModelDefaultLabel(modelType, version),
					value: getModelKey(modelType, version),
				})),
			);
		};

		createModelsOptions().then(setGenModelsOptions);
	}, []);

	const handleChange = (e: SelectChangeEvent<string[]>) => {
		const updatedGenModels = (e.target.value as string[]).reduce((acc, key) => {
			const modelName = getModelName(key);
			const modelVersion = getModelVersion(key);
			const displayName =
				iframeForm.config.gen_config?.enabled_models?.[modelName]?.[modelVersion] ??
				getModelDefaultLabel(modelName, modelVersion);
			acc[modelName] = { ...acc?.[modelName], [modelVersion]: displayName };
			return acc;
		}, {} as IframeGenModels);

		handleFormChange("config", {
			...iframeForm.config,
			gen_config: { ...iframeForm.config.gen_config, enabled_models: updatedGenModels },
		});
	};

	const handleDelete = (keyToDelete: string): void => {
		const updatedGenModels = { ...iframeForm?.config.gen_config?.enabled_models };
		delete updatedGenModels?.[getModelName(keyToDelete)]?.[getModelVersion(keyToDelete)];

		handleFormChange("config", {
			...iframeForm.config,
			gen_config: { ...iframeForm.config.gen_config, enabled_models: updatedGenModels },
		});
	};

	const handleEditLabel = (keyToEdit: string, newValue: string): void => {
		if (newValue) {
			const updatedGenModels = { ...iframeForm?.config.gen_config?.enabled_models };
			updatedGenModels[getModelName(keyToEdit)][getModelVersion(keyToEdit)] = newValue as string;

			handleFormChange("config", {
				...iframeForm.config,
				gen_config: { ...iframeForm.config.gen_config, enabled_models: updatedGenModels },
			});
		}
	};

	return (
		<BriaDropDown
			value={selectedModelsKeys}
			items={genModelsOptions.map((item) => ({
				key:
					iframeForm.config.gen_config?.enabled_models?.[getModelName(item.value)]?.[
						getModelVersion(item.value)
					] ?? item.key,
				value: item.value,
			}))}
			loading={textToImageStore.loadingModels}
			onChange={handleChange}
			handleDelete={handleDelete}
			handleEditItem={handleEditLabel}
			placeholder={t("placeholder")}
			multiple
		/>
	);
};

export default observer(IframeGenModels);

export type DisplayName = string;
export type IframeGenModels = { [model: string]: { [version: string]: DisplayName } };
