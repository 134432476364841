export interface OrgUsage {
	apiUsage: number;
	hasApiKeys: boolean;
	apiFreeCallsLimit: number;
}

export enum OrgFeatures {
	API = "API",
	REMOVE_BG = "REMOVE_BG",
	TAILORED_GENERATION = "TAILORED_GENERATION",
	ECOMMERCE = "ECOMMERCE",
	FOUNDATION_MODELS = "FOUNDATION_MODELS",
}

export interface OrgSubscription {
	plan_name: string;
	interval: string;
	status: string;
	platform_fee: string;
	usage_fee: string;
	cancels_on?: string;
	renews_on?: string;
	hide_fees?: string;
	discount?: string;
	start_date?: string;
	is_blocked: boolean;
	block_reason?: string;
	block_notes?: string;
	features?: OrgFeatures[];
}

export interface OrgSubscriptionPlan {
	name: string;
	displayName: string;
	selfService: boolean;
	priceIds?: string[];
	termsAndConditionsLink?: string;
}
