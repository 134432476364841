import { NavigateFunction, createSearchParams, matchRoutes } from "react-router-dom";
import {
	ACCENTURE_ORG_ID,
	AMANA_ORG_ID,
	AWS_ORG_ID,
	BRIA_DEMOS_ORG_ID,
	BRIA_ORG_ID,
	FOX_ORG_ID,
	MCCANN_ORG_ID,
	NVIDIA_GAMING_ORG_ID,
	PAMPERS_ORG_ID,
	PUBLICIS_LE_PONT_UNILEVER_ORG_ID,
	PUBLICIS_ORG_ID,
	PURINA_ORG_ID,
	TAG_WORLDWIDE_ORG_ID,
	WPP_POC_ORG_ID,
} from "../constants/OrgConstants.ts";
import RouterConstants from "../constants/RouterConstants.ts";
import { USER_ORGANIZATION_ROLES } from "../constants/UserConstants.ts";
import { getSelectedOrganization } from "../helpers/localStorage.ts";
import User from "../models/user.ts";
import { convertS3UriToHttpsUrl } from "../pages/Campaign/Brands/utils.tsx";

export function capitalizeFirstLetter(text: string) {
	return text ? text.charAt(0).toUpperCase() + text.slice(1) : text;
}

export const getOrganizationAdmins = (user: any) => {
	let adminsList = user.user_organizations?.filter((item: any) => {
		return (
			item.role === USER_ORGANIZATION_ROLES.ADMIN ||
			item.role === USER_ORGANIZATION_ROLES.ADMIN_HIDDEN ||
			item.role === USER_ORGANIZATION_ROLES.OWNER
		);
	});
	adminsList = adminsList?.map((item: any) => item.org_uid);
	return adminsList ?? [];
};

export const isBriaAdmin = (user: User | null) => {
	return (
		user?.user_organizations?.some(
			(item: { org_uid: string; status: string; role: string }) =>
				item.org_uid === BRIA_ORG_ID && item.role === USER_ORGANIZATION_ROLES.ADMIN,
		) ?? false
	);
};

export const isBriaMember = (user: User | null) => {
	return (
		user?.user_organizations?.some(
			(item: { org_uid: string; status: string; role: string }) => item.org_uid === BRIA_ORG_ID,
		) ?? false
	);
};

export const isNvidiaGaminOrg = () => {
	const selectedOrg = getSelectedOrganization();
	return selectedOrg?.organization?.uid === NVIDIA_GAMING_ORG_ID;
};

export const validateEmail = (email: string) => {
	return String(email)
		.toLowerCase()
		.match(
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
		);
};

export const isPublicisOrg = () => {
	const selectedOrg = getSelectedOrganization();
	return selectedOrg?.organization?.uid === PUBLICIS_ORG_ID;
};

export const isPublicisLePointUnileverOrg = () => {
	const selectedOrg = getSelectedOrganization();
	return selectedOrg?.organization?.uid === PUBLICIS_LE_PONT_UNILEVER_ORG_ID;
};

export const isAmanaOrg = () => {
	const selectedOrg = getSelectedOrganization();
	return selectedOrg?.organization?.uid === AMANA_ORG_ID;
};

export const isBriaOrg = () => {
	const selectedOrg = getSelectedOrganization();
	return selectedOrg?.organization?.uid === BRIA_ORG_ID;
};

export const isAwsOrg = () => {
	const selectedOrg = getSelectedOrganization();
	return selectedOrg?.organization?.uid === AWS_ORG_ID;
};

export const isMccannOrg = () => {
	const selectedOrg = getSelectedOrganization();
	return selectedOrg?.organization?.uid === MCCANN_ORG_ID;
};

export const isBriaDemosOrg = () => {
	const selectedOrg = getSelectedOrganization();
	return selectedOrg?.organization?.uid === BRIA_DEMOS_ORG_ID;
};

export const isWppPocOrg = () => {
	const selectedOrg = getSelectedOrganization();
	return selectedOrg?.organization?.uid === WPP_POC_ORG_ID;
};

export const isFoxOrg = () => {
	const selectedOrg = getSelectedOrganization();
	return selectedOrg?.organization?.uid === FOX_ORG_ID;
};

export const isPurinaOrg = () => {
	const selectedOrg = getSelectedOrganization();
	return selectedOrg?.organization?.uid === PURINA_ORG_ID;
};

export const isPampersOrg = () => {
	const selectedOrg = getSelectedOrganization();
	return selectedOrg?.organization?.uid === PAMPERS_ORG_ID;
};

export const isAccentureOrg = () => {
	const selectedOrg = getSelectedOrganization();
	return selectedOrg?.organization?.uid === ACCENTURE_ORG_ID;
};

export const isTagWorldwideOrg = () => {
	const selectedOrg = getSelectedOrganization();
	return selectedOrg?.organization?.uid === TAG_WORLDWIDE_ORG_ID;
};

export const navigateWithParams = (navigate: NavigateFunction, path: string) => {
	const queryParams = new URLSearchParams(location.search);
	navigate(`${path}?${queryParams.toString()}`);
};

export function formatDate(apiDateString?: Date): string {
	if (apiDateString) {
		const apiDate = new Date(apiDateString);
		const options: Intl.DateTimeFormatOptions = {
			day: "numeric",
			month: "short",
			year: "numeric",
		};
		return apiDate.toLocaleDateString(undefined, options);
	} else {
		return "";
	}
}

export const isModelTypeDropDownVisible = () => {
	return false;
};

export const getSafeFileName = (filename: string) => {
	return filename?.replace(/[^a-z0-9]/gi, "_");
};

export const hasOrganization = () => {
	const selectedOrg = getSelectedOrganization();
	return !!selectedOrg?.organization;
};

export const getRouteConfigs = (url: string) => {
	const routes = Object.values(RouterConstants).map((config) => ({
		path: config.fullPath ?? config.path,
	}));

	const matchedRoutes = matchRoutes(routes, url);

	if (matchedRoutes && matchedRoutes.length > 0) {
		const match = matchedRoutes[0];
		return Object.values(RouterConstants).find((config) => config.fullPath === match.route.path);
	}

	return null;
};

export const getTodayDateRange = (): [Date, Date] => {
	const now = new Date();
	const startDate = new Date(now.getFullYear(), now.getMonth(), 1);
	const endDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());
	return [startDate, endDate];
};

export const removeParamFromLocationUrl = (params: string[], navigate?: NavigateFunction) => {
	const queryParams = new URLSearchParams(location.search);
	params.forEach((param) => queryParams.delete(param));
	if (navigate) {
		navigate(
			{
				search: createSearchParams(queryParams).toString(),
			},
			{ replace: true },
		);
	}
	return `${location.origin}${location.pathname}?${queryParams.toString()}`;
};

export const loadFont = (fontSrc: any) => {
	const style = document.createElement("style");
	const httpUrl = convertS3UriToHttpsUrl(fontSrc.src);
	style.appendChild(
		document.createTextNode(`
	  @font-face {
		font-family: "${fontSrc.label ?? fontSrc.name}";
		src: url("${httpUrl}") format("truetype");
	  }
	`),
	);
	document.head.appendChild(style);
};

export const sortById = (list: any[] | undefined) => {
	return list ? list.slice().sort((itemA: any, itemB: any) => itemA.id - itemB.id) : [];
};
export const isFoxApps = () => {
	return window.location.pathname.includes("fox-apps");
};

export const isCanvasRequiredForThisApp = () => {
	const appsWithCanvas = ["eraser"];
	return appsWithCanvas.some((app) => window.location.pathname.includes(app));
};