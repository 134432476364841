import { IBrushSettings } from "../models/sandboxAPI.ts";

export const MAX_ALLOWED_SELECTED_IMAGES = 35;
export const DEFAULT_BRUSH: IBrushSettings = Object.freeze({
	lineJoin: "round",
	lineCap: "round",
	strokeStyle: "#8800FF",
	lineWidth: 60,
	globalCompositeOperation: "source-over",
	globalAlpha: 1,
	brushColorOpacity: 0.5,
});

export const SOLID_COLOR_CONFIG = {
	selected: false,
	color_code: "#FFFFFF",
};

export const GENERATE_BY_TEXT_CONFIGS = {
	selected: false,
	prompt: "",
	num_results: 1,
	fast_version: true,
};

export const BACKGROUND_CONFIGS = {
	expand: false,
	remove: false,
	solid: SOLID_COLOR_CONFIG,
	generateByText: GENERATE_BY_TEXT_CONFIGS,
};

export const CUSTOM_SIZE_CONFIGS = {
	selected: false,
	width: 0,
	height: 0,
};

export const SIZE_CONFIGS = {
	crop: false,
	custom_size: CUSTOM_SIZE_CONFIGS,
};

export const DEFAULT_STRUCTURE_REF_INFLUENCE = 0.75;
export const DEFAULT_TAILORED_MODEL_INFLUENCE = 0.5;

export const IMAGE_REFERENCE = {
	selected: true,
	structure_ref_influence: DEFAULT_STRUCTURE_REF_INFLUENCE,
	tailored_model_influence: DEFAULT_TAILORED_MODEL_INFLUENCE,
	num_results: 1,
};
