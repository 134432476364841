import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { isProduction } from "../../config/env";
import { setSelectedOrganization } from "../../helpers/localStorage";
import { useAuthService } from "../../hooks/useAuthService";
import { useBriaTheme } from "../../hooks/useBriaTheme";
import { useIframe } from "../../hooks/useIframe";
import { useAppStore } from "../../hooks/useStores";
import { Iframe, IframePostMessageTypes } from "../../models/new-iframe";
import { UserOrganization } from "../../models/organization";
import iframeStore from "../../pages/IframeNew/iframe-store.tsx";

function InitIframe() {
	const { textToImageStore, campaignStore } = useAppStore();
	const { getIframeId, authenticateIframe, navigateToLandingPage } = useIframe();
	const { convertUnderscoreToCssVar, setCssVariableValue } = useBriaTheme();
	const { loginAsGuest } = useAuthService();

	useEffect(() => {
		const handlePreviewMessage = async (event: MessageEvent) => {
			const { type, message } = JSON.parse(event.data);
			if (type === IframePostMessageTypes.InitPreview && message) {
				const { iframe, fb_organization } = message;
				!isProduction() && console.log(iframe);
				await updateIframe(iframe, fb_organization);
				navigateToLandingPage();
			}
		};

		const initIframe = async () => {
			const iframeId = getIframeId();
			if (!iframeId) return;

			if (iframeId === "preview") {
				window.addEventListener("message", handlePreviewMessage);
				parent.postMessage("readyToPreview", "*");
				!isProduction() && console.log("Sent post message 'readyToPreview' to '*'");
			} else {
				await loginAsGuest();
				const { iframe, fb_organization } = await iframeStore.getIframeAnonymous(iframeId);
				await updateIframe(iframe, fb_organization);
				navigateToLandingPage();
			}
		};

		initIframe();
		return () => {
			window.removeEventListener("message", handlePreviewMessage);
		};
	}, []);

	const updateIframe = async (iframe: Iframe, fb_organization: UserOrganization) => {
		iframeStore.iframe = iframe;
		updateTheme();
		setSelectedOrganization(fb_organization);
		await Promise.all([authenticateIframe(iframe), initConfigs()]);
	};

	const initConfigs = async () => {
		return Promise.all([
			initTextToImageConfig(),
			initImageToImageConfig(),
			initAiEditorConfig(),
			initCampaignConfig(),
		]);
	};

	const initTextToImageConfig = async () => {
		const defaultAspectRatio = iframeStore.iframe?.config.gen_config?.default_aspect_ratio;
		defaultAspectRatio && textToImageStore.handleConfigChange("aspect_ratio", defaultAspectRatio);
	};

	const initImageToImageConfig = async () => {};

	const initAiEditorConfig = async () => {};

	const initCampaignConfig = async () => {
		campaignStore.handleAdminModeChange(!!iframeStore.iframe?.config.controls?.isAdmin);
	};

	const updateTheme = () => {
		const style = iframeStore.iframe?.config.custom_style;
		Object.entries(style?.cssVars ?? {}).forEach(([cssKey, value]) =>
			setCssVariableValue(convertUnderscoreToCssVar(cssKey), value),
		);
	};
	return null;
}

export default observer(InitIframe);
