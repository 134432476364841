import { makeAutoObservable, runInAction } from "mobx";
import { FilterByOptions } from "../../components/common/BriaTable/BriaTable.tsx";
import { PaginatedItems } from "../../models/common.ts";
import { Iframe, getDefaultIframe } from "../../models/new-iframe.ts";
import { UserOrganization } from "../../models/organization.ts";
import QueryService from "../../utils/QueryService.ts";

export interface IIframeStore {
	iframe?: Iframe;
	iframeForm: Iframe;
	paginatedIframes: PaginatedItems<Iframe>;
	loadingOrgIframes: boolean;
	rowsPerPage: number;
	formErrors: { save?: boolean; invalidName?: boolean };
	getOrgIframes: (pageNumber: number, filterBy?: FilterByOptions) => Promise<PaginatedItems<Iframe>>;
	handleFormChange: <K extends keyof Iframe>(key: K, value: Iframe[K]) => void;
	getIframe: (iframeId: string) => Promise<Iframe>;
	getIframeAnonymous: (iframeId: string) => Promise<{ iframe: Iframe; fb_organization: UserOrganization }>;
	createIframe: (iframe: Iframe) => Promise<number>;
	updateIframe: (iframe: Iframe) => Promise<void>;
	deleteIframe: (iframeId: string) => Promise<void>;
	isIframe: () => boolean;
}

export class IframeStore implements IIframeStore {
	private queryService: QueryService = new QueryService("/iframes");
	iframe: Iframe;
	iframeForm: Iframe;
	paginatedIframes: PaginatedItems<Iframe> = { total: 0, items: [] };
	rowsPerPage: number = 20;
	loadingOrgIframes: boolean = false;
	formErrors: { save?: boolean; invalidName?: boolean } = {};
	queryParams = new URLSearchParams(location.search);

	constructor() {
		makeAutoObservable(this);
		const defaultIframe = getDefaultIframe("");
		this.iframeForm = defaultIframe;
		this.iframe = { ...defaultIframe, id: this.queryParams.get("iframeId") ?? "" };
	}

	getOrgIframes = async (pageNumber: number, filterBy?: FilterByOptions): Promise<PaginatedItems<Iframe>> => {
		try {
			this.loadingOrgIframes = true;
			const paginatedIframes: PaginatedItems<Iframe> = await this.queryService.get("/", {
				params: {
					filter_by: filterBy,
					page: pageNumber,
					per_page: this.rowsPerPage,
				},
			});

			runInAction(() => {
				this.paginatedIframes = paginatedIframes;
				this.loadingOrgIframes = false;
			});

			return this.paginatedIframes;
		} catch (e: any) {
			this.loadingOrgIframes = false;
			return Promise.reject(`Error loading organization's iframes: ${e.message || e.toString()}`);
		}
	};

	handleFormChange = <K extends keyof Iframe>(key: K, value: Iframe[K]) => {
		this.iframeForm = { ...this.iframeForm, [key]: value };
	};

	getIframe = async (iframeId: string): Promise<Iframe> => {
		return this.queryService.get(`/${iframeId}`);
	};

	getIframeAnonymous = async (iframeId: string): Promise<{ iframe: Iframe; fb_organization: UserOrganization }> => {
		return this.queryService.get(`/anonymous/${iframeId}`);
	};

	createIframe = async (iframe: Iframe): Promise<number> => {
		try {
			const iframeId = await this.queryService.post(`/`, iframe);
			return iframeId;
		} catch (e: any) {
			if (e.response?.status === 409) {
				this.formErrors.invalidName = true;
			}
			return Promise.reject(`Error creating new iframe: ${e.message || e.toString()}`);
		}
	};
	updateIframe = async (iframe: Iframe): Promise<void> => {
		try {
			await this.queryService.put(`/${iframe.id}`, iframe);
			runInAction(() => {
				this.paginatedIframes.items = this.paginatedIframes?.items.map((template) =>
					template.id === iframe.id ? iframe : template,
				);
			});
		} catch (e: any) {
			if (e.response?.status === 409) {
				this.formErrors.invalidName = true;
			}
			return Promise.reject(`Error updating iframe: ${e.message || e.toString()}`);
		}
	};

	deleteIframe = async (iframeId: string): Promise<void> => {
		return this.queryService.delete(`/${iframeId}`);
	};

	isIframe = (): boolean => {
		return !!this.iframe?.id;
	};
}

const iframeStore = new IframeStore();
export default iframeStore;
