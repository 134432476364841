import { Box, Divider, Typography } from "@mui/material";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import StopIcon from "../../../../assets/images/icons/StopIcon.tsx";
import BriaButton from "../../../../components/common/BriaButton/BriaButton";
import BriaSlider from "../../../../components/common/BriaSlider/BriaSlider.tsx";
import { APPS } from "../../../../constants/AppsConstants.ts";
import {
	DEFAULT_STRUCTURE_REF_INFLUENCE,
	DEFAULT_TAILORED_MODEL_INFLUENCE,
	MAX_ALLOWED_SELECTED_IMAGES,
} from "../../../../constants/ImageToImageConstants.ts";
import { useImageToImageConfig } from "../../../../hooks/useImageToImageConfig";
import { useAppStore } from "../../../../hooks/useStores";
import ExpandableMenuButton from "../../../../layout/ExpandableMenu/Button/ExpandableMenuButton.tsx";
import ExpandableMenuLayout from "../../../../layout/ExpandableMenu/Menu/ExpandableMenuLayout.tsx";
import InputLayout from "../../../../layout/InputLayout/InputLayout";
import SwipableMenuButton from "../../../../layout/SwipableMenu/Button/SwipableMenuButton";
import SwipableMenuLayout from "../../../../layout/SwipableMenu/Menu/SwipableMenuLayout";
import { ImageReferenceConfigsType, ImageToImageConfigType } from "../../../../models/image-to-image";
import { isFoxApps } from "../../../../utils";
import ConfigDisplay from "./ConfigDisplay/ConfigDisplay";
import BackgroundConfig from "./Features/Background/BackgroundConfig";
import ForegroundPlacement from "./Features/ForegroundPlacement/ForegroundPlacement";
import ImageReference from "./Features/ImageReference/ImageReference.tsx";
import SizeConfig from "./Features/Size/SizeConfig";
import StyleConfig from "./Features/Style/StyleConfig";
import styles from "./ImageToImageConfig.module.scss";

const ImageToImageConfig = () => {
	const { t } = useTranslation("translation", { keyPrefix: "playground.imageToImage.config" });
	const { imageToImageStore, playgroundStore } = useAppStore();
	const {
		isGenerateEnabled,
		updateSelectedBackgroundConfigs,
		updateSelectedSizeConfigs,
		updateSelectedForegroundPlacementConfigs,
		updateSelectedImageReferenceConfigs,
	} = useImageToImageConfig();

	const countSelected = playgroundStore.getSelectedImages().length;
	const [isGeneratingImages, setIsGeneratingImages] = useState<boolean>(
		imageToImageStore.isGeneratingImages || false,
	);
	const [activeConfig, setActiveConfig] = useState<string>("");

	useEffect(() => {
		if (playgroundStore.selectedConfig !== APPS.IMAGE_TO_IMAGE) {
			playgroundStore.handleAppChange(APPS.IMAGE_TO_IMAGE);
		}

		setIsGeneratingImages(imageToImageStore.isGeneratingImages);
	}, [imageToImageStore.isGeneratingImages]);

	const handleDeleteConfig = (configKey: keyof ImageToImageConfigType, field: string, value: any) => {
		const currentConfig = { ...(imageToImageStore.config[configKey] as Record<string, any> | undefined) };

		const extraConfigs: ImageReferenceConfigsType = {};

		if (configKey === "image_reference") {
			switch (field) {
				case "prompt":
					extraConfigs.structure_ref_influence = DEFAULT_STRUCTURE_REF_INFLUENCE;
					break;
				case "tailored_model_id":
					extraConfigs.tailored_model_influence = DEFAULT_TAILORED_MODEL_INFLUENCE;
					extraConfigs.model_id = undefined;
					break;
				default:
					break;
			}
		}
		imageToImageStore.handleConfigChange(configKey, {
			...currentConfig,
			...extraConfigs,
			[field]: value,
		});
	};

	const renderFoxAppsConfig = () => (
		<>
			<SwipableMenuLayout className={styles.config}>
				<SwipableMenuButton
					swipeTo={<StyleConfig />}
					subSwipableMenuProps={{ title: t("features.style.title") }}
				>
					<Box className={styles.buttonLabel}>{t("features.style.button")}</Box>
				</SwipableMenuButton>
				<Divider />
				<InputLayout label={t("numberOfImages.label")} labelClassName={styles.inputLabel}>
					<BriaSlider
						value={imageToImageStore.config.num_results}
						onChange={(_, value) => imageToImageStore.handleConfigChange("num_results", value as number)}
						min={1}
						step={1}
						max={4}
						marks={Array.from({ length: 4 }, (_, index) => ({
							value: index + 1,
							label: `${index + 1}`,
						}))}
					/>
				</InputLayout>
			</SwipableMenuLayout>
			<BriaButton
				buttonType="primaryMedium"
				disabled={!isGenerateEnabled()}
				fullWidth
				onClick={() => imageToImageStore.generateImageToImage()}
			>
				{countSelected * imageToImageStore.config.num_results > MAX_ALLOWED_SELECTED_IMAGES
					? t("tooManySelected")
					: t("button")}
			</BriaButton>
		</>
	);

	const renderStandardAppsConfig = () => (
		<Box className={styles.imageToImageConfigsWrapper}>
			<ExpandableMenuLayout
				className={clsx(styles.config, styles.imageToImageConfigs)}
				setActiveConfig={setActiveConfig}
			>
				<Box className={styles.configHeader}>
					<Typography variant="h6" className={styles.title}>
						{t("title")}
					</Typography>
					<Typography className={styles.subTitle}>{t("subTitle")}</Typography>
				</Box>
				<ExpandableMenuButton
					expandTo={<BackgroundConfig />}
					subMenuPanelProps={{ title: t("features.background.title") }}
					isActive={activeConfig === t("features.background.title")}
				>
					<Box className={styles.buttonLabel}>{t("features.background.button")}</Box>
				</ExpandableMenuButton>
				<ConfigDisplay
					config={imageToImageStore.config.background}
					onDelete={(configKey: string, value: string) => {
						handleDeleteConfig("background", configKey, value);
						updateSelectedBackgroundConfigs();
					}}
				/>
				<Divider />
				<ExpandableMenuButton
					expandTo={<SizeConfig />}
					subMenuPanelProps={{ title: t("features.size.title") }}
					isActive={activeConfig === t("features.size.title")}
				>
					<Box className={styles.buttonLabel}>{t("features.size.button")}</Box>
				</ExpandableMenuButton>
				<ConfigDisplay
					config={imageToImageStore.config.size}
					onDelete={(configKey: string, value: string) => {
						handleDeleteConfig("size", configKey, value);
						updateSelectedSizeConfigs();
					}}
				/>
				<Divider />
				<ExpandableMenuButton
					expandTo={<ForegroundPlacement />}
					subMenuPanelProps={{ title: t("features.foregroundPlacement.title") }}
					isActive={activeConfig === t("features.foregroundPlacement.title")}
				>
					<Box className={styles.buttonLabel}>{t("features.foregroundPlacement.button")}</Box>
				</ExpandableMenuButton>
				<ConfigDisplay
					config={imageToImageStore.config.foreground_placement}
					onDelete={(configKey: string, value: string) => {
						handleDeleteConfig("foreground_placement", configKey, value);
						updateSelectedForegroundPlacementConfigs();
					}}
				/>
				<Divider />
				<ExpandableMenuButton
					expandTo={<ImageReference />}
					subMenuPanelProps={{ title: t("features.imageReference.title") }}
					isActive={activeConfig === t("features.imageReference.title")}
				>
					<Box className={styles.buttonLabel}>{t("features.imageReference.button")}</Box>
				</ExpandableMenuButton>
				<ConfigDisplay
					config={imageToImageStore.config.image_reference}
					onDelete={(configKey: string, value: string) => {
						handleDeleteConfig("image_reference", configKey, value);
						updateSelectedImageReferenceConfigs();
					}}
				/>
			</ExpandableMenuLayout>
			{isGeneratingImages && (
				<BriaButton
					buttonType="primaryMedium"
					className={styles.stopGeneratingBtn}
					disabled={isGenerateEnabled() as boolean}
					fullWidth
					onClick={() => imageToImageStore.abortImageGeneration()}
				>
					<StopIcon /> {t("stopGenerating")}
				</BriaButton>
			)}
			{!isGeneratingImages && (
				<BriaButton
					buttonType="primaryMedium"
					disabled={!isGenerateEnabled()}
					fullWidth
					onClick={() => imageToImageStore.generateImageToImage()}
					className="generate-image-to-image"
				>
					{countSelected * imageToImageStore.config.num_results > MAX_ALLOWED_SELECTED_IMAGES
						? t("tooManySelected")
						: t("button")}
					{!isFoxApps() &&
						imageToImageStore.getGeneratedImagesCount() > 0 &&
						isGenerateEnabled() &&
						` ${imageToImageStore.getGeneratedImagesCount()} ${t("results")}`}
				</BriaButton>
			)}
		</Box>
	);

	return <Box className={styles.wrapper}>{isFoxApps() ? renderFoxAppsConfig() : renderStandardAppsConfig()}</Box>;
};

export default observer(ImageToImageConfig);
