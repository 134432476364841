import { useTranslation } from "react-i18next";
import foxLogo from "../../../assets/images/pngs/FS HIGH RES.png";
import BriaButton from "../../../components/common/BriaButton/BriaButton.tsx";
import OrganizationsDropdown from "../../../components/common/OrganizationsDropdown/OrganizationsDropdown.tsx";
import RouterConstants from "../../../constants/RouterConstants.ts";
import { useAppStore } from "../../../hooks/useStores.tsx";
import HeaderLayout from "../HeaderLayout.tsx";

import { observer } from "mobx-react-lite";
import SupportButton from "../../../components/common/SupportButton/SupportButton.tsx";
import useSecureNavigate from "../../../hooks/useSecureNavigate.tsx";
import styles from "./FoxHeader.module.scss";

const FoxHeader = () => {
	const navigate = useSecureNavigate();
	const { uiStore, authStore } = useAppStore();
	const { t } = useTranslation("translation", { keyPrefix: "headers.fox" });

	const handleFoxLogoClick = () => {
		navigate(RouterConstants.FOX_APPS.path);
	};

	return (
		<HeaderLayout className={styles.header} logo={foxLogo} onLogoClick={handleFoxLogoClick}>
			<>
				<BriaButton buttonType="textMedium" onClick={() => uiStore.showDialog("FoxGuidelinesPopup")}>
					{t("userGuide")}
				</BriaButton>
				{authStore.user && <SupportButton page={"Fox"} />}
			</>
			<OrganizationsDropdown />
		</HeaderLayout>
	);
};

export default observer(FoxHeader);
