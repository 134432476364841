import { Box, SelectChangeEvent, Typography } from "@mui/material";
import clsx from "clsx";
import { get, ref } from "firebase/database";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BriaButton from "../../../../../../components/common/BriaButton/BriaButton";
import BriaDropDown, { DropDownItem } from "../../../../../../components/common/BriaDropDown/BriaDropDown";
import BriaInput from "../../../../../../components/common/BriaInput/BriaInput";
import useStaticDropdown from "../../../../../../components/common/DropDowns/useStaticDropdown";
import { firebaseDatabase } from "../../../../../../config/firebase";
import { useImageToImageConfig } from "../../../../../../hooks/useImageToImageConfig";
import { useAppStore } from "../../../../../../hooks/useStores";
import {
	ConfigValue,
	Platform,
	PlatformDestination,
	ResponsePlatformDestination,
} from "../../../../../../models/image-to-image";
import { generateCropPresetsByPlatform } from "../../../../../../utils/generateCropPresetsByPlatform";
import Config from "../../Config/Config";
import styles from "./SizeConfig.module.scss";

const SizeConfig = () => {
	const { t } = useTranslation("translation", { keyPrefix: "playground.imageToImage.config.features.size" });
	const { imageToImageStore } = useAppStore();
	const { updateSelectedSizeConfigs } = useImageToImageConfig();
	const { items } = useStaticDropdown({ tKeyPrefix: "aspectRatio" });
	const AspectRatiosList = items;

	const selectedAspectRatios = imageToImageStore.config.size?.aspect_ratio?.value || [];
	const selectedPlatform = imageToImageStore.config.size?.medium_destination?.platform?.key || "";
	const selectedDestinations = imageToImageStore.config.size?.medium_destination?.destinations || [];

	const [cropConfig, setCropConfig] = useState<any>([]);
	const [platformsList, setPlatformsList] = useState<DropDownItem[]>([]);
	const [activeConfig, setActiveConfig] = useState<string>("");

	useEffect(() => {
		getCropConfig();
	}, []);

	const getCropConfig = async () => {
		const res = await get(ref(firebaseDatabase, "crop_config/"));
		const data = generateCropPresetsByPlatform(res.val());
		setCropConfig(data);

		const platformsList: DropDownItem[] = [];
		data.flatMap((selectedPlatform) => {
			[...selectedPlatform.keys()].map((platform) => {
				platformsList.push({
					key: platform,
					value: platform,
				});
				return platform;
			});
		});
		setPlatformsList(platformsList);
	};

	const handleSizeConfigChange = (field: string, value: ConfigValue) => {
		imageToImageStore.handleConfigChange("size", {
			...imageToImageStore.config.size,
			[field]: value,
		});
		updateSelectedSizeConfigs();
	};

	const handleMediumDestinationChange = (e: SelectChangeEvent<string>) => {
		imageToImageStore.handleConfigChange("size", {
			...imageToImageStore.config.size,
			medium_destination: {
				...imageToImageStore.config.size?.medium_destination,
				platform: { ...imageToImageStore.config.size?.medium_destination?.platform, key: e.target.value },
			},
		});
		updateSelectedSizeConfigs();
	};

	const handlePlatformSelection = (item: PlatformDestination) => {
		const isSelected = selectedDestinations.some(
			(destination) => destination.width === item.width && destination.height === item.height,
		);

		const updatedDestinations = isSelected
			? selectedDestinations.filter(
					(destination) => destination.width !== item.width || destination.height !== item.height,
				)
			: [...selectedDestinations, item];

		imageToImageStore.handleConfigChange("size", {
			...imageToImageStore.config.size,
			medium_destination: {
				...imageToImageStore.config.size?.medium_destination,
				destinations: updatedDestinations,
				selected: updatedDestinations.length > 0,
			},
		});
		updateSelectedSizeConfigs();
	};

	const handleAspectRatioSelection = (item: string) => {
		const isSelected = selectedAspectRatios.includes(item);

		const updatedAspectRatios = isSelected
			? selectedAspectRatios.filter((aspectRatio) => aspectRatio !== item)
			: [...selectedAspectRatios, item];

		imageToImageStore.handleConfigChange("size", {
			...imageToImageStore.config.size,
			aspect_ratio: {
				...imageToImageStore.config.size?.aspect_ratio,
				value: updatedAspectRatios,
				selected: updatedAspectRatios.length > 0,
			},
		});
		updateSelectedSizeConfigs();
	};

	const updateSelectedCustomSize = () => {
		const customSize = imageToImageStore.config.size?.custom_size;
		let isSelected = customSize?.selected;
		const updatedCustomSize = [];

		if (customSize?.width) {
			updatedCustomSize.push("width");
		}

		if (customSize?.height) {
			updatedCustomSize.push("height");
		}

		if (!isSelected && updatedCustomSize.length === 2) {
			isSelected = true;
		}

		if (isSelected && updatedCustomSize.length < 1) {
			isSelected = false;
		}

		handleSizeConfigChange("custom_size", {
			...imageToImageStore.config.size?.custom_size,
			selected: isSelected,
		});
	};

	return (
		<Box className={styles.sizeConfigContainer}>
			{/* Crop to Fit */}
			{/** TODO: crop will be hidden now till engin support it with other configs */}
			{/* <Config
				title={t("cropToFitForeground")}
				checked={imageToImageStore.config.size?.crop}
				onClick={(e) => {
					const target = e.target as HTMLInputElement;
					handleSizeConfigChange("crop", target.checked);
				}}
			/> */}

			{/* Aspect Ratio */}
			<Config
				title={t("aspectRatio")}
				checked={imageToImageStore.config.size?.aspect_ratio?.selected || selectedAspectRatios.length > 0}
				onClick={(e) => {
					const target = e.target as HTMLInputElement;
					handleSizeConfigChange("aspect_ratio", {
						...imageToImageStore.config.size?.aspect_ratio,
						selected: target.checked,
						value: target.checked ? selectedAspectRatios : [],
					});
					setActiveConfig(t("aspectRatio"));
				}}
				isActive={activeConfig === t("aspectRatio")}
				setIsActive={setActiveConfig}
			>
				<Box className={styles.configContent}>
					<Box className={styles.aspectRatioList}>
						{AspectRatiosList?.map((aspectRatio) => {
							const value = aspectRatio.value as string;
							const isSelected = selectedAspectRatios.includes(value);
							return (
								<BriaButton
									onClick={() => handleAspectRatioSelection(value)}
									className={clsx(styles.aspectRatioBtn, {
										[styles.selected]: isSelected,
									})}
								>
									{value}
								</BriaButton>
							);
						})}
					</Box>
				</Box>
			</Config>

			{/* Custom Size */}
			<Config
				title={t("customSize")}
				checked={imageToImageStore.config.size?.custom_size?.selected}
				onClick={(e) => {
					const target = e.target as HTMLInputElement;
					const custom_size = imageToImageStore.config.size?.custom_size;
					handleSizeConfigChange("custom_size", {
						...custom_size,
						selected: target.checked,
						width: target.checked ? custom_size?.width : 0,
						height: target.checked ? custom_size?.height : 0,
					});
					setActiveConfig(t("customSize"));
				}}
				isActive={activeConfig === t("customSize")}
				setIsActive={setActiveConfig}
			>
				<Box className={clsx(styles.configContent, styles.dimensions)}>
					<BriaInput
						value={imageToImageStore.config.size?.custom_size?.width}
						onChange={async (e) => {
							const target = e.target as HTMLInputElement;
							await handleSizeConfigChange("custom_size", {
								...imageToImageStore.config.size?.custom_size,
								width: parseInt(target.value),
							});
							updateSelectedCustomSize();
						}}
						label={t("width")}
						type="number"
						customEndAdornmentText={"px"}
					/>
					<BriaInput
						value={imageToImageStore.config.size?.custom_size?.height}
						onChange={async (e) => {
							const target = e.target as HTMLInputElement;
							await handleSizeConfigChange("custom_size", {
								...imageToImageStore.config.size?.custom_size,
								height: parseInt(target.value),
							});
							updateSelectedCustomSize();
						}}
						label={t("height")}
						type="number"
						customEndAdornmentText={"px"}
					/>
				</Box>
			</Config>

			{/* Medium Destination */}
			<Config
				title={t("mediumDestination")}
				checked={imageToImageStore.config.size?.medium_destination?.selected || selectedDestinations.length > 0}
				onClick={(e) => {
					const target = e.target as HTMLInputElement;
					handleSizeConfigChange("medium_destination", {
						...imageToImageStore.config.size?.medium_destination,
						selected: target.checked,
						destinations: target.checked ? selectedDestinations : [],
					});
					target.checked && setActiveConfig(t("mediumDestination"));
				}}
				isActive={activeConfig === t("mediumDestination")}
				setIsActive={setActiveConfig}
			>
				<Box className={styles.configContent}>
					<BriaDropDown
						value={selectedPlatform}
						onChange={handleMediumDestinationChange}
						placeholder={t("dropDownPlaceholder")}
						items={platformsList}
						className={styles.dropDown}
						width={"-webkit-fill-available"}
					/>
					{selectedPlatform && (
						<Box className={styles.platformItems}>
							{cropConfig.map((platform: Platform) => {
								return platform.get?.(selectedPlatform)?.map((item: ResponsePlatformDestination) => {
									const isSelected = selectedDestinations.find(
										(destination) =>
											destination.width === item.width && destination.height === item.height,
									);

									return (
										<BriaButton
											key={`${selectedPlatform}-${item.name}`}
											onClick={() =>
												handlePlatformSelection({
													name: `${selectedPlatform} ${item.name}`,
													width: item.width,
													height: item.height,
												})
											}
											className={clsx(styles.platformItem, {
												[styles.selected]: isSelected,
											})}
										>
											<Box
												className={styles.platformOption}
												sx={{ width: item.resize.width ?? 0, height: item.resize.height ?? 0 }}
											></Box>
											<Box>
												<Typography className={styles.label}>{item.name}</Typography>
												<Typography className={styles.label}>
													{item.width} x {item.height}
												</Typography>
											</Box>
										</BriaButton>
									);
								});
							})}
						</Box>
					)}
				</Box>
			</Config>
		</Box>
	);
};

export default observer(SizeConfig);
