import { SelectChangeEvent } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CrownIcon } from "../../../../../assets/images/icons/CrownIcon";
import BriaDropDown, { DropDownItem } from "../../../../../components/common/BriaDropDown/BriaDropDown";
import { TAILORED_GENERATION_MODEL_STATUS } from "../../../../../constants/TGConstants";
import { useAppStore } from "../../../../../hooks/useStores";
import { ITailoredGenerationModel } from "../../../../../models/tailoredGeneration";
import iframeStore from "../../../iframe-store.tsx";

const IframeTgModels = () => {
	const { t } = useTranslation("translation", { keyPrefix: "newIframes.editor.form.tgModels" });
	const { tailoredGenerationStore, authStore, pricingStore } = useAppStore();
	const { iframeForm, handleFormChange } = iframeStore;
	const [tgModelsOptions, setTgModelsOptions] = useState<DropDownItem[]>([]);

	useEffect(() => {
		const createModelsOptions = async (): Promise<DropDownItem[]> => {
			return (await tailoredGenerationStore.loadModels(TAILORED_GENERATION_MODEL_STATUS.COMPLETED)).map(
				(tailoredModel: ITailoredGenerationModel) => ({
					key: tailoredModel.name,
					value: tailoredModel.id as string,
					icon: !authStore.isPremiumOrgSubscription ? <CrownIcon /> : "",
				}),
			);
		};

		createModelsOptions().then(setTgModelsOptions);
	}, [authStore.isPremiumOrgSubscription]);

	const handleTgModelChange = (e: SelectChangeEvent<string[]>) => {
		if (authStore.isPremiumOrgSubscription) {
			handleFormChange("config", {
				...iframeForm.config,
				tg_config: { ...iframeForm.config.tg_config, enabled_models: e.target.value as string[] },
			});
		} else {
			pricingStore.openPricingFlow();
		}
	};

	const handleDeleteTgModel = (keyToDelete: string): void => {
		const updatedTgModels = iframeForm?.config.tg_config?.enabled_models ?? [];
		const index = updatedTgModels.indexOf(keyToDelete as string);
		if (index > -1) {
			updatedTgModels.splice(index, 1);
		}
		handleFormChange("config", {
			...iframeForm.config,
			tg_config: { ...iframeForm.config.tg_config, enabled_models: updatedTgModels },
		});
	};

	return (
		<BriaDropDown
			menuMaxHeight={"300px"}
			value={iframeForm?.config.tg_config?.enabled_models ?? []}
			onChange={handleTgModelChange}
			handleDelete={handleDeleteTgModel}
			loading={tailoredGenerationStore.isLoading || authStore.isLoadingOrgSubscriptions}
			error={tailoredGenerationStore.isError}
			placeholder={tailoredGenerationStore.isLoading || tgModelsOptions.length ? t("placeholder") : t("noModels")}
			disabled={
				!tailoredGenerationStore.isLoading &&
				(tgModelsOptions.length === 0 || authStore.isLoadingOrgSubscriptions)
			}
			items={tgModelsOptions}
			multiple
		/>
	);
};
export default observer(IframeTgModels);
