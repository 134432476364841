import { NavigateFunction, NavigateOptions, To, useNavigate } from "react-router-dom";
import RouterConstants from "../constants/RouterConstants.ts";

function useSecureNavigate() {
	const navigate = useNavigate();

	const navigateSecurely: NavigateFunction = (to: To | number, options?: NavigateOptions) => {
		if (typeof to === "number") {
			navigate(to);
		} else {
			const relativeUrlRegex = /^\/?(?:[^/]+\/?)+$/;
			const toPath = typeof to === "string" ? to : to.pathname;
			if (toPath && relativeUrlRegex.test(toPath)) {
				navigate(to, options);
			} else {
				navigate(RouterConstants.CONSOLE.fullPath, options);
			}
		}
	};

	return navigateSecurely as NavigateFunction;
}

export default useSecureNavigate;
