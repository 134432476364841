import Box from "@mui/material/Box";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { CrownIcon } from "../../../assets/images/icons/CrownIcon";
import LinkConstants from "../../../constants/LinkConstants";
import { useAppStore } from "../../../hooks/useStores";
import { OrgFeatures } from "../../../models/billing";
import { TailoredModelsDropDownProps } from "../../../models/tailoredGeneration";
import { isBriaMember } from "../../../utils";
import BriaButton from "../BriaButton/BriaButton";
import TextToImageModelsDropdown from "../DropDowns/TextToImageModelsDropdown/TextToImageModelsDropdown";
import LoadingPlaceholder from "../LoadingPlaceholder/LoadingPlaceholder";
import styles from "./TailoredModelsDropdown.module.scss";

const TailoredModelsDropdown = ({ onChange, placeholder, selectedValue }: TailoredModelsDropDownProps) => {
	const { t } = useTranslation("translation");
	const { authStore, tailoredGenerationStore } = useAppStore();

	const requestAccess = !(
		authStore.isFeatureEnabled(OrgFeatures.TAILORED_GENERATION) || isBriaMember(authStore.user)
	);
	const isLoading = authStore.isLoadingOrgSubscriptions || tailoredGenerationStore.isLoading;

	return (
		<Box className={clsx(styles.modelsDropdownContainer, "modelsDropdownContainer")}>
			<Box className={styles.overlay}>
				<LoadingPlaceholder className={styles.loading} isLoading={isLoading} size={20}>
					{requestAccess && (
						<BriaButton
							buttonType="textSmall"
							className={styles.accessBtn}
							disableRipple
							onClick={() => window.open(LinkConstants.CONTACT_US_FORM_LINK, "_blank")}
						>
							<CrownIcon /> {t("requestAccess")}
						</BriaButton>
					)}
				</LoadingPlaceholder>
			</Box>
			<TextToImageModelsDropdown
				selectedValue={selectedValue}
				handleChange={onChange}
				placeholder={placeholder}
				showFoundationModels={false}
			/>
		</Box>
	);
};

export default observer(TailoredModelsDropdown);
