import { Box, InputBaseComponentProps } from "@mui/material";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import DeleteIcon from "../../../assets/images/icons/DeleteIcon";
import { EditIcon2 } from "../../../assets/images/icons/EditIcon2";
import { showErrorToast } from "../../../utils/toast";
import BriaButton, { BriaButtonProps, ButtonTypes } from "../BriaButton/BriaButton";
import LoadingPlaceholder from "../LoadingPlaceholder/LoadingPlaceholder";
import { VisuallyHiddenInput } from "../VisuallyHiddenInput";
import styles from "./FileUploader.module.scss";

type Props = {
	fileName?: string;
	buttonText?: string | ReactNode;
	buttonType?: ButtonTypes;
	loading?: boolean;
	inputProps?: InputBaseComponentProps;
	buttonProps?: BriaButtonProps;
	onUpload?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	onDelete?: () => Promise<void>;
	maxFilesLimit?: number;
	maxFileSize?: number;
	disabled?: boolean;
};

const FileUploader = ({
	fileName,
	buttonText,
	buttonType = "textMedium",
	loading = false,
	inputProps,
	buttonProps,
	onUpload,
	onDelete,
	maxFilesLimit = Infinity,
	maxFileSize = Infinity,
	disabled,
}: Props) => {
	const { t } = useTranslation("translation", { keyPrefix: "playground" });

	const handleUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
		const file = e.target.files?.[0];
		const files: File[] | null = Array.from(e.target.files || []);

		if (files.length > maxFilesLimit) {
			showErrorToast(t("imageToImage.filesExceedLimit"));
			return;
		}

		const oversizedFile = files.find((file) => file.size > maxFileSize);
		if (oversizedFile) {
			showErrorToast(t("imageToImage.fileExceedsSize"));
			return;
		}

		if (file) {
			await onUpload?.(e);
		}

		e.target.value = "";
	};

	const renderFileInput = () => (
		<VisuallyHiddenInput
			type="file"
			id="fileInput"
			style={{ display: "none" }}
			inputProps={inputProps}
			onChange={handleUpload}
		/>
	);

	const renderActions = () => (
		<Box className={styles.actionsContainer}>
			<Box
				className={clsx({
					[styles.disabledOpacity]: loading,
				})}
				component="label"
				sx={{ marginTop: "5px" }}
			>
				<EditIcon2 className={styles.actionBtn} />
				{!loading && renderFileInput()}
			</Box>
			{onDelete && (
				<DeleteIcon
					className={clsx({
						[styles.actionBtn]: true,
						[styles.disabledOpacity]: loading,
					})}
					onClick={onDelete}
				/>
			)}
		</Box>
	);

	return (
		<>
			{fileName !== undefined && (fileName || loading) ? (
				<Box className={styles.container}>
					<Box className={styles.fileName}>
						<LoadingPlaceholder className={styles.loading} isLoading={loading}>
							{fileName}
						</LoadingPlaceholder>
					</Box>
					{renderActions()}
				</Box>
			) : (
				<BriaButton
					disabled={disabled}
					buttonType={buttonType}
					loading={loading}
					component="label"
					{...buttonProps}
				>
					{renderFileInput()}
					{buttonText}
				</BriaButton>
			)}
		</>
	);
};

export default observer(FileUploader);
