import { Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import ColorPicker from "../../../../../components/common/ColorPicker/ColorPicker";
import TextColorPicker from "../../../../../components/common/TextColorPicker/TextColorPicker";
import InputLayout from "../../../../../layout/InputLayout/InputLayout";
import iframeStore from "../../../iframe-store.tsx";
import styles from "./IframeCustomStyle.module.scss";

const IframeCustomStyle = () => {
	const { iframeForm, handleFormChange } = iframeStore;
	const { t } = useTranslation("translation", { keyPrefix: "newIframes.editor.form.customStyle" });

	const handleCssVarChange = async (cssVar: string, value: string) => {
		handleFormChange("config", {
			...iframeForm.config,
			custom_style: {
				...iframeForm.config.custom_style,
				cssVars: {
					...iframeForm.config.custom_style?.cssVars,
					[cssVar]: value,
				},
			},
		});
	};

	return (
		<InputLayout label={t("primaryColor.label")}>
			<Box className={styles.colorWrapper}>
				<ColorPicker
					circleWidth="33px"
					color={iframeForm?.config.custom_style?.cssVars?.["primary_main_color"]}
					onColorChange={(color: string) => handleCssVarChange("primary_main_color", color)}
				/>
				<TextColorPicker
					color={iframeForm?.config.custom_style?.cssVars?.["primary_main_color"]}
					onColorChange={(color: string) => handleCssVarChange("primary_main_color", color)}
				/>
			</Box>
		</InputLayout>
	);
};

export default observer(IframeCustomStyle);
