import { FormControlLabel, FormGroup } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import BriaCheckbox from "../../../../../components/common/BriaCheckbox/BriaCheckbox";
import { ControlsIframeConfig } from "../../../../../models/new-iframe";
import iframeStore from "../../../iframe-store.tsx";

const IframeControls = () => {
	const { iframeForm, handleFormChange } = iframeStore;
	const { t } = useTranslation("translation", { keyPrefix: "newIframes.editor.form.controls" });

	const handleControlChange = (configKey: keyof ControlsIframeConfig, checked: boolean) => {
		handleFormChange("config", {
			...iframeForm.config,
			controls: { ...iframeForm.config.controls, [configKey]: checked },
		});
	};

	return (
		<FormGroup>
			{Object.entries(iframeForm.config.controls || {}).map(([key, value]) => (
				<FormControlLabel
					key={key}
					control={
						<BriaCheckbox
							checked={value}
							onChange={(e) => handleControlChange(key as keyof ControlsIframeConfig, e.target.checked)}
						/>
					}
					label={t(`checkboxes.${key}`)}
				/>
			))}
		</FormGroup>
	);
};

export default observer(IframeControls);
