import { Box, FormControlLabel } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import BriaCheckbox from "../../../../../../components/common/BriaCheckbox/BriaCheckbox";
import BriaColorPicker from "../../../../../../components/common/BriaColorPicker/BriaColorPicker";
import BriaInput from "../../../../../../components/common/BriaInput/BriaInput";
import BriaSlider from "../../../../../../components/common/BriaSlider/BriaSlider";
import { useImageToImageConfig } from "../../../../../../hooks/useImageToImageConfig";
import { useAppStore } from "../../../../../../hooks/useStores";
import InputLayout from "../../../../../../layout/InputLayout/InputLayout";
import { ConfigValue } from "../../../../../../models/image-to-image";
import Config from "../../Config/Config";
import styles from "./BackgroundConfig.module.scss";

const BackgroundConfig = () => {
	const { t } = useTranslation("translation", { keyPrefix: "playground.imageToImage.config.features.background" });
	const { imageToImageStore } = useAppStore();
	const { updateSelectedBackgroundConfigs } = useImageToImageConfig();
	const solidColor = imageToImageStore.config.background?.solid?.color_code || "#FFFFFF";
	const [activeConfig, setActiveConfig] = useState<string>("");
	const [fastVersion, setFastVersion] = useState<boolean>(
		imageToImageStore.config.background?.generateByText?.fast_version || true,
	);

	const handleBackgroundConfigChange = (field: string, value: ConfigValue) => {
		imageToImageStore.handleConfigChange("background", {
			...imageToImageStore.config.background,
			[field]: value,
		});
		updateSelectedBackgroundConfigs();
	};

	const handleSolidColorChange = (color: string) => {
		imageToImageStore.handleConfigChange("background", {
			...imageToImageStore.config.background,
			solid: {
				selected: true,
				color_code: color,
			},
		});

		updateSelectedBackgroundConfigs();
	};

	return (
		<Box className={styles.backgroundConfigContainer}>
			{/* Expand / Original background */}
			<Config
				title={t("expand")}
				checked={imageToImageStore.config.background?.expand}
				onClick={(e) => {
					const target = e.target as HTMLInputElement;
					handleBackgroundConfigChange("expand", target.checked);
				}}
			/>

			{/* Remove */}
			<Config
				title={t("remove")}
				checked={imageToImageStore.config.background?.remove}
				onClick={(e) => {
					const target = e.target as HTMLInputElement;
					handleBackgroundConfigChange("remove", target.checked);
				}}
			/>

			{/* Solid */}
			<Config
				title={t("solid")}
				checked={imageToImageStore.config.background?.solid?.selected}
				onClick={(e) => {
					const target = e.target as HTMLInputElement;
					handleBackgroundConfigChange("solid", {
						selected: target.checked,
						color_code: solidColor,
					});
					target.checked && setActiveConfig(t("solid"));
				}}
				isActive={activeConfig === t("solid")}
				setIsActive={setActiveConfig}
			>
				<Box className={styles.solidColorContainer}>
					<BriaColorPicker color={solidColor} onChange={handleSolidColorChange} showInputField />
				</Box>
			</Config>

			{/* Generate by text */}
			<Config
				title={t("generateByText")}
				checked={imageToImageStore.config.background?.generateByText?.selected}
				onClick={(e) => {
					const target = e.target as HTMLInputElement;
					handleBackgroundConfigChange("generateByText", {
						...imageToImageStore.config.background?.generateByText,
						selected: target.checked,
						fast_version: target.checked,
						prompt: !target.checked ? "" : imageToImageStore.config.background?.generateByText?.prompt,
					});
					target.checked && setActiveConfig(t("generateByText"));
				}}
				isActive={activeConfig === t("generateByText")}
				setIsActive={setActiveConfig}
			>
				<Box className={styles.accordionContainer}>
					<BriaInput
						value={imageToImageStore.config.background?.generateByText?.prompt}
						placeholder={t("describeTheBackground")}
						onChange={(e) => {
							const target = e.target as HTMLInputElement;
							handleBackgroundConfigChange("generateByText", {
								...imageToImageStore.config.background?.generateByText,
								prompt: target.value,
								selected: target.value.trim().length > 0,
							});
						}}
						multiline
						minRows={3}
						className={styles.modelDescription}
					/>
					<FormControlLabel
						onClick={(e) => {
							const target = e.target as HTMLInputElement;
							handleBackgroundConfigChange("generateByText", {
								...imageToImageStore.config.background?.generateByText,
								fast_version: target.checked,
							});
							setFastVersion(target.checked);
						}}
						className={styles.formControlContainer}
						control={<BriaCheckbox title={t("fastVersion")} />}
						label={t("fastVersion")}
						value={imageToImageStore.config.background?.generateByText?.fast_version}
						checked={fastVersion}
					/>
					<InputLayout label={t("numberOfImages.label")} labelClassName={styles.inputLabel}>
						<BriaSlider
							value={imageToImageStore.config.background?.generateByText?.num_results}
							className={styles.numberSlider}
							onChange={(_e, value) => {
								handleBackgroundConfigChange("generateByText", {
									...imageToImageStore.config.background?.generateByText,
									num_results: value as number,
								});
							}}
							min={1}
							step={1}
							max={4}
							marks={Array.from({ length: 4 }, (_, index) => ({
								value: index + 1,
								label: `${index + 1}`,
							}))}
						/>
					</InputLayout>
				</Box>
			</Config>
		</Box>
	);
};

export default observer(BackgroundConfig);
