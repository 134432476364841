import { AxiosError } from "axios";
import { makeAutoObservable, runInAction } from "mobx";
import { ChangeEvent } from "react";
import { ERRORS_TO_EXCLUDE } from "../../config/axios.tsx";
import {
	TAILORED_GENERATION_DATASET_STATUS,
	TAILORED_GENERATION_MODEL_STATUS,
	TAILORED_GENERATION_TYPES,
} from "../../constants/TGConstants.ts";
import { getSelectedOrganization } from "../../helpers/localStorage.ts";
import { IRootStore } from "../../mobx/root-store.tsx";
import { PaginatedItems } from "../../models/common.ts";
import {
	IDatasetImage,
	IGeneratedImage,
	ITailoredGenerationDataset,
	ITailoredGenerationEndpoint,
	ITailoredGenerationModel,
	TailoredGenerationBrandStyle,
	TailoredGenerationModelType,
} from "../../models/tailoredGeneration.ts";
import TailoredGenerationService from "../../services/TailoredGenerationService.ts";
import iframeStore from "../IframeNew/iframe-store.tsx";

export interface ITailoredGenerationStore {
	isLoading: boolean;
	loadingEndpoints: boolean;
	isDeleting: boolean;
	isUploading: boolean;
	isUploadingDataCSV: boolean;
	compareCSVAssetidsWithOriginalAssetids: boolean; // this flag controls the updating of manual captions in existing datasets. It indicates that asset identifiers in the CSV file should be compared with the original asset IDs of the dataset.
	isAutoCaption: boolean;
	isGenerating: boolean;
	successUploaded: number;
	failedUploaded: number;
	dismissWarning: boolean;
	exceedsMaxNumberOfAssetsWarning: null | string;
	rowsPerPage: number;
	paginatedDatasets: PaginatedItems<ITailoredGenerationDataset>;
	totalSelectedImages: number;
	isError: boolean;
	errorEndpoints: boolean;
	errorMessage: null | string;
	searchPrompt: string;
	generatedImages: IGeneratedImage[];
	progressPercentage: number;
	isValidDatasetName: boolean;
	isEmptyDescription: boolean;
	tailoredGenerationService: TailoredGenerationService;
	models: ITailoredGenerationModel[];
	endpoints: ITailoredGenerationEndpoint[];
	datasets: ITailoredGenerationDataset[];
	selectedModel: ITailoredGenerationModel | undefined;
	viewedDataset: ITailoredGenerationDataset | undefined; //  Used for viewing dataset that are not associated with any specific model.
	selectedDatasetOption: ITailoredGenerationDataset | undefined;
	selectedModelUsedToControlThePlayGroundTaps: ITailoredGenerationModel | undefined;
	modelForm: ITailoredGenerationModel;
	createNewDataset: boolean;
	handleFormChange: <K extends keyof ITailoredGenerationModel>(key: K, value: ITailoredGenerationModel[K]) => void;
	handleDeleteImage: (asset_id: string | undefined) => Promise<void>;
	handleUploadSelectMultiple: (event: ChangeEvent<HTMLInputElement>) => Promise<void>;
	uploadDatasetCSV: (file: File | undefined) => Promise<void>;

	loadDatasets(): Promise<void>;

	deleteDataset(dataset: ITailoredGenerationDataset): Promise<void>;

	loadModels(status?: TAILORED_GENERATION_MODEL_STATUS): Promise<ITailoredGenerationModel[]>;

	loadEndpoints(): Promise<ITailoredGenerationEndpoint[]>;

	findModelById(modelId: string): Promise<ITailoredGenerationModel>;

	findModelByDatasetId(datasetId: string): Promise<ITailoredGenerationModel>;

	getDatasetAssetsAsCSV(datasetId: string): Promise<string>;

	createModel(model: ITailoredGenerationModel): Promise<void>;

	updateModel(model: ITailoredGenerationModel): Promise<void>;

	deleteModel(model: ITailoredGenerationModel): Promise<void>;

	updateDataset(dataset: ITailoredGenerationDataset, dataSetCreatedFromDatasetManagement: boolean): Promise<void>;

	updateDatasetAssetsAutoCaptions(dataset: ITailoredGenerationDataset): Promise<void>;

	uploadDatasetImages(images: IDatasetImage[]): Promise<void>;

	setIsValidDatasetName(value: boolean): void;

	setIsEmptyDescription(value: boolean): void;

	clearModelForm(): void;

	generateModelImages(modelUrl: string): void;

	getModelsCount(): Promise<number>;

	pullUntilFileIsAvailable(fileUrl: string): Promise<void>;
}

export default class TailoredGenerationStore implements ITailoredGenerationStore {
	rootStore: IRootStore;
	isLoading: boolean = false;
	loadingEndpoints: boolean = false;
	isUploading: boolean = false;
	isUploadingDataCSV: boolean = false;
	compareCSVAssetidsWithOriginalAssetids: boolean = false;
	isAutoCaption: boolean = false;
	isGenerating: boolean = false;
	createNewDataset: boolean = false;
	isDeleting: boolean = false;
	successUploaded: number = 0;
	failedUploaded: number = 0;
	dismissWarning: boolean = false;
	exceedsMaxNumberOfAssetsWarning: null | string = null;
	totalSelectedImages: number = 0;
	isError: boolean = false;
	errorEndpoints: boolean = false;
	errorMessage: null | string = null;
	searchPrompt: string = "";
	generatedImages: IGeneratedImage[] = [];
	progressPercentage: number = 0;
	isValidDatasetName = true;
	isEmptyDescription = false;
	models = [];
	endpoints = [];
	datasets = [];
	selectedModel: ITailoredGenerationModel | undefined;
	viewedDataset: ITailoredGenerationDataset | undefined;
	selectedDatasetOption: ITailoredGenerationDataset | undefined;
	selectedModelUsedToControlThePlayGroundTaps: ITailoredGenerationModel | undefined;
	rowsPerPage: number = 20;
	paginatedDatasets: PaginatedItems<ITailoredGenerationDataset> = { total: 0, items: [] };
	tailoredGenerationService = new TailoredGenerationService();

	modelForm: ITailoredGenerationModel = {
		name: "",
		description: "",
		type: "",
		modelType: TailoredGenerationModelType.BRIA_2_3.name,
		status: TAILORED_GENERATION_MODEL_STATUS.CREATED,
		trainingProgress: 0,
		dataset: {
			name: "",
			assets: [],
			numberOfAssets: 0,
			description: "",
			brandStyleType: "",
			status: TAILORED_GENERATION_DATASET_STATUS.DRAFT,
		},
	};

	constructor(rootStore: IRootStore) {
		makeAutoObservable(this);
		this.rootStore = rootStore;
	}

	setIsValidDatasetName = async (value: boolean) => {
		try {
			runInAction(() => {
				this.isValidDatasetName = value;
			});
		} catch (e) {
			return Promise.reject(e);
		}
	};

	setIsEmptyDescription = async (value: boolean) => {
		try {
			runInAction(() => {
				this.isEmptyDescription = value;
			});
		} catch (e) {
			return Promise.reject(e);
		}
	};

	loadDatasets = async (): Promise<void> => {
		try {
			this.isLoading = true;
			const datasets = await this.tailoredGenerationService.get("datasets/");

			runInAction(() => {
				this.datasets = datasets;
				this.paginatedDatasets = {
					items: this.datasets.slice(0, this.rowsPerPage),
					total: this.datasets.length,
				};
				this.isLoading = false;
				this.isError = false;
			});
		} catch (e) {
			this.isLoading = false;
			this.isError = true;
			return Promise.reject(e);
		}
	};

	deleteDataset = async (dataset: ITailoredGenerationDataset): Promise<void> => {
		try {
			this.isDeleting = true;
			await this.tailoredGenerationService.delete(`datasets/${dataset.id}/`);

			runInAction(() => {
				this.isLoading = false;
				this.isError = false;
				this.isDeleting = false;
			});
		} catch (e) {
			this.isLoading = false;
			this.isDeleting = false;
			this.isError = true;
			return Promise.reject(e);
		}
	};

	loadModels = async (status?: TAILORED_GENERATION_MODEL_STATUS): Promise<ITailoredGenerationModel[]> => {
		try {
			this.isLoading = true;
			let filtered_models: any = [];
			if (getSelectedOrganization()?.organization.uid) {
				filtered_models = await this.tailoredGenerationService.get(
					status ? `models/?status=${status}` : "models/",
				);
				const iframeTgModels = iframeStore.iframe?.config.tg_config?.enabled_models;
				if (iframeTgModels?.length) {
					filtered_models = filtered_models.filter((model: any) => iframeTgModels.includes(model?.id));
				}
			}

			filtered_models = filtered_models;

			runInAction(() => {
				this.models = filtered_models;
				this.isLoading = false;
				this.isError = false;
			});

			return this.models;
		} catch (e) {
			this.isLoading = false;
			this.isError = true;
			return Promise.reject(e);
		}
	};

	loadEndpoints = async (): Promise<ITailoredGenerationEndpoint[]> => {
		try {
			this.loadingEndpoints = true;
			const endpoints = await this.tailoredGenerationService.get("endpoints/");

			runInAction(() => {
				this.endpoints = endpoints;
				this.loadingEndpoints = false;
				this.errorEndpoints = false;
			});

			return this.endpoints;
		} catch (e) {
			this.loadingEndpoints = false;
			this.errorEndpoints = true;
			return Promise.reject(e);
		}
	};

	findModelById = async (modelId: string): Promise<ITailoredGenerationModel> => {
		try {
			this.isLoading = true;
			const model = await this.tailoredGenerationService.get(`models/${modelId}/`);
			runInAction(() => {
				this.isLoading = false;
				this.isError = false;
			});
			return Promise.resolve(model);
		} catch (e) {
			this.isLoading = false;
			this.isError = true;
			return Promise.reject(e);
		}
	};
	findModelByDatasetId = async (datasetId: string): Promise<ITailoredGenerationModel> => {
		try {
			this.isLoading = true;
			const model = await this.tailoredGenerationService.get(`models/dataset/${datasetId}/`);
			runInAction(() => {
				this.isLoading = false;
				this.isError = false;
			});
			return Promise.resolve(model);
		} catch (e) {
			this.isLoading = false;
			this.isError = true;
			return Promise.reject(e);
		}
	};

	createModel = async (model: ITailoredGenerationModel): Promise<void> => {
		try {
			this.isLoading = true;
			this.errorMessage = null;
			model.trainingProgress = 0;
			model.status = TAILORED_GENERATION_MODEL_STATUS.IN_PROGRESS;
			const updated_models = await this.tailoredGenerationService.post("models/", model);

			runInAction(() => {
				this.models = updated_models;
				this.isLoading = false;
				this.isError = false;
			});
		} catch (e) {
			this.isLoading = false;
			this.isError = true;
			if (e instanceof AxiosError) {
				const errorMessage = e?.response?.data?.message ?? "";
				if (ERRORS_TO_EXCLUDE.includes(errorMessage)) {
					this.errorMessage = errorMessage;
				}
			}
			this.rootStore.uiStore.hideDialog("TGPublishToTrainingDialog");
			return Promise.reject(e);
		}
	};

	updateModel = async (model: ITailoredGenerationModel): Promise<void> => {
		try {
			this.errorMessage = null;
			this.isLoading = true;
			const updated_models = await this.tailoredGenerationService.put("models/", model);

			runInAction(() => {
				this.models = updated_models;
				this.isLoading = false;
				this.isError = false;
			});
		} catch (e) {
			this.isLoading = false;
			this.isError = true;
			return Promise.reject(e);
		}
	};

	deleteModel = async (model: ITailoredGenerationModel): Promise<void> => {
		try {
			this.isLoading = true;
			const updated_models = await this.tailoredGenerationService.delete(`models/${model.id}/`);

			runInAction(() => {
				this.models = updated_models;
				this.isLoading = false;
				this.isError = false;
			});
		} catch (e) {
			this.isLoading = false;
			this.isError = true;
			return Promise.reject(e);
		}
	};

	uploadDatasetImages = async (images: IDatasetImage[]): Promise<void> => {
		try {
			let stopProcess = false;
			runInAction(() => {
				this.isLoading = true;
				this.isUploading = true;
				this.successUploaded = 0;
				this.failedUploaded = 0;
				this.dismissWarning = false;
				this.totalSelectedImages = images.length;
				this.errorMessage = null;
				this.exceedsMaxNumberOfAssetsWarning = null;
				this.progressPercentage = 5;
				if (
					this.modelForm.type == TAILORED_GENERATION_TYPES.SINGLE_SUBJECT &&
					this.modelForm.dataset.assets.length + images.length > 20
				) {
					if (this.modelForm.dataset.assets.length > 20 || this.modelForm.dataset.assets.length < 10) {
						this.errorMessage = ERRORS_TO_EXCLUDE[3];
					} else {
						this.exceedsMaxNumberOfAssetsWarning = ERRORS_TO_EXCLUDE[3];
					}
					stopProcess = true;
				} else if (
					this.modelForm.type == TAILORED_GENERATION_TYPES.STYLE &&
					this.modelForm.dataset.assets.length + images.length > 200
				) {
					if (this.modelForm.dataset.assets.length > 200 || this.modelForm.dataset.assets.length < 20) {
						this.errorMessage = ERRORS_TO_EXCLUDE[0];
					} else {
						this.exceedsMaxNumberOfAssetsWarning = ERRORS_TO_EXCLUDE[0];
					}
					stopProcess = true;
				} else if (
					this.modelForm.type == TAILORED_GENERATION_TYPES.ICONS &&
					this.modelForm.dataset.assets.length + images.length > 50
				) {
					if (this.modelForm.dataset.assets.length > 50 || this.modelForm.dataset.assets.length < 20) {
						this.errorMessage = ERRORS_TO_EXCLUDE[0];
					} else {
						this.exceedsMaxNumberOfAssetsWarning = ERRORS_TO_EXCLUDE[0];
					}
					stopProcess = true;
				}
			});
			if (stopProcess) {
				this.isLoading = false;
				this.isUploading = false;
				this.progressPercentage = 0;
				this.successUploaded = 0;
				this.failedUploaded = 0;
				return;
			}

			const UPLOAD_BATCH_SIZE = 1;
			const batches = Math.ceil(images.length / UPLOAD_BATCH_SIZE);

			for (let i = 0; i < batches; i++) {
				const startIdx = i * UPLOAD_BATCH_SIZE;
				const endIdx = (i + 1) * UPLOAD_BATCH_SIZE;
				const batchImages = images.slice(startIdx, endIdx);
				const brand_style_type =
					this.modelForm.type === TAILORED_GENERATION_TYPES.SINGLE_SUBJECT
						? TailoredGenerationBrandStyle.CHARACTER.name
						: "";
				try {
					const updated_dataset = await this.tailoredGenerationService.post(
						`models/dataset/${this.modelForm.dataset.id}/upload/?brand_style_type=${brand_style_type}&createNewDataset=${this.createNewDataset}`,
						batchImages,
						{
							"Content-Type": "multipart/form-data",
						},
					);
					runInAction(() => {
						this.createNewDataset = false;
						updated_dataset.name = this.modelForm.dataset.name;
						updated_dataset.description = this.modelForm.dataset.description;
						this.handleFormChange("dataset", updated_dataset);
						this.progressPercentage = ((i + 1) / batches) * 100;
						this.successUploaded = this.successUploaded + 1;
					});
				} catch (e: any) {
					runInAction(() => {
						this.progressPercentage = ((i + 1) / batches) * 100;
						if (
							e?.response?.data?.message == ERRORS_TO_EXCLUDE[1] ||
							e?.response?.data?.message == ERRORS_TO_EXCLUDE[2]
						) {
							this.failedUploaded = this.failedUploaded + 1;
						}
					});
				}
			}

			runInAction(() => {
				this.isLoading = false;
				this.isError = false;
				this.isUploading = false;
				this.progressPercentage = 100;
				if (
					this.modelForm.type == TAILORED_GENERATION_TYPES.SINGLE_SUBJECT &&
					(this.modelForm.dataset.assets.length < 10 || this.modelForm.dataset.assets.length > 20)
				) {
					this.errorMessage = ERRORS_TO_EXCLUDE[3];
				} else if (
					this.modelForm.type == TAILORED_GENERATION_TYPES.STYLE &&
					(this.modelForm.dataset.assets.length < 20 || this.modelForm.dataset.assets.length > 200)
				) {
					this.errorMessage = ERRORS_TO_EXCLUDE[0];
				}
			});
		} catch (e) {
			runInAction(() => {
				this.isLoading = false;
				this.isUploading = false;
				this.isError = true;
				this.progressPercentage = 0;
				this.successUploaded = 0;
				this.failedUploaded = 0;
				return Promise.reject(e);
			});
		}
	};

	updateDataset = async (
		dataset: ITailoredGenerationDataset,
		dataSetCreatedFromDatasetManagement: boolean,
	): Promise<void> => {
		try {
			this.isLoading = true;
			let createNewDS = false;
			const brand_style_type =
				this.modelForm.type === TAILORED_GENERATION_TYPES.SINGLE_SUBJECT
					? TailoredGenerationBrandStyle.CHARACTER.name
					: "";
			if (
				this.selectedDatasetOption &&
				this.selectedDatasetOption.id == dataset.id &&
				(dataset.description != this.selectedDatasetOption?.description ||
					dataset.name != this.selectedDatasetOption.name)
			) {
				createNewDS = true;
			}
			const updated_dataset = await this.tailoredGenerationService.post(`models/dataset/`, {
				dataset,
				dataSetCreatedFromDatasetManagement,
				createNewDS,
				brand_style_type,
			});

			runInAction(() => {
				this.createNewDataset = false;
				this.handleFormChange("dataset", updated_dataset);
				this.isLoading = false;
				this.isError = false;
			});
		} catch (e) {
			this.isLoading = false;
			this.isError = true;
			return Promise.reject(e);
		}
	};

	handleFormChange = <K extends keyof ITailoredGenerationModel>(key: K, value: ITailoredGenerationModel[K]): void => {
		try {
			runInAction(() => {
				this.modelForm = {
					...this.modelForm,
					[key]: value,
				};
			});
		} catch (e) {
			this.isLoading = false;
			this.isError = true;
		}
	};

	handleDeleteImage = async (asset_id: string | undefined) => {
		try {
			const brandStyleType =
				this.modelForm.type === TAILORED_GENERATION_TYPES.SINGLE_SUBJECT
					? TailoredGenerationBrandStyle.CHARACTER.name
					: "";
			if (asset_id) {
				const updated_dataset = await this.tailoredGenerationService.delete(
					`models/dataset/${this.modelForm.dataset.id}/assets/${asset_id}/?createNewDataset=${this.createNewDataset}&brand_style_type=${brandStyleType}`,
				);

				runInAction(() => {
					this.createNewDataset = false;
					updated_dataset.name = this.modelForm.dataset.name;
					updated_dataset.description = this.modelForm.dataset.description;
					this.handleFormChange("dataset", updated_dataset);
					this.isLoading = false;
					this.isError = false;
					this.errorMessage = null;
					if (
						this.modelForm.type == TAILORED_GENERATION_TYPES.SINGLE_SUBJECT &&
						(this.modelForm.dataset.assets.length < 10 || this.modelForm.dataset.assets.length > 20)
					) {
						this.errorMessage = ERRORS_TO_EXCLUDE[3];
					} else if (
						this.modelForm.type == TAILORED_GENERATION_TYPES.STYLE &&
						(this.modelForm.dataset.assets.length < 20 || this.modelForm.dataset.assets.length > 200)
					) {
						this.errorMessage = ERRORS_TO_EXCLUDE[0];
					}
				});
			}
		} catch (e) {
			this.isLoading = false;
			this.isError = true;
		}
	};

	handleUploadSelectMultiple = async (event: ChangeEvent<HTMLInputElement>): Promise<void> => {
		try {
			runInAction(() => {
				const selectedFiles = event.target.files;
				if (selectedFiles && selectedFiles.length > 0) {
					const newImages: IDatasetImage[] = Array.from(selectedFiles).map((file: File) => ({
						name: file.name,
						imageBytes: file,
						caption: "",
						imageUrl: "",
					}));

					const existingImages = this.modelForm.dataset.assets;

					// Filter out images that are already in the list
					const uniqueNewImages = newImages.filter(
						(newImage) => !existingImages.some((existingImage) => existingImage.name === newImage.name),
					);

					this.uploadDatasetImages(uniqueNewImages);
				}
			});
		} catch (e) {
			this.isLoading = false;
			this.isError = true;
		}
	};

	generateModelImages = async (modelUrl: string): Promise<void> => {
		this.isGenerating = true;
		this.isError = false;
		this.errorMessage = null;
		const generatedParams = {
			prompt: this.searchPrompt,
			negative_prompt: "",
			size: [1024, 1024],
			num_results: 4,
			steps_num: 8,
			disable_safety_checker: true,
			// text_guidance_scale: 1.0,
			url: modelUrl,
		};
		try {
			const response = await this.tailoredGenerationService.post("models/generate/", generatedParams);
			runInAction(() => {
				this.generatedImages = response.result.map((img: IGeneratedImage) => {
					if (img.urls && img.urls.length > 0) {
						return {
							...img,
							finalUrl: img.urls[0],
							fake_url: false,
						};
					} else {
						throw new Error(img.description);
					}
				});
				this.isGenerating = false;
			});
		} catch (e: any) {
			this.generatedImages = [];
			this.isGenerating = false;
			this.isError = true;
			if (e.message == "Query doesn't stand with Bria's ethic rules") {
				this.errorMessage = "Prompt doesn't stand with BRIA's ethic rules.";
			} else {
				this.errorMessage = "Something went wrong, Please try again later.";
			}
		}
	};

	pullUntilFileIsAvailable = async (fileUrl: string): Promise<void> => {
		try {
			if (!fileUrl) {
				return Promise.reject();
			}

			await this.tailoredGenerationService.post("models/playground/image/pull_until_available/", {
				file_url: fileUrl,
				max_attempts: 120,
				sleep_secs: 1,
			});
		} catch (e) {
			this.isLoading = false;
			this.isError = true;
		}
	};

	clearModelForm = () => {
		try {
			runInAction(() => {
				this.modelForm = {
					name: "",
					description: "",
					modelType: TailoredGenerationModelType.BRIA_2_3.name,
					status: TAILORED_GENERATION_MODEL_STATUS.CREATED,
					trainingProgress: 0,
					dataset: {
						name: "",
						assets: [],
						numberOfAssets: 0,
						description: "",
						brandStyleType: "",
						status: TAILORED_GENERATION_DATASET_STATUS.DRAFT,
					},
					type: "",
				};
			});
		} catch (e) {
			this.isLoading = false;
			this.isError = true;
		}
	};

	getModelsCount = async () => {
		try {
			const response = await this.tailoredGenerationService.get("models/count/");
			return Promise.resolve(response.message ?? 0);
		} catch (e) {
			this.isLoading = false;
			this.isError = true;
			return Promise.reject(e);
		}
	};

	getDatasetAssetsAsCSV = async (datasetId: string): Promise<string> => {
		try {
			this.isLoading = true;
			const csvData = await this.tailoredGenerationService.get(`models/dataset/preparecsv/${datasetId}/`);
			runInAction(() => {
				this.isLoading = false;
				this.isError = false;
				this.compareCSVAssetidsWithOriginalAssetids = false;
			});
			return Promise.resolve(csvData);
		} catch (e) {
			this.isLoading = false;
			this.isError = true;
			return Promise.reject(e);
		}
	};

	uploadDatasetCSV = async (file: File | undefined): Promise<void> => {
		try {
			this.isUploadingDataCSV = true;
			this.isLoading = true;
			const brand_style_type =
				this.modelForm.type === TAILORED_GENERATION_TYPES.SINGLE_SUBJECT
					? TailoredGenerationBrandStyle.CHARACTER.name
					: "";

			const csvFile = file;
			if (csvFile) {
				const formData = new FormData();
				formData.append("file", csvFile);
				const updated_dataset = await this.tailoredGenerationService.post(
					`models/dataset/${this.modelForm.dataset.id}/uploadcsv/?brand_style_type=${brand_style_type}&create_new_dataset=${this.createNewDataset}&compareCSVAssetidsWithOriginalAssetids=${this.compareCSVAssetidsWithOriginalAssetids}`,
					formData,
					{
						"Content-Type": "multipart/form-data",
					},
				);
				runInAction(() => {
					if (updated_dataset.id != this.modelForm.dataset.id) {
						this.compareCSVAssetidsWithOriginalAssetids = true;
					}
					this.isUploadingDataCSV = false;
					this.isLoading = false;
					this.createNewDataset = false;
					updated_dataset.name = this.modelForm.dataset.name;
					updated_dataset.description = this.modelForm.dataset.description;
					this.handleFormChange("dataset", updated_dataset);
				});
			}
		} catch (e) {
			this.isUploadingDataCSV = false;
			this.isLoading = false;
			this.isError = true;
			return Promise.reject(e);
		}
	};

	updateDatasetAssetsAutoCaptions = async (dataset: ITailoredGenerationDataset): Promise<void> => {
		try {
			this.isAutoCaption = true;
			this.isLoading = true;
			const brand_style_type =
				this.modelForm.type === TAILORED_GENERATION_TYPES.SINGLE_SUBJECT
					? TailoredGenerationBrandStyle.CHARACTER.name
					: "";
			const createNewDS = this.createNewDataset;
			const updated_dataset = await this.tailoredGenerationService.post(`models/dataset/autocaption/`, {
				dataset,
				createNewDS,
				brand_style_type,
			});

			runInAction(() => {
				this.createNewDataset = false;
				if (updated_dataset) {
					this.handleFormChange("dataset", updated_dataset);
				}
				this.isLoading = false;
				this.isError = false;
				this.isAutoCaption = false;
			});
		} catch (e) {
			this.isLoading = false;
			this.isError = true;
			this.isAutoCaption = false;
			return Promise.reject(e);
		}
	};
}
