import { Mark } from "@mui/base/useSlider";
import type { Header, PostData } from "har-format";
import { MutableRefObject } from "react";
import { DropDownItem } from "../components/common/BriaDropDown/BriaDropDown.tsx";
import { PlaygroundImage } from "./image-to-image";
import { ruleSetType } from "../utils/validators";

export enum SandboxAPIType {
	ImageModifications = "ImageModifications",
	ECommerceSuite = "ECommerceSuite",
}

export enum ImageModificationsSubAPIType {
	RemoveBackground = "remove-background",
	BlurBackground = "blur-background",
	GenerateBackground = "generate-background",
	ExpandImage = "expand-image",
	IncreaseResolution = "increase-resolution",
	DelayerImage = "delayer-image",
	CropOutForeground = "crop-out-foreground",
	GetMasks = "get-masks",
	Eraser = "eraser",
}

export enum eCommerceSuiteSubAPIType {
	ProductCutout = "product-cutout",
	ProductPackshot = "product-packshot",
	ProductShadow = "product-shadow",
	LifestyleProductShotByText = "lifestyle-product-shot-by-text",
}

export type SandboxSubAPITypes = ImageModificationsSubAPIType | eCommerceSuiteSubAPIType;

export interface APIParameter {
	name: string;
	type: string;
	label: string;
	placeholder?: string | string[];
	info?: string;
	required?: boolean;
	defaultValue?: any;
	min?: number;
	max?: number;
	step?: number;
	dropdownOptions?: DropDownItem<any>[];
	hidden?: boolean;
	numberOfInputs?: number;
	customEndAdornmentText?: string;
	className?: string;
	selectMultipleCheckbox?: boolean;
	multiple?: boolean;
	valueLabelDisplay?: "on" | "off" | "auto";
	height?: string;
	minRows?: number;
	multiline?: boolean;
	marks?: boolean | Mark[];
	action?: string;
	validationMessage?: string | null;
	validationSchema?: ruleSetType;
}

interface APIConfig {
	id: string;
	apiName: string;
	endpoint: string;
	docLink: string;
	method: string;
	contentType?: string;
	nonIsolated?: boolean;
	parameters: APIParameter[];
}

export interface ApiRequestData {
	method: string;
	url: string;
	headers: Header[];
	postData?: PostData;
}

export type SandboxAPIConfigType = {
	apiConfig: APIConfig | null;
	selectedAPI: SandboxAPIType;
	selectedSubAPI?: SandboxSubAPITypes;
	original_image: PlaygroundImage;
	num_results?: number;
	apiRequestData?: ApiRequestData;
};

export type sandboxResultImage = {
	result_url: string;
	seed?: number;
	description?: string;
}

export type IBrushSettings = {
	lineJoin: CanvasLineJoin;
	lineCap: CanvasLineCap;
	strokeStyle: string;
	lineWidth: number;
	globalCompositeOperation: GlobalCompositeOperation;
	globalAlpha: number;
	brushColorOpacity: number;
};

export type BrushCanvasRefs = {
	canvasRef: MutableRefObject<HTMLCanvasElement | null>;
	canvasOverlayRef: MutableRefObject<HTMLCanvasElement | null>;
};
