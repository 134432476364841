import CloseIcon from "@mui/icons-material/CloseTwoTone";
import { Box, Divider, Link, Modal, Tab, Tabs, Typography } from "@mui/material";
import clsx from "clsx";
import { ClientId, HTTPSnippet, HarRequest, TargetId } from "httpsnippet-lite";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DeleteIcon from "../../../../assets/images/icons/DeleteIcon";
import FullscreenIcon from "../../../../assets/images/icons/Image/FullscreenIcon";
import BriaButton from "../../../../components/common/BriaButton/BriaButton.tsx";
import BriaTooltip from "../../../../components/common/BriaTooltip/BriaTooltip.tsx";
import CopyToClipboardButton from "../../../../components/common/CopyToClipboardButton/CopyToClipboardButton";
import { BriaImageProps } from "../../../../components/common/Galleries/BriaImage.tsx";
import LoadingAnimation from "../../../../components/common/LoadingAnimation/LoadingAnimation.tsx";
import { useAppStore } from "../../../../hooks/useStores.tsx";
import { ImageToImageConfigType, PlaygroundImage, PlaygroundResult } from "../../../../models/image-to-image";
import { ApiRequestData, SandboxAPIConfigType } from "../../../../models/sandboxAPI.ts";
import PlaygroundImageOverlay from "../../Images/Overlay/PlaygroundImageOverlay";
import SandboxAPIGallery from "./Gallery/SandboxAPIGallery";
import styles from "./SandboxAPIResult.module.scss";
// TODO: move all the code generation modal related codes into a separate component
import hljs from "highlight.js/lib/core";
import csharp from "highlight.js/lib/languages/csharp";
import javascript from "highlight.js/lib/languages/javascript";
import php from "highlight.js/lib/languages/php";
import python from "highlight.js/lib/languages/python";
import ruby from "highlight.js/lib/languages/ruby";
import shell from "highlight.js/lib/languages/shell";
import "highlight.js/styles/a11y-dark.min.css";
import BriaCanvas from "../../../../components/common/Galleries/BriaCanvas.tsx";
import LoadingPlaceholder from "../../../../components/common/LoadingPlaceholder/LoadingPlaceholder.tsx";
import { isCanvasRequiredForThisApp } from "../../../../utils";

hljs.registerLanguage("javascript", javascript);
hljs.registerLanguage("python", python);
hljs.registerLanguage("csharp", csharp);
hljs.registerLanguage("ruby", ruby);
hljs.registerLanguage("php", php);
hljs.registerLanguage("shell", shell);

type codeSnippetType = string | string[] | null;
type Props = {
	playgroundResult: PlaygroundResult;
};

const SandboxAPIResult = ({ playgroundResult }: Props) => {
	const { t } = useTranslation("translation", { keyPrefix: "playground.sandboxAPI.result" });
	const { playgroundStore, sandboxAPIStore } = useAppStore();
	const [openFullScreen, setOpenFullScreen] = useState(false);
	const [openCodeModal, setOpenCodeModal] = useState(false);
	const [codeSnippet, setCodeSnippet] = useState<codeSnippetType>();
	const [highlightedSnippet, setHighlightedSnippet] = useState<string>();
	const [apiData, setApiData] = useState<ApiRequestData>();
	const loading = !!playgroundResult.images.filter((image) => image.loading).length;
	const [tabIndex, setTabIndex] = useState(0);
	const codeTabs = [
		{ label: "Python", targetId: "python", clientId: "requests" },
		{ label: "Node", targetId: "node" },
		{ label: "JavaScript", targetId: "javascript" },
		{ label: "C#", targetId: "csharp" },
		{ label: "PHP", targetId: "php" },
		{ label: "Ruby", targetId: "ruby" },
		{ label: "cURL", targetId: "shell" },
	];

	useEffect(() => {
		const updateSnippet = async () => {
			if (apiData) {
				const snippet = await generateCodeSnippet(
					apiData,
					codeTabs[tabIndex].targetId as TargetId,
					codeTabs[tabIndex].clientId,
				);
				setCodeSnippet(snippet);
			}
		};
		updateSnippet();
	}, [tabIndex]);

	const generateCodeSnippet = async (
		apiRequestData: ApiRequestData,
		codeTarget: TargetId,
		codeClient?: ClientId,
	): Promise<codeSnippetType> => {
		if (!apiRequestData) return null;
		const { method, url, headers, postData } = apiRequestData;
		const request: HarRequest = {
			method: method.toUpperCase(),
			url,
			headers,
			postData,
			httpVersion: "HTTP/1.1",
			cookies: [],
			queryString: [],
			headersSize: -1,
			bodySize: -1,
		};
		const snippet = new HTTPSnippet(request);
		const code = await snippet.convert(codeTarget, codeClient);
		const highlightedCode = hljs.highlight(code as string, {
			language: codeTarget === "node" ? "javascript" : codeTarget,
		}).value;
		setHighlightedSnippet(highlightedCode);
		return code;
	};

	const handleOpenCodeModal = async (apiRequestData: ApiRequestData) => {
		setApiData(apiRequestData);
		const snippet = await generateCodeSnippet(
			apiRequestData,
			codeTabs[tabIndex].targetId as TargetId,
			codeTabs[tabIndex].clientId,
		);
		setCodeSnippet(snippet);
		setOpenCodeModal(true);
	};

	const handleClose = () => setOpenCodeModal(false);

	const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
		setTabIndex(newValue);
	};

	return (
		<Box
			className={clsx(styles.itiGalleryWrapper, styles.resultRow, "galleryWrapper", {
				[styles.fullHeightGallery]: isCanvasRequiredForThisApp(),
			})}
		>
			{isCanvasRequiredForThisApp() ? (
				<LoadingPlaceholder isLoading={sandboxAPIStore.isUploadingImage} className={styles.loadingContainer}>
					<BriaCanvas
						key={0}
						{...playgroundResult.images[0]}
						isLoading={sandboxAPIStore.isLoading}
						canvasRef={(canvas: HTMLCanvasElement | null) => {
							if (!sandboxAPIStore.brushCanvasRefs[0]) {
								sandboxAPIStore.brushCanvasRefs[0] = {
									canvasRef: { current: null },
									canvasOverlayRef: { current: null },
								};
							}

							sandboxAPIStore.brushCanvasRefs[0].canvasRef.current = canvas;
						}}
						canvasOverlayRef={(overlayCanvas: HTMLCanvasElement | null) => {
							if (!sandboxAPIStore.brushCanvasRefs[0]) {
								sandboxAPIStore.brushCanvasRefs[0] = {
									canvasRef: { current: null },
									canvasOverlayRef: { current: null },
								};
							}
							sandboxAPIStore.brushCanvasRefs[0].canvasOverlayRef.current = overlayCanvas;
						}}
					/>
				</LoadingPlaceholder>
			) : (
				<>
					<Box className={styles.header}>
						{!isCanvasRequiredForThisApp() && (
							<>
								{playgroundResult.isFromUpload ? (
									<Box className={styles.headerText}>{t("uploadHeader")}</Box>
								) : (
									<Box className={styles.headerText}>{t("header")}</Box>
								)}

								{loading ? (
									<LoadingAnimation loading={loading} progressBarTime={6} showAnimation={false} />
								) : (
									<Box className={styles.actionBtns}>
										{!playgroundResult.isFromUpload && playgroundResult.apiRequestData && (
											<BriaButton
												onClick={() =>
													playgroundResult.apiRequestData
														? handleOpenCodeModal(playgroundResult.apiRequestData)
														: false
												}
												className={clsx({
													[styles.button]: true,
												})}
												buttonType="primaryMedium"
											>
												{t("viewCode")}
											</BriaButton>
										)}
										<BriaTooltip title={t("delete")}>
											<DeleteIcon
												className={styles.iconButton}
												onClick={() => {
													if (playgroundResult.isFromUpload) {
														sandboxAPIStore.uploadImageSrc = undefined;
													}
													playgroundStore.deleteResult(playgroundResult.id);
												}}
											/>
										</BriaTooltip>
										<BriaTooltip title={t("fullScreen")}>
											<FullscreenIcon
												className={styles.iconButton}
												onClick={() => setOpenFullScreen(true)}
											/>
										</BriaTooltip>
									</Box>
								)}
							</>
						)}
					</Box>
					<SandboxAPIGallery
						images={playgroundResult.images.map(
							(image) =>
								({
									ImageOverlay: <PlaygroundImageOverlay {...{ image }} />,
									displayOverlay: "customHover",
									selectable: true,
									htmlJsonData: image.id,
									fullScreenProps: {
										compareTo: (image.config as ImageToImageConfigType)?.original_image.url,
										fileName: playgroundStore.getFileName(image),
									},
									onSuccessPulling: async () => {
										playgroundResult?.isFromUpload &&
											playgroundStore.selectImages(playgroundResult.images);
										playgroundStore.onSuccessResult(image);
									},
									onErrorPulling: (errorType) => playgroundStore.onErrorResult(image, errorType),
									async: loading,
									asyncProps: { maxAttempts: 240 },
									...image,
								}) as BriaImageProps & PlaygroundImage,
						)}
						fullScreenProps={{
							open: openFullScreen,
							onClose: () => setOpenFullScreen(false),
							displayMode: "scroll",
							hideDownloadIcon: true,
						}}
						className={styles.gallery}
					/>
				</>
			)}

			<Modal className={styles.CodeModal} open={openCodeModal} onClose={handleClose}>
				<Box className={styles.ModalCenter}>
					<CloseIcon onClick={handleClose} className={styles.closeIcon} />
					<Typography className={styles.ModalHeader} variant="h6" component="h2">
						{t("code")}
					</Typography>
					{codeSnippet && highlightedSnippet && (
						<Box className={styles.ModalContent}>
							<Tabs value={tabIndex} onChange={handleTabChange} className={styles.codeTabs}>
								{codeTabs.map((tab, index) => (
									<Tab key={index} label={tab.label} />
								))}
							</Tabs>
							<Box>
								<pre>
									<code dangerouslySetInnerHTML={{ __html: highlightedSnippet }} />
								</pre>
							</Box>
							<Divider className={styles.bottomDivider} />
							<Box className={styles.modalFooter}>
								<Link
									className={styles.apiDocLink}
									href={(playgroundResult.config as SandboxAPIConfigType)?.apiConfig?.docLink}
									target={"_blank"}
									rel="noopener"
								>
									{t("apiDoc")}
								</Link>
								<CopyToClipboardButton
									className={styles.CopyButton}
									textToCopy={typeof codeSnippet === "string" ? codeSnippet : codeSnippet?.join("\n")}
									buttonText={t("copyCode")}
									buttonType="primaryMedium"
								/>
							</Box>
						</Box>
					)}
				</Box>
			</Modal>
		</Box>
	);
};

export default observer(SandboxAPIResult);
