import {
	Avatar,
	Box,
	CircularProgress,
	FormControlLabel,
	FormGroup,
	Menu,
	MenuItem,
	Switch,
	TextField,
	Typography,
} from "@mui/material";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { ChangeEvent, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import Logo from "../../../../../assets/images/svgs/Bria-logo.svg";
import BriaButton from "../../../../../components/common/BriaButton/BriaButton";
import BriaIconButton from "../../../../../components/common/BriaIconButton/BriaIconButton";
import ConfirmationPopup from "../../../../../components/common/ConfirmationPopup/ConfirmationPopup";
import EditableChip from "../../../../../components/common/EditableChip/EditableChip.tsx";
import OrganizationsDropdown from "../../../../../components/common/OrganizationsDropdown/OrganizationsDropdown";
import RouterConstants from "../../../../../constants/RouterConstants";
import { USER_DEFAULT_AVATAR } from "../../../../../constants/UserConstants";
import { getSelectedOrganization } from "../../../../../helpers/localStorage.ts";
import useSecureNavigate from "../../../../../hooks/useSecureNavigate.tsx";
import { useAppStore } from "../../../../../hooks/useStores";
import InputLayout from "../../../../../layout/InputLayout/InputLayout.tsx";
import { CampaignEntityStatus, CampaignViewModes } from "../../../../../models/common";
import iframeStore from "../../../../../pages/IframeNew/iframe-store.tsx";
import useCampaignHeaderUtils from "../../../../CustomUtils/CampaignHeaderUtils.tsx";
import useScenesUtils from "../../../../CustomUtils/ScenesUtils.tsx";
import useTemplateUtils from "../../../../CustomUtils/UseTemplateUtils.tsx";
import { TemplatePreviewBtn } from "../../../../campaign-store.tsx";
import Icons from "../../../../components/Icons";
import { DesignEditorContext } from "../../../../contexts/DesignEditor.tsx";
import useDesignEditorContext from "../../../../hooks/useDesignEditorContext.ts";
import { SecondaryTabTypeEnum } from "../Panels/panelItems/index.ts";
import styles from "./CampaignHeader.module.scss";

const CampaignHeader: React.FC = () => {
	const { setScenes } = useContext(DesignEditorContext);
	const { updateScenes } = useScenesUtils();
	const { setCurrentScene } = useDesignEditorContext();
	const { saveCampaign, saveTemplate, saveNewCampaign, saveNewTemplate } = useCampaignHeaderUtils();
	const {
		campaignStore,
		designEditorStore,
		authStore: authStore,
		authStore: { user, logout },
		textToImageStore,
		playgroundStore,
		imagesStore,
	} = useAppStore();
	const { isPopupView } = useContext(DesignEditorContext);
	const { t } = useTranslation("translation", { keyPrefix: "editor.header" });
	const { t: campaignTranslation } = useTranslation("translation", { keyPrefix: "editor.popup.campaign" });
	const { t: templateTranslation } = useTranslation("translation", { keyPrefix: "editor.popup.template" });
	const { t: t1 } = useTranslation();
	const [viewMode, setViewMode] = useState<CampaignViewModes>(campaignStore.viewMode);
	const { handleDownloadTemplate } = useTemplateUtils();
	const [isTemplateDownloading, setIsTemplateDownloading] = useState<boolean>(false);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const isAdminMode = campaignStore.isAdminMode;
	const editor = campaignStore.editor;
	const [openModal, setOpenModal] = useState<boolean>(false);
	const [saveNewTemplateClicked, setSaveNewTemplateClicked] = useState<boolean>(false);
	const [templateName, setTemplateName] = useState<string>("");
	const [isValidTemplateName, setIsValidTemplateName] = useState<boolean>(true);
	const displayedTemplateName =
		campaignStore?.selectedTemplate && campaignStore.selectedTemplate.name?.length > 0
			? campaignStore.selectedTemplate.name
			: campaignTranslation("untitled");

	const undoButtonDisabled =
		!editor?.history?.getUndos?.()?.length ||
		editor.history.getUndos().length <= 1 ||
		imagesStore.secondaryTabType === SecondaryTabTypeEnum.SMART_IMAGE;

	const redoButtonDsiabled =
		!editor?.history?.getRedos?.()?.length ||
		editor.history.getRedos().length == 0 ||
		imagesStore.secondaryTabType === SecondaryTabTypeEnum.SMART_IMAGE;
	editor?.history?.setScenesFunc(setScenes);
	editor?.history.getUpdatedScenes(updateScenes);
	editor?.history.setCurrentScenesFunc(setCurrentScene);

	const handleAdminModeChange = (e: ChangeEvent<HTMLInputElement>) => {
		campaignStore.handleAdminModeChange(e.target.checked);
	};
	const navigate = useSecureNavigate();
	const handleViewModeChange = (viewMode: CampaignViewModes) => {
		setViewMode(viewMode);
		campaignStore.handleViewModeChange(viewMode);
	};
	const [isUndoing, setIsUndoing] = useState(false);
	const [isRedoing, setIsRedoing] = useState(false);

	const undo = () => {
		if (isUndoing) return;
		setIsUndoing(true);
		editor?.history.undo();
		setTimeout(() => {
			setIsUndoing(false);
		}, 350);
	};

	const redo = () => {
		if (isRedoing) return;
		setIsRedoing(true);
		editor?.history.redo();
		setTimeout(() => {
			setIsRedoing(false);
		}, 350);
	};
	const handleAvatarClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};
	return (
		<Box
			className={clsx(styles.CampaignHeaderContainer, {
				[styles.isAdminMode]: isAdminMode,
			})}
		>
			{!isPopupView && (
				<Box className={styles.logoContainer}>
					<img className={styles.logo} src={Logo} />
					<Box className={styles.projectName}>
						{!campaignStore.selectedTemplate ? (
							<Typography className={styles.projectName}>{campaignTranslation("untitled")}</Typography>
						) : (
							<EditableChip
								key={displayedTemplateName}
								label={displayedTemplateName}
								onEdit={(newValueL: string) => {
									if (campaignStore && campaignStore?.selectedTemplate) {
										campaignStore.selectedTemplate.name = newValueL;
									}
								}}
								onDelete={() => {}}
								className={styles.backgroundColor}
								enableOnMouseEnter
								enableOnMouseLeave
								hideButtons
							/>
						)}
					</Box>
				</Box>
			)}
			<Box
				className={clsx(styles.rightSide, {
					[styles.toCenter]: isPopupView,
				})}
			>
				<Box className={styles.CampaignHeaderWrapper}>
					{isAdminMode && (
						<Typography className={styles.adminModeNode}>
							<span className={styles.bold}>{t("adminMode")}</span> - {t("adminModeNote")}
						</Typography>
					)}
					{(authStore.user?.isSuperAdminOrOwner() || iframeStore.iframe?.config.controls?.isAdmin) && (
						<FormGroup>
							<FormControlLabel
								className={clsx(styles.FormControlLabel, {
									[styles.adminModeFormControlLabel]: isAdminMode,
								})}
								control={
									<Switch
										checked={isAdminMode}
										onChange={handleAdminModeChange}
										classes={{
											root: clsx(styles.switchBase, {
												[styles.selected]: isAdminMode,
											}),
										}}
									/>
								}
								label={t("adminMode")}
								labelPlacement="start"
								value={isAdminMode}
								classes={{ label: styles.adminModeLabel }}
							/>
						</FormGroup>
					)}
					{/* TODO: to add the other functionalities later */}
					<Box className={styles.hide}>
						<Box className={styles.verticalDivider} />

						<BriaIconButton
							title={t("gridView")}
							buttonType="secondaryLarge"
							onClick={() => handleViewModeChange(CampaignViewModes.GRID_VIEW)}
							className={clsx(styles.iconButton, {
								[styles.selectedView]: viewMode === CampaignViewModes.GRID_VIEW,
							})}
						>
							<Icons.GridView size={24} />
						</BriaIconButton>

						<BriaIconButton
							title={t("fullView")}
							buttonType="secondaryLarge"
							onClick={() => handleViewModeChange(CampaignViewModes.FULL_VIEW)}
							className={clsx(styles.iconButton, {
								[styles.selectedView]: viewMode === CampaignViewModes.FULL_VIEW,
							})}
						>
							<Icons.FullView size={24} />
						</BriaIconButton>
					</Box>
					<Box className={styles.verticalDivider} />

					<BriaIconButton
						className={clsx({
							[styles.disabled]: undoButtonDisabled,
						})}
						disabled={undoButtonDisabled}
						title={t("undo")}
						buttonType="secondaryLarge"
						onClick={undo}
					>
						<Icons.Undo size={24} />
					</BriaIconButton>
					<BriaIconButton
						className={clsx({
							[styles.disabled]: redoButtonDsiabled,
						})}
						disabled={redoButtonDsiabled}
						title={t("redo")}
						buttonType="secondaryLarge"
						onClick={redo}
					>
						<Icons.Redo size={24} />
					</BriaIconButton>
					{authStore.user?.isSuperAdminOrOwner() && !isAdminMode && (
						<Box className={styles.verticalDivider} />
					)}

					{isAdminMode ? (
						<>
							{isTemplateDownloading ? (
								<CircularProgress color="inherit" size={18} />
							) : (
								<BriaIconButton
									title={t("download")}
									buttonType="secondaryLarge"
									disabled={
										!campaignStore.selectedTemplate ||
										designEditorStore.isLoadingTemplate ||
										isTemplateDownloading
									}
									onClick={async () => {
										setIsTemplateDownloading(true);
										await handleDownloadTemplate(campaignStore.selectedTemplate);
										setIsTemplateDownloading(false);
									}}
								>
									<Icons.Download size={24} />
								</BriaIconButton>
							)}

							<BriaButton
								buttonType="secondaryMedium"
								onClick={() => {
									setTemplateName("");
									setOpenModal(true);
									setSaveNewTemplateClicked(true);
								}}
								className={clsx(styles.secondaryMedium, styles.btn)}
								disabled={!campaignStore.selectedTemplate}
							>
								{t("saveNewTemplate")}
							</BriaButton>

							{((campaignStore?.selectedTemplate &&
								campaignStore.selectedTemplate.status !== CampaignEntityStatus.DRAFT) ||
								!campaignStore?.selectedTemplate) && (
								<BriaButton
									onClick={saveTemplate}
									buttonType="primaryMedium"
									className={clsx(styles.primaryMedium, styles.btn)}
									loading={campaignStore.isTemplateUpdating || campaignStore.isCreatingTemplate}
									disabled={
										!campaignStore.selectedTemplate ||
										campaignStore.isTemplateUpdating ||
										campaignStore.isCreatingTemplate ||
										campaignStore.selectedTemplate.org_id !==
											getSelectedOrganization()?.organization.uid
									}
								>
									{t("saveTemplate")}
								</BriaButton>
							)}
						</>
					) : (
						<>
							<BriaButton
								buttonType="textMedium"
								className={styles.btn}
								disabled={campaignStore.isLoadingTemplates}
								onClick={() => {
									designEditorStore.setProperty("isTemplateEmptyState", true);
									designEditorStore.setProperty("designEditorPopup", true);
									campaignStore.setProperty("isEditingAllAds", true);
									campaignStore.handleSetSelectedTemplatePreviewButton(
										TemplatePreviewBtn.ORGANIZATION,
									);
								}}
							>
								{t("new")}
							</BriaButton>
							{((campaignStore?.selectedTemplate &&
								campaignStore.selectedTemplate.status !== CampaignEntityStatus.ACTIVE) ||
								!campaignStore?.selectedTemplate) && (
								<BriaButton
									onClick={saveCampaign}
									buttonType="textMedium"
									className={styles.btn}
									disabled={!campaignStore.selectedTemplate}
									loading={campaignStore.isTemplateUpdating}
								>
									{t("save")}
								</BriaButton>
							)}

							<BriaButton
								buttonType="textMedium"
								onClick={() => {
									setTemplateName("");
									setOpenModal(true);
									setSaveNewTemplateClicked(false);
								}}
								className={styles.btn}
								disabled={!campaignStore.selectedTemplate}
							>
								{t("saveNewCampaign")}
							</BriaButton>
							<BriaButton
								disabled={
									!campaignStore.selectedTemplate ||
									designEditorStore.isLoadingTemplate ||
									isTemplateDownloading
								}
								loading={isTemplateDownloading}
								buttonType="textMedium"
								className={styles.btn}
								onClick={async () => {
									setIsTemplateDownloading(true);
									await handleDownloadTemplate(campaignStore.selectedTemplate);
									setIsTemplateDownloading(false);
								}}
							>
								{t("download")}
							</BriaButton>
						</>
					)}
				</Box>
				{!isPopupView && (
					<>
						<OrganizationsDropdown />
						{authStore.user && (
							<Box className={styles.userIconConainter}>
								<BriaButton
									onClick={handleAvatarClick}
									aria-label={t("accountOfCurrentUser")}
									className={styles.avatarButton}
									disableRipple
								>
									<Avatar
										src={user?.profilePicture ?? USER_DEFAULT_AVATAR}
										className={styles.avatarStyle}
									/>
								</BriaButton>
							</Box>
						)}
						<Menu
							anchorEl={anchorEl}
							open={Boolean(anchorEl)}
							onClose={handleClose}
							anchorOrigin={{
								vertical: "bottom",
								horizontal: "center",
							}}
							PaperProps={{
								style: {
									width: 150,
								},
							}}
						>
							<MenuItem
								onClick={() => {
									logout().finally(() => {
										playgroundStore.clearResults();
										textToImageStore.clearConfig();
										textToImageStore.errorMessage = "";
										handleClose();
										const queryParams = new URLSearchParams(location.search);
										const continueUrl = queryParams.get("continueUrl") || undefined;

										if (
											(location.pathname && location.pathname.includes("apps")) ||
											(continueUrl && continueUrl.includes("apps"))
										) {
											if (
												(location.pathname && location.pathname.includes("fox")) ||
												(continueUrl && continueUrl.includes("fox"))
											) {
												navigate(RouterConstants.FOX_APPS.path);
											} else {
												navigate("/" + RouterConstants.CONSOLE.path, { replace: true });
											}
										} else {
											navigate(RouterConstants.LOGIN.path);
										}
									});
								}}
							>
								<Typography variant="inherit">{t1("logout")}</Typography>
							</MenuItem>
						</Menu>
					</>
				)}
			</Box>

			<ConfirmationPopup
				onClose={() => {
					setOpenModal(false);
				}}
				paperClassName={clsx(styles.campaignConfirmationPopup)}
				title={saveNewTemplateClicked ? templateTranslation("title") : campaignTranslation("title")}
				description={
					<Box>
						<InputLayout
							showLabel={false}
							validateOn={!isValidTemplateName}
							validationMessage={
								saveNewTemplateClicked
									? templateTranslation("teamplateValidationMessage")
									: campaignTranslation("campaignValidationMessage")
							}
						>
							<TextField
								value={templateName}
								onChange={(e) => {
									setTemplateName(e.target.value);
								}}
								placeholder={
									saveNewTemplateClicked ? templateTranslation("input") : campaignTranslation("input")
								}
								fullWidth
								InputProps={{ classes: { root: styles.textFieldDataSetCreation } }}
							/>
						</InputLayout>
					</Box>
				}
				confirmButtonText={
					saveNewTemplateClicked ? templateTranslation("saveNewTemplate") : t("saveNewCampaign")
				}
				loading={campaignStore.isTemplateDuplicationInProgress || campaignStore.isCreatingTemplate}
				open={openModal}
				hideCancel={true}
				onClick={() => {
					if (saveNewTemplateClicked) {
						saveNewTemplate({ templateName, setIsValidTemplateName, setOpenModal });
					} else {
						saveNewCampaign({ templateName, setIsValidTemplateName, setOpenModal });
					}
				}}
			/>
		</Box>
	);
};

const ObservedComponent = observer(CampaignHeader);
export default ObservedComponent;
