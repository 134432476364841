import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Box, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import clsx from "clsx";
import { useState } from "react";
import { Image } from "../../../DesignEditor/models/image";
import { IMenuItem } from "../../../DesignEditor/views/DesignEditor/components/Panels/panelItems/Images/Images";
import useHoverOverlay from "../../../hooks/useHoverOverlay";
import styles from "./EditorImageOverlay.module.scss";
export type EditorImageOverlayProps = {
	menuItems: IMenuItem[];
	image: Image;
	hideMenuItems?: boolean;
};

const EditorImageImageOverlay = ({ menuItems, image, hideMenuItems }: EditorImageOverlayProps) => {
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
	const [hoverRef, isHovered, setIsHovered] = useHoverOverlay();

	const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation();
		setAnchorEl(event.currentTarget);
	};
	const handleMenuClose = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(null);
		event.stopPropagation();
	};
	return (
		<Box ref={hoverRef} className={styles.overlay}>
			<Box className={styles.overlayContent}>
				{!hideMenuItems && (
					<IconButton
						className={clsx(styles.iconButton, {
							[styles.iconButtonVisible]: isHovered || Boolean(anchorEl),
							[styles.iconButtonHidden]: !isHovered && !Boolean(anchorEl),
						})}
						onClick={handleMenuOpen}
					>
						<MoreHorizIcon className={styles.MoreHorizIcon} />
					</IconButton>
				)}

				<Menu
					classes={{
						paper: styles.paper,
					}}
					anchorEl={anchorEl}
					open={Boolean(anchorEl)}
					onClose={handleMenuClose}
					MenuListProps={{
						onMouseEnter: () => setIsHovered(true),
						onMouseLeave: () => !anchorEl && setIsHovered(false),
					}}
				>
					{menuItems.map((item: IMenuItem, index: number) => (
						<MenuItem
							key={index}
							onClick={(e: any) => {
								item.onClick(image);
								handleMenuClose(e);
							}}
						>
							<Box className={styles.menuIconWrapper}>
								<img src={item.icon} alt="" />
								<Typography className={styles.menuLabel}>{item.label}</Typography>
							</Box>
						</MenuItem>
					))}
				</Menu>
			</Box>
		</Box>
	);
};

export default EditorImageImageOverlay;
