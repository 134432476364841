import { Box, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IconButton } from "rsuite";
import { API_PLACEMENT_TYPES } from "../../../../../constants/foregroundPlacementConstants";
import { useAppStore } from "../../../../../hooks/useStores";
import { ConfigDisplayProps, ImageToImageConfigType, PlatformDestination } from "../../../../../models/image-to-image";
import styles from "./ConfigDisplay.module.scss";
import ConfigItem from "./ConfigItem";

const ConfigDisplay: React.FC<ConfigDisplayProps> = ({ config, onDelete }) => {
	const { t } = useTranslation("translation", { keyPrefix: "playground.imageToImage.config.features" });
	const { tailoredGenerationStore } = useAppStore();
	const placements = API_PLACEMENT_TYPES(t);
	const [modelsMap, setModelsMap] = useState(new Map());

	useEffect(() => {
		if (tailoredGenerationStore.models && tailoredGenerationStore.models.length > 0) {
			setModelsMap(new Map(tailoredGenerationStore.models.map((model) => [model.id, model])));
		}
	}, [tailoredGenerationStore.models]);

	const renderConfigItem = (configKey: keyof ImageToImageConfigType | string, value: any): JSX.Element | null => {
		if (typeof value === "boolean" && configKey !== "selected") {
			return value ? (
				<ConfigItem
					configKey={configKey}
					value={configKey}
					label={t(`mainPage.${configKey}`)}
					onDelete={onDelete}
				/>
			) : null;
		}

		if (configKey === "prompt" && typeof value === "string" && value.trim().length) {
			return <ConfigItem configKey={configKey} value={value} label={value} onDelete={onDelete} />;
		}

		if (configKey === "tailored_model_id") {
			const selectedTailoredModel = modelsMap.get(value);
			if (!selectedTailoredModel) return null;
			return (
				<ConfigItem
					configKey={configKey}
					value={value}
					label={selectedTailoredModel?.name}
					onDelete={onDelete}
				/>
			);
		}

		if (typeof value === "object" && value !== null && "selected" in value) {
			if (!value.selected) return null;
			switch (configKey) {
				case "solid":
					return (
						<ConfigItem configKey={configKey} onDelete={onDelete} value={value}>
							<Box className={styles.colorPreviewBox}>
								<Box className={styles.colorBox} sx={{ background: (value as any).color_code }} />
								<Typography className={styles.label}>{t("background.backgroundColor")}</Typography>
							</Box>
						</ConfigItem>
					);
				case "generateByText":
					if (!value.prompt) return null;
					return (
						<ConfigItem
							configKey={configKey}
							value={(value as any).prompt}
							onDelete={onDelete}
							label={(value as any).prompt}
						/>
					);
				case "aspect_ratio":
					return (
						<>
							{(value as any)?.value?.map((item: string) => (
								<ConfigItem
									configKey={configKey}
									value={item}
									label={`${t("size.aspectRatio")}: ${item}`}
									onDelete={onDelete}
								/>
							))}
						</>
					);
				case "custom_size":
					if (!value.width || !value?.height) return null;
					return (
						<ConfigItem
							configKey={configKey}
							value={value}
							onDelete={onDelete}
							label={`${t("size.customSize")}: ${(value as any).width}x${(value as any).height}`}
						/>
					);
				case "medium_destination":
					return (
						<>
							{(value as any)?.destinations?.map((item: PlatformDestination) => (
								<ConfigItem
									configKey={configKey}
									value={item}
									onDelete={onDelete}
									label={`${item.name} ${item.width}x${item.height}`}
								/>
							))}
						</>
					);
				case "placements":
					return (
						<>
							{value.values?.map((item: string) => (
								<ConfigItem configKey={configKey} value={item} onDelete={onDelete}>
									<Box className={styles.placementItem}>
										<IconButton
											icon={placements?.find((placement) => placement.value === item)?.icon()}
											className={styles.placementIcon}
										/>
										<Typography className={styles.label}>{item.replace("_", " ")}</Typography>
									</Box>
								</ConfigItem>
							))}
						</>
					);
				default:
					return null;
			}
		}

		return null;
	};

	if (!config) return null;

	return (
		<Box className={styles.configDisplayWrapper}>
			{Object.keys(config).map((key) => renderConfigItem(key as keyof ImageToImageConfigType, config[key]))}
		</Box>
	);
};

export default observer(ConfigDisplay);
