import { makeAutoObservable, runInAction } from "mobx";
import { ChangeEvent } from "react";
import { v4 as uuidv4 } from "uuid";
import { IGroupOption } from "../../../components/common/CustomSingleSelectDropdown/ٍSingleSelectGroupedDropDown.tsx";
import {
	TextToImageModels,
	getModelName,
	getModelVersion,
} from "../../../components/common/DropDowns/TextToImageModelsDropdown/TextToImageModelsDropdown.tsx";
import { APPS } from "../../../constants/AppsConstants.ts";
import RouterConstants from "../../../constants/RouterConstants.ts";
import useErrorToast from "../../../hooks/useErrorToast.tsx";
import { IRootStore } from "../../../mobx/root-store.tsx";
import { PlaygroundImage, PlaygroundResult } from "../../../models/image-to-image.ts";
import { TextToImageConfigType } from "../../../models/text-to-image.ts";
import QueryService from "../../../utils/QueryService.ts";
import iframeStore from "../../IframeNew/iframe-store.tsx";

export interface ITextToImageStore {
	config: TextToImageConfigType;
	models: TextToImageModels;
	loadingModels: boolean;
	mediums: string[];
	loadingMediums: boolean;
	hideNegativePrompt: boolean;
	errorMessage: string;
	handleConfigChange: <K extends keyof TextToImageConfigType>(key: K, value: TextToImageConfigType[K]) => void;
	getModels: () => Promise<TextToImageModels>;
	getMediums: () => Promise<string[]>;
	generateTextToImage: () => Promise<void>;
	clearConfig: () => void;
	isTextToVector: () => boolean;
	handleText2ImagePopupChange: (e: ChangeEvent<HTMLInputElement>, selectedOption: IGroupOption) => void;
}

export default class TextToImageStore implements ITextToImageStore {
	private queryService: QueryService = new QueryService("/text-to-image");
	rootStore: IRootStore;
	config: TextToImageConfigType = defaultConfig;
	models: TextToImageModels = {};
	loadingModels: boolean = false;
	hideNegativePrompt: boolean = false;
	mediums: string[] = [];
	loadingMediums: boolean = false;
	errorToast = useErrorToast();
	errorMessage = "";

	constructor(rootStore: IRootStore) {
		makeAutoObservable(this);
		this.rootStore = rootStore;
	}

	isTextToVector = () => {
		return window.location.pathname.endsWith(RouterConstants.TEXT_TO_VECTOR.path);
	};

	clearConfig = () => {
		this.config = defaultConfig;
	};

	handleConfigChange = <K extends keyof TextToImageConfigType>(key: K, value: TextToImageConfigType[K]) => {
		this.config[key] = value;
	};
	handleBulkConfigChange = <K extends keyof TextToImageConfigType>(
		keyOrChanges: K | Partial<TextToImageConfigType>,
		value?: TextToImageConfigType[K],
	) => {
		if (typeof keyOrChanges === "string") {
			// Handle single key-value update
			this.config[keyOrChanges] = value as TextToImageConfigType[K];
		} else {
			// Handle multiple properties update
			this.config = { ...this.config, ...keyOrChanges };
		}
		console.log(this.config, "this.config");
	};

	generateTextToImage = async (): Promise<void> => {
		const { playgroundStore } = this.rootStore;
		const savedIndex = playgroundStore.playgroundResults.length;
		const resultsSkeletons: PlaygroundResult = {
			id: uuidv4(),
			config: { ...this.config },
			type: APPS.TEXT_TO_IMAGE,
			images: Array.from({ length: this.config.num_results! }).map((_) => ({
				id: "",
				url: "",
				seed: NaN,
				loading: true,
			})),
		};
		playgroundStore.playgroundResults = [...playgroundStore.playgroundResults, resultsSkeletons];
		this.errorMessage = "";

		try {
			const generatedImages: PlaygroundImage[] = await this.queryService.post("/", this.config);

			runInAction(() => {
				playgroundStore.playgroundResults[savedIndex].images = generatedImages.map((image) => ({
					...image,
					loading: true,
					id: uuidv4(),
					config: playgroundStore.playgroundResults[savedIndex].config,
					type: APPS.TEXT_TO_IMAGE,
				}));
			});
		} catch (e: any) {
			if (e?.response?.data?.message == "Query doesn't stand with Bria's ethic rules") {
				this.errorMessage =
					"We detected words that may result in offensive image. Rephrase or remove them to carry on.";
			}
			runInAction(() => {
				playgroundStore.playgroundResults = playgroundStore.playgroundResults.filter(
					(_, index) => index !== savedIndex,
				);
			});
		}
	};

	getModels = async (): Promise<TextToImageModels> => {
		try {
			if (Object.keys(this.models).length === 0) {
				this.loadingModels = true;
				const models: TextToImageModels = await this.queryService.get("/models");
				runInAction(() => {
					this.loadingModels = false;
					this.models = models;
				});
			}

			const iframeEnabledModels = iframeStore.iframe?.config.gen_config?.enabled_models;
			if (iframeEnabledModels)
				this.models = Object.fromEntries(
					Object.entries(this.models)
						.filter(([type]) => type in iframeEnabledModels)
						.map(([type, details]) => [
							type,
							{
								...details,
								versions: details.versions.filter((version) => version in iframeEnabledModels[type]),
							},
						]),
				);

			return this.models;
		} catch (e: any) {
			this.loadingModels = false;
			return Promise.reject(`Error loading text-to-image models: ${e.message || e.toString()}`);
		}
	};

	getMediums = async (): Promise<string[]> => {
		try {
			if (Object.keys(this.mediums).length === 0) {
				this.loadingMediums = true;
				const mediums: string[] = await this.queryService.get("/mediums");

				runInAction(() => {
					this.loadingMediums = false;
					this.mediums = mediums;
				});
			}
			return this.mediums;
		} catch (e: any) {
			this.loadingMediums = false;
			return Promise.reject(`Error loading text-to-image mediums: ${e.message || e.toString()}`);
		}
	};
	clearTailoredModelsSelections = () => {
		this.handleBulkConfigChange({
			model_url: undefined,
			tailored_model_id: undefined,
		});
	};
	clearFoundationModelsSelections = () => {
		this.handleBulkConfigChange({
			model: "",
			model_version: "",
		});
	};
	handleText2ImagePopupChange = (e: ChangeEvent<HTMLInputElement>, selectedOption: IGroupOption) => {
		this.hideNegativePrompt = false;
		const isTailoredModel = selectedOption && selectedOption?.extraData?.isTailoredModel;
		this.handleConfigChange("model_name", selectedOption.key);

		if (!isTailoredModel) {
			this.clearTailoredModelsSelections();
			this.handleBulkConfigChange({
				model: getModelName(e.target.value as string),
				model_version: getModelVersion(e.target.value as string),
			});
			if (selectedOption.value.toLowerCase().includes("fast")) {
				this.hideNegativePrompt = true;
				this.handleConfigChange("negative_prompt", undefined);
			}
		} else {
			this.clearFoundationModelsSelections();
			this.handleBulkConfigChange({
				model_url: selectedOption?.extraData?.endpointModelName,
				tailored_model_id: selectedOption?.id,
				negative_prompt: undefined,
			});
			this.hideNegativePrompt = true;
		}
	};
}

const defaultConfig: TextToImageConfigType = {
	model: "",
	model_version: "",
	aspect_ratio: "4:3",
	medium: "",
	num_results: 4,
	prompt_enhancement: true,
};
